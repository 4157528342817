import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"
import { ENDPOINTS, REST_API } from "../../const/constants"
import axios from "axios"
import { useDispatch } from "react-redux"
import { refreshToken } from "./tokenSlice"
import { customRoute } from "../../utilities"

interface IProfile {
  id: number,
  full_name: string,
  middle_name: string,
  first_name: string,
  last_name: string,
  gender: string,
  birth_date: string,
  level_display: string,
  level: number,
  image: string,
  phone_number: string,
  country: ILocation,
  city: ILocation,
  bio: string
}

interface IPhoto {
  id: number,
  image: string
}

export interface IReward {
  id: number,
  photos: IPhoto[],
  user: string,
  start_day: number,
  start_date: number,
  start_month: number,
  start_year: number,
  end_day: number,
  end_month: number,
  end_year: number,
  year: number,
  sport: ISport,
  distance: IDistance,
  disciplines: string,
  result: string,
  category: string,
  title: string,
  note: string,
  age: number,
  gender: string,
  significance: string,
  protocol: string,
  team: string,
  organizer: string,
  teacher: string,
  institution: string,
  kind_activity: string
  country: ILocation,
  city: ILocation
}
interface ILocation {
  id: number,
  name: string
}

interface IDistance {
  id: number,
  title: string,
  sport: number
}

interface ISport {
  id: number,
  title: string
}

interface OwnSubscription {
  id: number
  status: string
  owner: string
  current_type: CurrentType
  next_type: any
  next_payment: any
  expire_at: string
  price: number
  available_free: boolean
  infinity: boolean
}

interface CurrentType {
  id: string
  title: string
  price: number
  duration: number
  num_users: number
  is_active: boolean
  has_discount: boolean
  discount_price: any
}

interface IMe {
  id: string,
  email: string,
  completed: boolean,
  level: number,
  level_display: string,
  profile: IProfile,
  rewards: IReward[],
  own_subscription: OwnSubscription,
  is_subuser: boolean
}

interface initialStateErrorType {
  detail?: string,
  code?: string,
  messages?: string[]
}

interface meInitialStateType {
  loading: boolean,
  completed: boolean
  data: IMe,
  success: boolean,
  error: boolean,
  errors: initialStateErrorType,
}

const initialState: meInitialStateType = {
  loading: false,
  completed: false,
  data: {} as IMe,
  success: false,
  error: false,
  errors: {} as initialStateErrorType,
};



export interface IMeReq {
  token: string | null;
}

export const getMe = createAsyncThunk(
  "getMe",
  async (req: IMeReq, { rejectWithValue }) => {
    const { token } = req;
    const url = REST_API + ENDPOINTS.user.me;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await axios.get(url, { headers: headers });
      return response.data;
    } catch (err) {
      const error: any = err;
      if(error.response.status === 401) {
        try{
          const refresh = await axios.post(REST_API + ENDPOINTS.auth.refresh, { refresh: localStorage.getItem('refresh') });
          const _tk = refresh.data.access
          localStorage.setItem('access', _tk)
          const response = await axios.get(url, { headers: {Authorization: `Bearer ${_tk}`,} });
          return response.data;
        } catch (err){
          const serror: any = err;
          localStorage.removeItem('access')
          localStorage.removeItem('refresh')
          return rejectWithValue(serror.response);
        }
      }
    }
  }
);

export const getMeSlice = createSlice({
  name: "getMe",
  initialState,
  reducers: {
    logMeOut: (state: any) => {
      state.loading = false;
      state.completed = false;
      state.data = { Me: {} as IMe };
      state.success = false;
      state.checkpoint = false;
      state.error = false;
      state.errors = {} as initialStateErrorType;
      localStorage.removeItem('access')
      localStorage.removeItem('refresh')

    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMe.pending, (state: any) => {
        state.loading = true;
        state.success = false;
        state.checkpoint = true;
      })
      .addCase(getMe.fulfilled, (state: any, { payload }: any) => {
        state.loading = false;
        state.completed = payload.completed
        state.data = payload;
        state.error = false;
        state.success = true;
        // !payload.completed && customRoute('/auth')
      })
      .addCase(getMe.rejected, (state, { error, payload }) => {
        const payl: any = payload;
        state.loading = false;
        state.success = false;
        state.error = true;
      })
  },
});
export const { logMeOut } = getMeSlice.actions;

export const MeSlice = (state: RootState) => state.me;

export const MeReducer = getMeSlice.reducer;
