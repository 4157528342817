
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import axios from "axios";
import { ENDPOINTS, REST_API } from "../../const/constants";
import { createUser } from "./registrationSlice";
import { customRoute } from "../../utilities";

export interface IAuth {
  refresh: string;
  access: string;
}


interface AuthUserInitialStateType {
  loading: boolean,
  data: IAuth
  success: boolean,
  error: boolean,
  errors: any,
}

const initialState: AuthUserInitialStateType = {
  loading: false,
  data: {} as IAuth,
  success: false,
  error: false,
  errors: {},
};

interface IUserReq {
  email: string;
  password: string;
}

function getCookie(name: string): string | undefined {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift();
}

export const openId = createAsyncThunk(
  "authUser",
  async (req: IUserReq, { rejectWithValue }) => {
    const url = REST_API + ENDPOINTS.auth.openId;
    let data = new FormData();
    data.append('username', req.email);
    data.append('password', req.password);

    const csrfToken: string | undefined = getCookie('csrftoken');

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: REST_API + ENDPOINTS.auth.openId,
      headers: {
        // 'Cookie': `csrftoken=${csrfToken}`,
      },
      data: data
    };
    try {
      const response = await axios.request(config)
      return response.data;
    } catch (err) {
      const error: any = err;
      return rejectWithValue(error.response);
    }
  }
);

export const getOpenIdTkns = createAsyncThunk(
  "authUser",
  async (req, { rejectWithValue }) => {
    const url = REST_API + ENDPOINTS.auth.openId;
    try {
      const response = await axios.get(url, {});
      return response.data;
    } catch (err) {
      const error: any = err;
      return rejectWithValue(error.response);
    }
  }
);

export const authUser = createAsyncThunk(
  "authUser",
  async (req: IUserReq, { rejectWithValue }) => {
    const url = REST_API + ENDPOINTS.auth.token;
    try {
      const response = await axios.post(url, { ...req }, {});
      return response.data;
    } catch (err) {
      const error: any = err;
      return rejectWithValue(error.response);
    }
  }
);

interface ISwitchUser {
  token: string | null;
  id: string
}

export const switchUser = createAsyncThunk(
  "switchUser",
  async (req: ISwitchUser, { rejectWithValue }) => {
    const { token, id } = req
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const url = REST_API + ENDPOINTS.user.switch;
    try {
      const response = await axios.post(url, { subuser: id }, { headers: headers });
      return response.data;
    } catch (err) {
      const error: any = err;
      return rejectWithValue(error.response);
    }
  }
);

interface IBackUser {
  token: string | null;
}

export const backUser = createAsyncThunk(
  "backUser",
  async (req: IBackUser, { rejectWithValue }) => {
    const { token } = req
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const url = REST_API + ENDPOINTS.user.back;
    try {
      const response = await axios.post(url, {}, { headers: headers });
      return response.data;
    } catch (err) {
      const error: any = err;
      return rejectWithValue(error.response);
    }
  }
);

interface IOAuthReq {
  code: string
}

interface IOAuthCode {
  client_id: string
  code_challenge: string
  redirect_uri: string
}

export const oAuthCode = createAsyncThunk(
  "oAuthCode",
  async (req: IOAuthCode, { rejectWithValue }) => {
    const token = localStorage.getItem('access')
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    const url = REST_API + ENDPOINTS.auth.oauthCode
    try {
      const response = await axios.post(url, req, { headers: headers });
      return response.data;
    } catch (err) {
      const error: any = err;
      return rejectWithValue(error.response);
    }
  }
);

export const oAuthUser = createAsyncThunk(
  "oAuthUser",
  async (req: IOAuthReq, { rejectWithValue }) => {
    const { code } = req
    const url = REST_API + ENDPOINTS.auth.oauthTokens
    try {
      const response = await axios.post(url, { code: code }, {});
      return response.data;
    } catch (err) {
      const error: any = err;
      return rejectWithValue(error.response);
    }
  }
);

interface IConfirmEmailReq {
  key: string
}

export const subsKey = createAsyncThunk(
  "subsKey",
  async (req: IConfirmEmailReq, { rejectWithValue }) => {
    const { key } = req
    const url = REST_API + ENDPOINTS.subscriptions.renewFree
    try {
      const response = await axios.post(url, { key: key }, {});
      return response.data;
    } catch (err) {
      const error: any = err;
      return rejectWithValue(error.response);
    }
  }
);

interface IConfirmEmailReq {
  key: string
}

type IUrefreshTokenReq = {
  refresh: string | null
}

export const refreshToken = createAsyncThunk(
  "refresh/refreshToken",
  async (req: IUrefreshTokenReq, { rejectWithValue }) => {
    const { refresh } = req;
    const url = REST_API + ENDPOINTS.auth.refresh;
    try {
      const response = await axios.post(url, { refresh: refresh });
      return response.data;
    } catch (err) {
      const error: any = err;
      return rejectWithValue(error.response);
    }
  }
);

export const userRestoreKey = createAsyncThunk(
  "userRestoreKey",
  async (req: IConfirmEmailReq, { rejectWithValue }) => {
    const { key } = req
    const url = REST_API + ENDPOINTS.user.restore
    try {
      const response = await axios.post(url, { key: key }, {});
      return response.data;
    } catch (err) {
      const error: any = err;
      return rejectWithValue(error.response);
    }
  }
);

export const subsInfinity = createAsyncThunk(
  "subsInfinity",
  async (req: IConfirmEmailReq, { rejectWithValue }) => {
    const { key } = req
    const url = REST_API + ENDPOINTS.subscriptions.infinity
    try {
      const response = await axios.post(url, { key: key }, {});
      return response.data;
    } catch (err) {
      const error: any = err;
      return rejectWithValue(error.response);
    }
  }
);

export const ubsubsKey = createAsyncThunk(
  "ubsubsKey",
  async (req: IConfirmEmailReq, { rejectWithValue }) => {
    const { key } = req
    const url = REST_API + ENDPOINTS.user.unsubscribe
    try {
      const response = await axios.post(url, { key: key }, {});
      return response.data;
    } catch (err) {
      const error: any = err;
      return rejectWithValue(error.response);
    }
  }
);

export const emailKey = createAsyncThunk(
  "emailKey",
  async (req: IConfirmEmailReq, { rejectWithValue }) => {
    const { key } = req
    const url = REST_API + ENDPOINTS.user.verify
    try {
      const response = await axios.post(url, { key: key }, {});
      return response.data;
    } catch (err) {
      const error: any = err;
      return rejectWithValue(error.response);
    }
  }
);

export const authUserSlice = createSlice({
  name: "authUser",
  initialState,
  reducers: {
    authUserAction: () => { },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authUser.pending, (state: any) => {
        state.loading = true;
        state.success = false;
        state.errors.email = "";
      })
      .addCase(authUser.fulfilled, (state: any, { payload }: any) => {
        state.loading = false;
        state.data = payload
        state.error = false;
        state.success = true;
        localStorage.setItem("access", payload.access)
        localStorage.setItem("refresh", payload.refresh)
      })
      .addCase(authUser.rejected, (state: any, { payload }: any) => {
        state.loading = false;
        state.success = false;
        state.error = true;
      })
      .addCase(refreshToken.fulfilled, (state: any, { payload }: any) => {
        const savedUser = localStorage.getItem('savedUserId')
        state.loading = false;
        state.data = payload
        state.error = false;
        state.success = true;
        localStorage.setItem("access", payload.access)
        !!savedUser && customRoute(`/user/${savedUser}`)
      })
      .addCase(oAuthUser.pending, (state: any) => {
        state.loading = true;
        state.success = false;
        state.errors.email = "";
      })
      .addCase(oAuthUser.fulfilled, (state: any, { payload }: any) => {
        state.loading = false;
        state.data = payload
        state.error = false;
        state.success = true;
        localStorage.setItem("access", payload.access)
        localStorage.setItem("refresh", payload.refresh)
      })
      .addCase(oAuthUser.rejected, (state: any, { payload }: any) => {
        state.loading = false;
        state.success = false;
        state.error = true;
      })
      .addCase(createUser.fulfilled, (state: any, { payload }: any) => {
        state.loading = false;
        state.data = payload.tokens
        state.error = false;
        state.success = true;
        localStorage.setItem("access", payload.tokens.access)
        localStorage.setItem("refresh", payload.tokens.refresh)
      })
      .addCase(switchUser.pending, (state: any) => {
        state.loading = true;
        state.success = false;
        state.errors.email = "";
      })
      .addCase(switchUser.fulfilled, (state: any, { payload }: any) => {
        state.loading = false;
        state.data = payload
        state.error = false;
        state.success = true;
        localStorage.setItem("access", payload.access)
      })
      .addCase(switchUser.rejected, (state: any, { payload }: any) => {
        state.loading = false;
        state.success = false;
        state.error = true;
      })
      .addCase(backUser.pending, (state: any) => {
        state.loading = true;
        state.success = false;
        state.errors.email = "";
      })
      .addCase(backUser.fulfilled, (state: any, { payload }: any) => {
        state.loading = false;
        state.data = payload
        state.error = false;
        state.success = true;
        localStorage.setItem("access", payload.access)
        localStorage.setItem("refresh", payload.refresh)
      })
      .addCase(backUser.rejected, (state: any, { payload }: any) => {
        state.loading = false;
        state.success = false;
        state.error = true;
      })
  },
});

export const { authUserAction } = authUserSlice.actions;

export const AuthUser = (state: RootState) => state.authUser;

export const AuthUserReducer = authUserSlice.reducer;
