import { FC } from "react"

interface IIcon {
    fill?: string;
    stroke?: string;
}

export const GoogleIcon: FC<IIcon> = ({ }) => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M37.8911 21.2255H36.75V21.1667H24V26.8333H32.0063C30.8383 30.1321 27.6996 32.5 24 32.5C19.3059 32.5 15.5 28.6941 15.5 24C15.5 19.3059 19.3059 15.5 24 15.5C26.1668 15.5 28.1381 16.3174 29.6391 17.6526L33.6461 13.6456C31.1159 11.2876 27.7315 9.83334 24 9.83334C16.1765 9.83334 9.83334 16.1765 9.83334 24C9.83334 31.8236 16.1765 38.1667 24 38.1667C31.8236 38.1667 38.1667 31.8236 38.1667 24C38.1667 23.0501 38.0689 22.1229 37.8911 21.2255Z" fill="#FFC107" />
            <path d="M11.4667 17.4061L16.1212 20.8196C17.3806 17.7015 20.4307 15.5 24 15.5C26.1668 15.5 28.1381 16.3174 29.639 17.6526L33.6461 13.6456C31.1159 11.2876 27.7315 9.83334 24 9.83334C18.5586 9.83334 13.8397 12.9054 11.4667 17.4061Z" fill="#FF3D00" />
            <path d="M24 38.1667C27.6592 38.1667 30.9842 36.7663 33.498 34.489L29.1135 30.7787C27.6433 31.8968 25.8469 32.5015 24 32.5C20.3152 32.5 17.1865 30.1505 16.0079 26.8716L11.3881 30.431C13.7327 35.0188 18.4941 38.1667 24 38.1667Z" fill="#4CAF50" />
            <path d="M37.8911 21.2254H36.75V21.1667H24V26.8333H32.0063C31.4476 28.4033 30.4411 29.7752 29.1113 30.7794L29.1135 30.778L33.498 34.4883C33.1878 34.7702 38.1667 31.0833 38.1667 24C38.1667 23.0501 38.0689 22.1229 37.8911 21.2254Z" fill="#1976D2" />
            <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" stroke="#E9E9E9" />
        </svg>

    )
}

export const GoogleSmallIcon: FC<IIcon> = ({ }) => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1655_2952)">
                <path d="M21.7861 8.89961H20.9V8.85396H11V13.254H17.2167C16.3097 15.8153 13.8727 17.654 11 17.654C7.35515 17.654 4.4 14.6988 4.4 11.054C4.4 7.40911 7.35515 4.45396 11 4.45396C12.6825 4.45396 14.2131 5.08865 15.3786 6.1254L18.4899 3.01406C16.5253 1.18311 13.8974 0.0539551 11 0.0539551C4.92525 0.0539551 0 4.97921 0 11.054C0 17.1287 4.92525 22.054 11 22.054C17.0748 22.054 22 17.1287 22 11.054C22 10.3164 21.9241 9.59646 21.7861 8.89961Z" fill="#FFC107" />
                <path d="M1.26831 5.93401L4.88236 8.58446C5.86026 6.16336 8.22856 4.45396 11 4.45396C12.6825 4.45396 14.2131 5.08865 15.3786 6.1254L18.4899 3.01406C16.5253 1.18311 13.8974 0.0539551 11 0.0539551C6.77491 0.0539551 3.11081 2.43931 1.26831 5.93401Z" fill="#FF3D00" />
                <path d="M11 22.0539C13.8413 22.0539 16.423 20.9665 18.3749 19.1983L14.9704 16.3174C13.8289 17.1855 12.4341 17.655 11 17.6539C8.13889 17.6539 5.70954 15.8295 4.79434 13.2836L1.20724 16.0473C3.02774 19.6097 6.72484 22.0539 11 22.0539Z" fill="#4CAF50" />
                <path d="M21.786 8.89953H20.9V8.85388H11V13.2539H17.2167C16.7828 14.4729 16.0013 15.5381 14.9688 16.3179L14.9704 16.3168L18.375 19.1977C18.1341 19.4166 22 16.5539 22 11.0539C22 10.3163 21.9241 9.59638 21.786 8.89953Z" fill="#1976D2" />
            </g>
            <defs>
                <clipPath id="clip0_1655_2952">
                    <rect width="22" height="22" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}