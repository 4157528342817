import { useFormik } from "formik";
import s from './profile.module.scss'
import layout from '../../styles/_layout.module.scss'
import { CustomButtom } from "../common/CustomButton/CustomButton";
import { CustomInput } from "../common/CustomInput/CustomInput";
import { LockIcon } from "../../icons/lock-icon";
import * as Yup from "yup";
import { useAppDispatch } from "../../store/hooks";
import { newPassword } from "../../store/slice/newPasswordSlice";
import { useState } from "react";
import { PoluchilosIcon } from "../../icons/poluchilos-icon";


export const ChangePassword = () => {
    const dispatch = useAppDispatch()
    const [oerror, setoError] = useState('')
    const [perror, setpError] = useState('')
    const [success, setSuccess] = useState(false)

    const RegisterSchema = Yup.object().shape({
        oldPassword: Yup.string().required("Обязательное поле"),
        password: Yup.string().required("Обязательное поле"),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref("password"), undefined], "Пароли должны совпадать")
            .required("Обязательное поле"),
    });

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            password: '',
            confirmPassword: ''
        },

        onSubmit: values => {
            dispatch(newPassword({
                token: localStorage.getItem('access'),
                data: {
                    old_password: formik.values.oldPassword,
                    password: formik.values.password,
                    password2: formik.values.confirmPassword
                }
            })).then((resp: any) => {
                if (resp.error && !!resp.payload.data.old_password) {
                    setoError(resp.payload.data.old_password)
                }
                if (resp.error && !!resp.payload.data.password) {
                    setpError(resp.payload.data.password)
                }
                if(!resp.error){
                    setSuccess(true)
                }
            })
        },
        validationSchema: RegisterSchema,
        validateOnChange: false,
        validateOnBlur: false,
    });



    return (<>
        {!success && <form className={s.profileUserInfo} onSubmit={formik.handleSubmit}>
            <CustomInput
                style={{ marginBottom: 0 }}
                name="oldPassword"
                icon={<LockIcon />}
                type="password"
                onChange={formik.handleChange}
                placeholder='Введите старый пароль'
            />
            {!!formik.errors.oldPassword && !!formik.touched.oldPassword ? (
                <div className="form-error">{formik.errors.oldPassword}</div>
            ) : null}
            {!!oerror ? (
                <div className="form-error">{oerror}</div>
            ) : null}
            <CustomInput
                style={{ marginBottom: 0 }}
                name="password"
                icon={<LockIcon />}
                type="password"
                onChange={formik.handleChange}
                placeholder='Введите новый пароль'
            />
            {!!perror ? (
                <div className="form-error">{perror}</div>
            ) : null}
            <CustomInput
                style={{ marginBottom: 0 }}
                name="confirmPassword"
                icon={<LockIcon />}
                type="password"
                onChange={formik.handleChange}
                placeholder='Введите новый пароль еще раз'
            />
            {!!formik.errors.confirmPassword && !!formik.touched.confirmPassword ? (
                <div className="form-error">{formik.errors.confirmPassword}</div>
            ) : null}
            <CustomButtom
                type='submit'
                onClick={() => { }}>
                Изменить
            </CustomButtom>
        </form>}
        {success && <div className={layout.modalSuccess}>
            <div className={layout.successModalIcon}><PoluchilosIcon /></div>
            <div className={layout.successModalHeading}>Ваш пароль успешно изменен!</div>
        </div>}
    </>)
}