import { Spin, message } from "antd"
import { FC, useState } from "react"
import s from '../../Profile/profile.module.scss'
import { CustomButtom } from "../CustomButton/CustomButton"
import { MnInput } from "../MnInput/MnInput"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { AddAppeal, addAppeal } from "../../../store/slice/appealSlice"

interface ISupport {
    onClose: (state: boolean) => void
}

export const SupportForm: FC<ISupport> = ({ onClose }) => {
    const [subj, setSubj] = useState("")
    const [mes, setMes] = useState("")
    const dispatch = useAppDispatch()
    const {loading} = useAppSelector(AddAppeal)

    const sendMessage = () => {
        dispatch(addAppeal({
            token: localStorage.getItem('access'),
            subject: subj,
            message: mes
        })).then((resp:any)=>{
            if(!resp.error){
                message.success("Сообщение отправлено")
                onClose(false)
            }
        })
    }
    return (<>
        <div className={s.profileUserInfo}>
        <Spin spinning={loading}>
            <div className={s.formTop}>
                <MnInput
                    key="subject"
                    name="subject"
                    type="text"
                    onChange={(e) => { setSubj(e.target.value) }}
                    placeholder='Тема вопроса'
                />
                <MnInput
                    key="message"
                    name="message"
                    type="textarea"
                    onChange={(e) => { setMes(e.target.value) }}
                    placeholder='Ваше обращение'
                />
            </div>
            <div className={s.profileUserInfoFooter} style={{marginTop: 24}}>
                <CustomButtom
                    type='submit'
                    onClick={sendMessage}>
                    Отправить
                </CustomButtom>
            </div>
            </Spin>
        </div>
        
    </>)
}