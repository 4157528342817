import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"
import { ENDPOINTS, REST_API } from "../../const/constants"
import axios from "axios"

export interface IAlbum {
    id: number,
    title: string,
    reward: number,
    photos: IPhoto[]
  }

  interface IPhoto {
    id: number,
    image: string
  }

  interface initialStateErrorType {
    detail?: string,
    code?: string,
    messages?: string[]
  }

   interface meInitialStateType {
    loading: boolean,
    data: IAlbum[],
    success: boolean,
    error: boolean,
    errors: initialStateErrorType,
  }
  
  const initialState: meInitialStateType = {
    loading: false,
    data: [] as IAlbum[],
    success: false,
    error: false,
    errors: {} as initialStateErrorType,
  };

 
  
  export interface IMeReq {
    token?: string | null;
  }
  
  export const getAlbums = createAsyncThunk(
    "getAlbums",
    async (req: IMeReq, { rejectWithValue }) => {
      const { token } = req;
      const url = REST_API + ENDPOINTS.albums.albums;
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      try {
        const response = await axios.get(url, {headers: headers});
        return response.data;
      } catch (err) {
        const error: any = err;
        return rejectWithValue(error.response);
      }
    }
  );
  
  export const getAlbumsSlice = createSlice({
    name: "getAlbums",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
      builder
        .addCase(getAlbums.pending, (state:any) => {
          state.loading = true;
          state.success = false;
          state.checkpoint = true;
        })
        .addCase(getAlbums.fulfilled, (state:any, { payload }:any) => {
          state.loading = false;
          state.data = payload.results;
          state.error = false;
          state.success = true;
        })
        .addCase(getAlbums.rejected, (state, { error, payload }) => {
          const payl: any = payload;
          state.loading = false;
          state.success = false;
          state.error = true;
        })
    },
  });
  
  export const GetAlbums = (state: RootState) => state.getAlbums;
  
  export const GetAlbumsReducer = getAlbumsSlice.reducer;
  