import { FC } from "react"

interface IIcon {
    fill?: string;
    stroke?: string;
    size?: number;
}

export const KubokIcon: FC<IIcon> = ({}) => {
    return (
        <svg width="36" height="26" viewBox="0 0 36 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 24.667V29.667V24.667Z" fill="#DDBE90" />
            <path d="M18 24.667V29.667" stroke="#DDBE90" strokeWidth="2" strokeLinecap="round" />
            <path d="M29.6671 6.3335L31.2488 6.86017C32.8988 7.41017 33.7238 7.68517 34.1954 8.34017C34.6671 8.99518 34.6671 9.86518 34.6671 11.6035V11.7252C34.6671 13.1585 34.6671 13.8769 34.3221 14.4635C33.9771 15.0502 33.3488 15.3985 32.0954 16.0969L27.1671 18.8336M6.33364 6.3335L4.75197 6.86017C3.10196 7.41017 2.27696 7.68517 1.80529 8.34017C1.33362 8.99518 1.33362 9.86685 1.33362 11.6035V11.7252C1.33362 13.1585 1.33362 13.8769 1.67862 14.4635C2.02362 15.0502 2.65196 15.3985 3.9053 16.0969L8.83365 18.8336M16.577 8.0385C17.2104 6.90016 17.527 6.3335 18.0004 6.3335C18.4737 6.3335 18.7904 6.90016 19.4237 8.0385L19.587 8.33184C19.767 8.65517 19.857 8.81517 19.997 8.92184C20.1387 9.02851 20.3137 9.06851 20.6637 9.14684L20.9804 9.22018C22.2104 9.49851 22.8254 9.63684 22.9721 10.1068C23.1187 10.5785 22.6987 11.0685 21.8604 12.0485L21.6437 12.3019C21.4054 12.5802 21.2854 12.7185 21.232 12.8919C21.1787 13.0652 21.197 13.2502 21.232 13.6219L21.2654 13.9602C21.392 15.2685 21.4554 15.9235 21.0737 16.2135C20.6904 16.5035 20.1137 16.2385 18.962 15.7085L18.6654 15.5719C18.337 15.4219 18.1737 15.3469 18.0004 15.3469C17.827 15.3469 17.6637 15.4219 17.3354 15.5719L17.0387 15.7085C15.887 16.2402 15.3103 16.5035 14.927 16.2135C14.5437 15.9235 14.6087 15.2685 14.7353 13.9602L14.7687 13.6219C14.8037 13.2502 14.822 13.0652 14.7687 12.8919C14.7153 12.7202 14.5953 12.5802 14.357 12.3019L14.1403 12.0485C13.302 11.0685 12.882 10.5785 13.0287 10.1068C13.1753 9.63684 13.7903 9.49851 15.0203 9.22018L15.337 9.14684C15.687 9.06851 15.862 9.03018 16.0037 8.92184C16.1437 8.81517 16.2337 8.65517 16.4137 8.33184L16.577 8.0385Z" stroke="#DDBE90" strokeWidth="2" />
            <path d="M28 34.6669H7.99993M26.3334 2.09341C27.5684 2.32841 28.3017 2.58841 28.9334 3.36842C29.7284 4.34509 29.6867 5.40009 29.6017 7.5101C29.3017 15.0985 27.6017 24.6668 18.0016 24.6668C8.4016 24.6668 6.69993 15.1001 6.39659 7.5101C6.31326 5.40009 6.27159 4.34342 7.06326 3.36842C7.8566 2.39174 8.80494 2.23174 10.7033 1.91174C13.1152 1.51644 15.5559 1.32299 18 1.33341C19.1967 1.33341 20.31 1.37507 21.3333 1.44674" stroke="#DDBE90" strokeWidth="2" strokeLinecap="round" />
        </svg>

    )
}