
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import axios from "axios";
import { ENDPOINTS, REST_API } from "../../const/constants";

export interface IAuth {
    refresh: string;
    access: string;
}

interface IresendEmail {
    detail: string
}

interface resendEmailInitialStateType {
    loading: boolean,
    data: IresendEmail
    success: boolean,
    error: boolean,
    errors: any,
}

const initialState: resendEmailInitialStateType = {
  loading: false,
  data: {} as IresendEmail,
  success: false,
  error: false,
  errors: {},
};

interface IresendEmailReq {
    token: string | null;
}

export const resendEmail = createAsyncThunk(
  "resendEmail",
  async (req: IresendEmailReq, { rejectWithValue }) => {
    const {token} = req
    console.log(token)
    const url = REST_API + ENDPOINTS.user.send_email
    const headers = {
        Authorization: `Bearer ${token}`,
      };
    try {
      const response = await axios.post(url, {}, {headers: headers});
      return response.data;
    } catch (err) {
      const error: any = err;
      return rejectWithValue(error.response);
    }
  }
);

export const resendEmailSlice = createSlice({
  name: "resendEmail",
  initialState,
  reducers: {
    resendEmailAction: () => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(resendEmail.pending, (state:any) => {
        state.loading = true;
        state.success = false;
        state.errors.email = "";
      })
      .addCase(resendEmail.fulfilled, (state:any, { payload }:any) => {
        state.loading = false;
        state.data = payload
        state.error = false;
        state.success = true;
      })
      .addCase(resendEmail.rejected, (state:any, { payload }:any) => {
        const payl: any = payload;
        state.loading = false;
        state.success = false;
        state.error = true;
      });
  },
});

export const { resendEmailAction } = resendEmailSlice.actions;

export const ResendEmail = (state: RootState) => state.resendEmail;

export const ResendEmailReducer = resendEmailSlice.reducer;
