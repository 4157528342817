import { FC } from "react";
import s from './splash.module.scss'

interface IPSDItem {
    icon: any,
    text: string
}

interface IPSD {
    bgImage: string;
    bgPosition: string
    data: IPSDItem[]
}
export const PsdBlock: FC<IPSD> = ({ bgImage, bgPosition, data }) => {
    return (<>
        <div className={s.psdItem} style={{ backgroundImage: `url(${bgImage})`, backgroundPosition: `${bgPosition}` }}>
            <div className={s.psdItemTop}>
                <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="path-1-inside-1_2569_44025" fill="white">
                        <path d="M26.1041 8.91451C26.2012 8.81753 26.2016 8.6599 26.1026 8.56467C23.9621 6.50383 21.1501 5.27786 18.1777 5.11522C15.1224 4.94805 12.1131 5.9161 9.72831 7.83327C7.34352 9.75045 5.75152 12.4815 5.25834 15.5013C4.76516 18.5212 5.40559 21.6168 7.05655 24.193C8.7075 26.7693 11.2524 28.6444 14.2022 29.4579C17.1519 30.2715 20.2983 29.966 23.0364 28.6002C25.7746 27.2345 27.9113 24.9048 29.0358 22.0591C30.1299 19.2905 30.1907 16.2235 29.2126 13.4177C29.1674 13.2881 29.0241 13.2225 28.8954 13.2703C28.7667 13.3181 28.7013 13.4611 28.7464 13.5908C29.6819 16.2816 29.6224 19.2218 28.5734 21.8763C27.4939 24.608 25.4429 26.8443 22.8145 28.1553C20.1861 29.4663 17.1659 29.7595 14.3344 28.9786C11.5029 28.1977 9.05995 26.3978 7.47518 23.9248C5.89041 21.4518 5.27564 18.4803 5.74905 15.5815C6.22246 12.6826 7.75065 10.0611 10.0398 8.22079C12.329 6.38047 15.2177 5.45123 18.1505 5.6117C21.0005 5.76763 23.6967 6.9419 25.7506 8.91597C25.8496 9.01111 26.0069 9.0115 26.1041 8.91451Z" />
                    </mask>
                    <path d="M26.1041 8.91451C26.2012 8.81753 26.2016 8.6599 26.1026 8.56467C23.9621 6.50383 21.1501 5.27786 18.1777 5.11522C15.1224 4.94805 12.1131 5.9161 9.72831 7.83327C7.34352 9.75045 5.75152 12.4815 5.25834 15.5013C4.76516 18.5212 5.40559 21.6168 7.05655 24.193C8.7075 26.7693 11.2524 28.6444 14.2022 29.4579C17.1519 30.2715 20.2983 29.966 23.0364 28.6002C25.7746 27.2345 27.9113 24.9048 29.0358 22.0591C30.1299 19.2905 30.1907 16.2235 29.2126 13.4177C29.1674 13.2881 29.0241 13.2225 28.8954 13.2703C28.7667 13.3181 28.7013 13.4611 28.7464 13.5908C29.6819 16.2816 29.6224 19.2218 28.5734 21.8763C27.4939 24.608 25.4429 26.8443 22.8145 28.1553C20.1861 29.4663 17.1659 29.7595 14.3344 28.9786C11.5029 28.1977 9.05995 26.3978 7.47518 23.9248C5.89041 21.4518 5.27564 18.4803 5.74905 15.5815C6.22246 12.6826 7.75065 10.0611 10.0398 8.22079C12.329 6.38047 15.2177 5.45123 18.1505 5.6117C21.0005 5.76763 23.6967 6.9419 25.7506 8.91597C25.8496 9.01111 26.0069 9.0115 26.1041 8.91451Z" stroke="#818181" strokeWidth="4" mask="url(#path-1-inside-1_2569_44025)" />
                    <rect x="26" y="9.5" width="3" height="3" rx="1.5" fill="#818181" />
                    <rect x="14" y="13.5" width="8" height="8" rx="4" fill="#818181" />
                </svg>
            </div>
            <div className={s.psdItemBottom}>
                {data.map((item, index) => {
                    return (<div className={s.psdItemBottomIndex} key={index}>
                        <div style={{height: 24}}>
                        {item.icon}
                        </div>
                        <div>{item.text}</div>
                    </div>)
                })}
            </div>
        </div>
    </>)
}