
import { CalendarIcon } from '../../icons/calendar-icon'
import { useAppSelector } from '../../store/hooks'
import { GetSports } from '../../store/slice/getSportsSlice'
import { IReward } from '../../store/slice/getUserRewardsSlice'
import layout from '../../styles/_layout.module.scss'
import axios from 'axios'
import moment from 'moment'


import { FC, useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom'

interface IAwardItem {
    award: IReward
}

export const AwardItem: FC<IAwardItem> = ({ award }) => {
    useAppSelector(GetSports)
    const router = useNavigate()

    return (
        <div onClick={() => router(`/awards/${award.id}`)} className={layout.awardItem}>
            <div className={layout.awardItemImageContainer}>
                {!!award?.photos && award?.photos?.length > 0 ? <img loading='lazy' className={layout.awardItemImage} src={award?.photos[0]?.image} alt={award.title} width={175} height={100} />
                    : <div style={{ width: "100%", height: "100px", display: "flex", alignItems: 'center', justifyContent: 'center', background: '#E9E9E9' }}>

                        <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.4998 29.1001V34.8001" stroke="#C8C8C8" strokeWidth="3.0875" strokeLinecap="round" />
                            <path d="M28.1499 40.5H14.8499L15.4959 37.2776C15.5819 36.847 15.8144 36.4596 16.1539 36.1811C16.4934 35.9026 16.9188 35.7503 17.3579 35.75H25.6419C26.081 35.7503 26.5064 35.9026 26.8459 36.1811C27.1853 36.4596 27.4179 36.847 27.5039 37.2776L28.1499 40.5Z" stroke="#C8C8C8" strokeWidth="3.0875" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M34.7999 8.2002L36.603 8.8006C38.484 9.4276 39.4245 9.7411 39.9622 10.4878C40.4999 11.2345 40.4999 12.2263 40.4999 14.208V14.3467C40.4999 15.9807 40.4999 16.7996 40.1066 17.4684C39.7133 18.1372 38.997 18.5343 37.5682 19.3304L31.9499 22.4503M8.19978 8.2002L6.39667 8.8006C4.51567 9.4276 3.57516 9.7411 3.03746 10.4878C2.49976 11.2345 2.49976 12.2282 2.49976 14.208V14.3467C2.49976 15.9807 2.49976 16.7996 2.89306 17.4684C3.28636 18.1372 4.00266 18.5343 5.43147 19.3304L11.0498 22.4503M19.8772 10.1439C20.5992 8.8462 20.9602 8.2002 21.4998 8.2002C22.0394 8.2002 22.4004 8.8462 23.1225 10.1439L23.3087 10.4783C23.5139 10.8469 23.6165 11.0293 23.7761 11.1509C23.9376 11.2725 24.1371 11.3181 24.5361 11.4074L24.8971 11.491C26.2993 11.8083 27.0004 11.966 27.1676 12.5018C27.3348 13.0395 26.856 13.5981 25.9003 14.7153L25.6533 15.0041C25.3816 15.3214 25.2448 15.4791 25.184 15.6767C25.1232 15.8743 25.1441 16.0852 25.184 16.5089L25.222 16.8946C25.3664 18.3861 25.4386 19.1328 25.0035 19.4634C24.5665 19.794 23.9091 19.4919 22.5961 18.8877L22.2579 18.7319C21.8836 18.5609 21.6974 18.4754 21.4998 18.4754C21.3022 18.4754 21.116 18.5609 20.7417 18.7319L20.4035 18.8877C19.0906 19.4938 18.4332 19.794 17.9962 19.4634C17.5592 19.1328 17.6333 18.3861 17.7777 16.8946L17.8157 16.5089C17.8556 16.0852 17.8765 15.8743 17.8157 15.6767C17.7549 15.481 17.6181 15.3214 17.3464 15.0041L17.0994 14.7153C16.1437 13.5981 15.6649 13.0395 15.8321 12.5018C15.9993 11.966 16.7004 11.8083 18.1026 11.491L18.4636 11.4074C18.8626 11.3181 19.0621 11.2744 19.2236 11.1509C19.3832 11.0293 19.4858 10.8469 19.691 10.4783L19.8772 10.1439Z" stroke="#C8C8C8" strokeWidth="3.0875" />
                            <path d="M32.8999 40.5001H10.0998M30.9999 3.36637C32.4078 3.63427 33.2438 3.93067 33.9639 4.81988C34.8702 5.93328 34.8227 7.13599 34.7258 9.5414C34.3838 18.1921 32.4458 29.1001 21.5017 29.1001C10.5577 29.1001 8.61778 18.194 8.27197 9.5414C8.17697 7.13599 8.12947 5.93138 9.03198 4.81988C9.93638 3.70647 11.0175 3.52407 13.1816 3.15927C15.9312 2.70863 18.7136 2.4881 21.4998 2.49997C22.864 2.49997 24.1332 2.54747 25.2999 2.62917" stroke="#C8C8C8" strokeWidth="3.0875" strokeLinecap="round" />
                        </svg>

                    </div>
                }
            </div>
            <div className={layout.awardItemContent}>
                <div className={layout.awardItemDate}>
                    <CalendarIcon /> 
                     {!!award?.start_date && moment(award?.start_date).format('DD.MM.YYYY')}
                    {/* {award?.start_day}.{award?.start_month}.{award?.start_year} */}
                </div>
                <div className={layout.awardItemTitle}>
                    {award?.title}
                </div>
                <div className={layout.awardItemTypeBlock}>
                    {award.category === 'sport' &&
                        <><div className={layout.awardItemType}>{award?.sport?.title}</div>
                            <div className={layout.awardItemType}>{award?.disciplines}</div>
                        </>}
                        {award.category === 'non_sport' && <>
                        <div className={layout.awardItemType}>{award.kind_activity}</div>
                        </>}
                    <div className={layout.awardItemTime}>{award?.result}</div>
                </div>
            </div>
        </div>
    )
}