import { FC } from "react"

interface IIcon {
    fill?: string;
    stroke?: string;
    size?: number
}

export const PoloskiIcon: FC<IIcon> = ({ stroke = "#DDBE90", size=18 }) => {
    const viewBox = `0 0 ${size} ${size}`
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 8H3C2.73478 8 2.48043 7.89464 2.29289 7.70711C2.10536 7.51957 2 7.26522 2 7C2 6.73478 2.10536 6.48043 2.29289 6.29289C2.48043 6.10536 2.73478 6 3 6H21C21.2652 6 21.5196 6.10536 21.7071 6.29289C21.8946 6.48043 22 6.73478 22 7C22 7.26522 21.8946 7.51957 21.7071 7.70711C21.5196 7.89464 21.2652 8 21 8ZM17.6667 12.6667H6.33333C6.06812 12.6667 5.81376 12.5613 5.62623 12.3738C5.43869 12.1862 5.33333 11.9319 5.33333 11.6667C5.33333 11.4014 5.43869 11.1471 5.62623 10.9596C5.81376 10.772 6.06812 10.6667 6.33333 10.6667H17.6667C17.9319 10.6667 18.1862 10.772 18.3738 10.9596C18.5613 11.1471 18.6667 11.4014 18.6667 11.6667C18.6667 11.9319 18.5613 12.1862 18.3738 12.3738C18.1862 12.5613 17.9319 12.6667 17.6667 12.6667ZM13.6667 17.3333H10.3333C10.0681 17.3333 9.81376 17.228 9.62623 17.0404C9.43869 16.8529 9.33333 16.5985 9.33333 16.3333C9.33333 16.0681 9.43869 15.8138 9.62623 15.6262C9.81376 15.4387 10.0681 15.3333 10.3333 15.3333H13.6667C13.9319 15.3333 14.1862 15.4387 14.3738 15.6262C14.5613 15.8138 14.6667 16.0681 14.6667 16.3333C14.6667 16.5985 14.5613 16.8529 14.3738 17.0404C14.1862 17.228 13.9319 17.3333 13.6667 17.3333Z" fill="#DDBE90"/>
    </svg>
    
)
}