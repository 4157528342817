
import { GoogleIcon } from '../../icons/google-icon'
import s from './auth.module.scss'
import React, { FC, useEffect, useState } from "react"
import { CustomInput } from '../common/CustomInput/CustomInput'
import { LockIcon } from '../../icons/lock-icon'
import { AtIcon } from '../../icons/at-icon'
import { CustomButtom } from '../common/CustomButton/CustomButton'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { LinkBtn } from '../common/LinkBtn/LinkBtn'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { AuthUser, authUser, getOpenIdTkns, oAuthCode, openId } from '../../store/slice/tokenSlice'
import { MeSlice, getMe } from '../../store/slice/meSlice'

import { ENDPOINTS, REST_API, iOS } from '../../const/constants'
import { YandexIcon } from '../../icons/yandex.icon'
import { Spin, message } from 'antd'
import { customRoute } from '../../utilities'


interface ILogin {
    changeMode: (state: boolean) => void
}

export const OpenId = () => {
    const [searchParams] = useSearchParams()
    const client_id = searchParams.get('client_id')
    const code_challenge = searchParams.get('code_challenge')
    const redirect_uri = searchParams.get('redirect_uri')

    const [login, setLogin] = useState<null | string>(null)
    const [password, setPassword] = useState<null | string>(null)
    const dispatch = useAppDispatch()
    const { loading } = useAppSelector(AuthUser)
    const { loading: meLoading } = useAppSelector(MeSlice)
    const router = useNavigate()
    const [isBanner, setIsBanner] = useState(false)
    const [error, setError] = useState("")
    const { loading: aLoading } = useAppSelector(AuthUser)

    const signIn = () => {
        const savedUser = localStorage.getItem('savedUserId')
        login && password && dispatch(openId({
            email: login,
            password: password
        })).then((resp: any) => {
            if (resp.error) {
                setError(resp.payload.data)
            }
            if (!resp.error) {
                const _p1 = localStorage.getItem('client_id')
                const _p2 = localStorage.getItem('code_challenge')
                const _p3 = localStorage.getItem('redirect_uri')

                dispatch(getMe({ token: resp.payload.access })).then((resp: any) => {
                    if (!resp.error) {
                        if (!resp.payload.completed) {
                            router('/signup')
                        } else if (!!resp.payload.completed && !_p1 && !_p2 && !_p3 && !!savedUser) {
                            customRoute(`/user/${savedUser}`)
                        } else if (!!resp.payload.completed && !!_p1 && !!_p2 && !!_p3) {
                            dispatch(oAuthCode({client_id: _p1, code_challenge: _p2, redirect_uri: _p3})).then((response:any) => {
                                
                            })
                        } else {
                            customRoute('/profile')
                        }
                    }
                })
            }
        })
    }

    useEffect(()=>{
        !!client_id && localStorage.setItem('client_id', client_id)
        !!code_challenge && localStorage.setItem('code_challenge', code_challenge)
        !!redirect_uri && localStorage.setItem('redirect_uri', redirect_uri)
        dispatch(getOpenIdTkns())
    },[])

    return (<>
        <div className={s.loginWrapper}>
            {isBanner && <div className={s.banner}>
                <div className={s.bannerIcon}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM11 10C11 9.44771 10.5523 9 10 9C9.44771 9 9 9.44771 9 10V13C9 13.5523 9.44771 14 10 14C10.5523 14 11 13.5523 11 13V10ZM11 7C11 6.44772 10.5523 6 9.99999 6C9.44771 6 9 6.44772 9 7C9 7.55228 9.44771 8 9.99999 8C10.5523 8 11 7.55228 11 7Z" fill="#695FD1" />
                    </svg>
                </div>
                <div className={s.bannerText}>
                    <div className={s.bannerTextTitle}>
                        Мы обновились!
                    </div>
                    <div className={s.bannerTextDesc}>
                        Если ты участвовал в массовых мероприятиях и у тебя есть NFT-медали, то можно перейти на старую версию сайта.
                    </div>
                    <div className={s.bannerTextButton}>
                        <Link to="https://nft.моинаграды.рф">Перейти</Link>
                    </div>
                    <div onClick={() => { setIsBanner(false) }} className={s.bannerClose}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.4969 12L17.6868 7.81011C17.8856 7.61161 17.9975 7.34224 17.9977 7.06127C17.998 6.7803 17.8866 6.51074 17.6881 6.31189C17.4896 6.11304 17.2202 6.00118 16.9393 6.00094C16.6583 6.00069 16.3887 6.11206 16.1899 6.31057L12 10.5005L7.81011 6.31057C7.61125 6.11171 7.34155 6 7.06034 6C6.77912 6 6.50942 6.11171 6.31057 6.31057C6.11171 6.50942 6 6.77912 6 7.06034C6 7.34155 6.11171 7.61125 6.31057 7.81011L10.5005 12L6.31057 16.1899C6.11171 16.3887 6 16.6584 6 16.9397C6 17.2209 6.11171 17.4906 6.31057 17.6894C6.50942 17.8883 6.77912 18 7.06034 18C7.34155 18 7.61125 17.8883 7.81011 17.6894L12 13.4995L16.1899 17.6894C16.3887 17.8883 16.6584 18 16.9397 18C17.2209 18 17.4906 17.8883 17.6894 17.6894C17.8883 17.4906 18 17.2209 18 16.9397C18 16.6584 17.8883 16.3887 17.6894 16.1899L13.4969 12Z" fill="#CDCDCD" />
                    </svg>
                    </div>
                </div>
            </div>}
            <div className={s.loginImg}>
                <img src={'/images/loginImage.png'} width={257} height={232} alt="" />
            </div>
            <h1 style={{ textAlign: 'center' }} className={s.h1}>Организуй свои достижения!</h1>
            <div className={s.lofinForm} style={iOS ? { paddingBottom: 90 } : {}}>
                <div className={s.header}>
                    <h2 className={s.h2}>Авторизация</h2>
                    <Link rel='nofollow' to={REST_API + ENDPOINTS.auth.oauth + '?provider=google'} className={s.gIcon}><GoogleIcon /></Link>
                    <Link rel='nofollow' to={REST_API + ENDPOINTS.auth.oauth + '?provider=yandex'} className={s.yIcon}><YandexIcon /></Link>
                </div>
                <Spin spinning={aLoading || meLoading}>
                    <div>
                        <CustomInput
                            name="email"
                            icon={<AtIcon />}
                            type="email"
                            onChange={(e) => setLogin(e.target.value)}
                            placeholder='Электронная почта'
                        />
                        <CustomInput
                            name="password"
                            icon={<LockIcon />}
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder='Введите пароль'
                        />
                        {!!error && <div className={s.error}>{error}</div>}
                        <CustomButtom
                            type='primary'
                            onClick={signIn}
                            disabled={!login || !password || loading || meLoading}
                        >Войти
                        </CustomButtom>
                    </div>
                </Spin>
                <div className={s.switcher}>
                    Авторизуясь, вы принимаете <Link className={s.LinkBtn} to="https://help.моинаграды.рф/privacy#3" target="_blank">Политику конфиденциальности</Link> и <Link className={s.LinkBtn} to="https://help.моинаграды.рф/privacy#1" target="_blank">Условия использования</Link>.
                </div>
                <div className={s.switcher}>
                    <LinkBtn onClick={() => { router('/reset-password') }}>Забыли пароль?</LinkBtn>
                </div>
                <div className={s.switcher}>
                    <CustomButtom type="secondary" onClick={() => { router('/signup') }}>Зарегистрироваться</CustomButtom>
                </div>
            </div>
        </div>
    </>)
}