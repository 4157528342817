
import { FC, useEffect, useState } from "react"
import { StatusMenu } from "../common/StatusMenu/StatusMenu"
import s from './award.module.scss'
import { CustomInput } from "../common/CustomInput/CustomInput"
import { CustomButtom } from "../common/CustomButton/CustomButton"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { addReward } from "../../store/slice/addRewardSlice"
import classnames from 'classnames'
import { CustomCheckbox } from "../common/CustomCheckbox/CustomCheckbox"
import { GetSports, ISport, getSports } from "../../store/slice/getSportsSlice"
import { Select, Upload, UploadProps, message } from "antd"
import { FotikIcon } from "../../icons/fotik-icon"
import { DATES, ENDPOINTS, MONTHES, REST_API, YEARS } from "../../const/constants"
import { MeSlice, getMe } from "../../store/slice/meSlice"
import { useFormik } from "formik"
import { updateReward } from "../../store/slice/updateRewardSlice"
import { PoluchilosIcon } from "../../icons/poluchilos-icon"
import axios from "axios"
import { GetDistances, getDistances } from "../../store/slice/getDistancesSlice"
import { MnInput } from "../common/MnInput/MnInput"
import { GetCountries, getCountries } from "../../store/slice/getCountriesSlice"
import { CustomSelect } from "../common/CustomSelect/CustomSelect"
import * as Yup from "yup";
const cn = classnames.bind(this)

type OptionType = { label: string, value: string, id: number };

interface IAddAward {
    cat?: 'sport' | 'non_sport'
}

const EditSchema = Yup.object().shape({
    // city: Yup.string().required('Поле "Город" обязательно'),
    start_year: Yup.string().required('Поле "Год" обязательно '),
});


export const AddAward: FC<IAddAward> = ({ cat = 'sport' }) => {

    const items = ["Название награды", "Описание", "done"]

    // ============================================================

    const [step, setStep] = useState(1)
    const [title, setTitle] = useState("")
    const [status, setStatus] = useState<string>("Название награды")
    const [startDay, setStartDay] = useState(0)
    const [startMonth, setStartMonth] = useState(0)
    const [startYear, setStartYear] = useState(0)
    const [endDay, setEndDay] = useState<number | null>(null)
    const [endMonth, setEndMonth] = useState<number | null>(null)
    const [endYear, setEndYear] = useState<number | null>(null)
    const [isEndDate, setIsEndDate] = useState(false)
    const [isFull, setIsFull] = useState(false)
    const [sport, setSport] = useState<number | null>(null)
    const [kind, setKind] = useState(null)
    const [sResult, setSResult] = useState('')
    const [country, setCountry] = useState('')
    const [city, setCity] = useState('')
    const [options, setOptions] = useState<OptionType[]>([]);
    const dispatch = useAppDispatch()
    const { success: sSuccess, data: sData } = useAppSelector(GetSports)
    const { data: meData } = useAppSelector(MeSlice)
    const [rewardId, setRewardId] = useState(0)
    const [category, setCategory] = useState<'sport' | 'non_sport'>('sport')
    const [distances, setDistances] = useState<OptionType[]>([]);
    const { loading: dLoading, data: dData, success: dSuccess } = useAppSelector(GetDistances)
    const { data: cData } = useAppSelector(GetCountries)

    // ============================================================

    useEffect(() => {
        dispatch(getCountries({}))
    }, [])

    useEffect(() => {
        if (sSuccess) {
            const newOptions = sData.map((x: ISport, i: number) => {
                return ({
                    value: x.title,
                    label: x.title,
                    id: x.id
                })
            }
            );
            setOptions((state: OptionType[]) => [...state, ...newOptions]);
        }
    }, [sData, sSuccess])

    useEffect(() => {
        setDistances([])
        if (dSuccess) {
            const newDistances = dData.map((x: ISport, i: number) => {
                return ({
                    value: x.title,
                    label: x.title,
                    id: x.id
                })
            }
            );
            setDistances((state: OptionType[]) => [...state, ...newDistances]);
        }
    }, [dData, dSuccess])

    useEffect(() => {
        (category === 'sport' && !!sport) && dispatch(getDistances({ sport: sport }))
    }, [category, sport])

    useEffect(() => {
        !sSuccess && dispatch(getSports({}))
    }, [sSuccess])

    useEffect(() => {
        setCategory(cat)
    }, [cat])


    const nextClick = () => {
        if (step === 1) {
            dispatch(addReward({
                token: localStorage.getItem('access'),
                title: title,
                user: meData.id,
                category: category
            })).then((resp: any) => {
                if (!resp.error) {
                    setRewardId(resp.payload.id)
                    // setTitle('')
                    setStep(2)
                    dispatch(getMe({ token: localStorage.getItem('access') }))
                }
            })
        }
        if (step === 2) {
            dispatch(updateReward({
                token: localStorage.getItem('access'),
                id: rewardId,
                data: {
                    start_day: startDay,
                    start_month: startMonth,
                    start_year: startYear,
                    year: startYear,
                    end_day: endDay,
                    end_month: endMonth,
                    end_year: endYear,
                    sport: sport,
                    result: sResult,
                    kind_activity: kind
                }
            })).then((resp: any) => {
                if (!resp.error) {
                    setStartDay(0)
                    setEndDay(0)
                    setStartMonth(0)
                    setEndMonth(0)
                    setStartYear(0)
                    setEndYear(0)
                    setSport(null)
                    setKind(null)
                    setSResult('')
                    setStep(3)
                    dispatch(getMe({ token: localStorage.getItem('access') }))
                }
            })
        }
    }

    const uploadProps: UploadProps = {
        name: 'image',
        action: REST_API + ENDPOINTS.rewards.rewards + rewardId + '/photos/',
        onRemove: (image) => {
            let data = new FormData();

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: REST_API + ENDPOINTS.rewards.rewards + rewardId + '/photos/' + image.response.id + '/delete/',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        headers: {
            authorization: `Bearer ${typeof window !== 'undefined' && localStorage.getItem('access')}`,
        },
        onChange(info) {
            if (info.file.status === 'done') {
                message.success(`Файл ${info.file.name} успешно загружен`);
            } else if (info.file.status === 'error') {
                message.error(`Произошла ошибка при загрузке файла ${info.file.name}`);
            }
        },
    };

    const formik = useFormik({
        initialValues: {
            title: undefined,
            start_day: undefined,
            start_month: undefined,
            start_year: undefined,
            end_day: undefined,
            end_month: undefined,
            end_year: undefined,
            year: undefined,
            sport: undefined,
            distance: undefined,
            disciplines: undefined,
            age: undefined,
            gender: undefined,
            result: undefined,
            significance: undefined,
            protocol: undefined,
            country: 1,
            city_name: undefined,
            team: undefined,
            organizer: undefined,
            institution: undefined,
            teacher: undefined,
            kind_activity: undefined,
            note: undefined,
            additional_criteria: undefined
        },

        onSubmit: values => {
            dispatch(updateReward({
                token: localStorage.getItem('access'),
                id: rewardId,
                data: {
                    title: values.title,
                    start_day: values.start_day,
                    start_month: values.start_month,
                    start_year: values.start_year,
                    end_day: values.end_day,
                    end_month: values.end_month,
                    end_year: values.end_year,
                    year: values.year,
                    sport: values.sport,
                    distance: values.distance,
                    disciplines: values.disciplines,
                    age: values.age,
                    gender: values.gender,
                    result: values.result,
                    significance: values.significance,
                    protocol: values.protocol,
                    country: values.country,
                    city_name: values.city_name,
                    team: values.team,
                    organizer: values.organizer,
                    institution: values.institution,
                    teacher: values.teacher,
                    kind_activity: values.kind_activity,
                    note: values.note,
                    additional_criteria: values.additional_criteria
                }
            })).then((resp: any) => {
                if (!resp.error) {
                    setStartDay(0)
                    setEndDay(0)
                    setStartMonth(0)
                    setEndMonth(0)
                    setStartYear(0)
                    setEndYear(0)
                    setSport(null)
                    setKind(null)
                    setSResult('')
                    setStep(3)
                    dispatch(getMe({ token: localStorage.getItem('access') }))
                }
            })
        },
        validationSchema: EditSchema,
        validateOnChange: false,
        validateOnBlur: false,
    });

    useEffect(()=>{
        console.log('country', formik.values.country)
    },[formik.values])

    return (<>
        <div className={s.modalWrapper}>
            {step !== 3 &&
                <div className={s.statusMenu}>
                    <StatusMenu
                        items={items}
                        current={status}
                    />
                </div>
            }
            {step === 1 &&
                <div className={s.registrationSteps}>
                    <div className={s.stepBody}>
                        <CustomSelect
                            className={s.sportSelector}
                            placeholder="Тип награды"
                            defaultValue={category}
                            defaultMobValue={category}
                            onSelect={(value: any, option: any) => {
                                setCategory(option.value)
                                formik.setFieldValue('category', option.value);
                            }}
                            options={[
                                { value: 'sport', label: 'Спортивная' },
                                { value: 'non_sport', label: 'Неспортивная' },
                            ]}
                        />
                    </div>
                    <div className={s.stepBody}>
                        <CustomInput
                            name="title"
                            type="text"
                            onChange={(e) => {
                                setTitle(e.target.value)
                                formik.setFieldValue('title', e.target.value)
                            }}
                            placeholder='Укажите название награды'
                        />
                    </div>
                </div>
            }
            {step === 2 &&
                <form className={s.addForm}>
                    <div className={s.registrationSteps}>
                        <div className={s.subtitle}>
                            Выберите дату получения награды:
                        </div>
                        <div className={cn(s.stepBody, s.stepLine)}>
                            <CustomSelect
                                className={s.sportSelector}
                                popupClassName={s.sportPopupSelector}

                                bordered={false}
                                options={DATES}
                                placeholder="День"
                                onSelect={(value, option) => {
                                    setStartDay(+option.value);
                                    formik.setFieldValue('start_day', +option.value)
                                }}
                            />
                            <CustomSelect
                                className={s.sportSelector}
                                popupClassName={s.sportPopupSelector}

                                bordered={false}
                                options={MONTHES}
                                placeholder="Месяц"
                                onSelect={(value, option) => {
                                    setStartMonth(+option.value);
                                    formik.setFieldValue('start_month', +option.value)
                                }}
                            />
                            <CustomSelect
                                className={s.sportSelector}
                                popupClassName={s.sportPopupSelector}

                                bordered={false}
                                options={YEARS()}
                                placeholder="Год"
                                onSelect={(value, option) => {
                                    setStartYear(+option.value);
                                    formik.setFieldValue('start_year', +option.value)
                                    formik.setFieldValue('year', +option.value)
                                }}
                            />
                        </div>
                        {!!formik.errors.start_year && !!formik.touched.start_year ? (
                                <div className="form-error" style={{ marginBottom: '16px' }}>{formik.errors.start_year}</div>
                            ) : null}
                        <div className={s.line} onClick={() => setIsEndDate(!isEndDate)}>
                            <CustomCheckbox value={isEndDate} onChange={setIsEndDate} />
                            <div className={s.text}>Добавить дату окончания</div>
                        </div>
                        {isEndDate && <div className={cn(s.stepBody, s.stepLine)}>
                            <CustomSelect
                                className={s.sportSelector}
                                popupClassName={s.sportPopupSelector}

                                bordered={false}
                                options={DATES}
                                placeholder="День"
                                onSelect={(value, option) => {
                                    setEndDay(+option.value);
                                    formik.setFieldValue('end_day', +option.value)
                                }}
                            />
                            <CustomSelect
                                className={s.sportSelector}
                                popupClassName={s.sportPopupSelector}

                                bordered={false}
                                options={MONTHES}
                                placeholder="Месяц"
                                onSelect={(value, option) => {
                                    setEndMonth(+option.value);
                                    formik.setFieldValue('end_month', +option.value)
                                }}
                            />

                            <CustomSelect
                                className={s.sportSelector}
                                popupClassName={s.sportPopupSelector}
                                bordered={false}
                                options={YEARS()}
                                placeholder="Год"
                                onSelect={(value, option) => {
                                    setEndYear(+option.value);
                                    formik.setFieldValue('end_year', +option.value)
                                }}
                            />

                        </div>}
                        <div className={s.separator}></div>
                        {category === 'sport' && <CustomSelect
                            isSearch
                            className={s.sportSelector}
                            popupClassName={s.sportPopupSelector}
                            bordered={false}
                            options={options}
                            placeholder="Вид спорта"
                            onSelect={(value, option) => {
                                setSport(option.id);
                                formik.setFieldValue('sport', +option.id)
                            }}
                        />}
                        {category === 'non_sport' &&
                            <CustomInput
                                name="kind"
                                type="text"
                                onChange={(e) => {
                                    setKind(e.target.value)
                                    formik.setFieldValue('kind_activity', e.target.value)
                                }}
                                placeholder='Направление'
                                style={{ width: "100%", marginBottom: '0' }}
                            />
                        }
                        <div className={s.tip}>
                            Укажите результаты, которых вы добились в соревновании <br />Например: <span>“Участие, 3 место или Лучший игрок”</span> 🚀
                        </div>
                        <div className={s.stepBody}>
                            <CustomInput
                                name="result"
                                type="textarea"
                                onChange={formik.handleChange}
                                rows={4}
                                placeholder='Результат соревнования'
                                style={{ marginBottom: '0' }}
                            />
                        </div>
                        <div className={s.stepBody}>
                            <Upload className={s.addPhoto} {...uploadProps}>
                                <div>
                                    <FotikIcon />
                                    <div>Добавить фотографию награды</div>
                                </div>
                            </Upload>
                        </div>

                        <MnInput
                            key="protocol"
                            name="protocol"
                            type="text"
                            onChange={formik.handleChange}
                            placeholder='Ссылка на результаты соревнования'
                        />
                        <div className={s.line} onClick={() => setIsFull(!isFull)}>
                            <CustomCheckbox value={isFull} onChange={setIsEndDate} />
                            <div className={s.text}>Добавить полное описание</div>
                        </div>
                        <div className={s.separator}></div>

                        {isFull && <>
                            {category === 'sport' && <>
                                {/* <CustomSelect
                                    isSearch
                                    className={s.sportSelector}
                                    popupClassName={s.sportPopupSelector}
                                    // defaultValue={formik.values.distance}
                                    bordered={false}
                                    options={distances}
                                    loading={dLoading}
                                    disabled={distances.length === 0}
                                    placeholder="Дисциплина"
                                    onSelect={(value, option) => {
                                        formik.setFieldValue('distance', option.id);
                                    }}
                                /> */}
                                <MnInput
                                    key="disciplines"
                                    name="disciplines"
                                    type="text"
                                    onChange={formik.handleChange}
                                    placeholder='Дисциплина'
                                />
                                <MnInput
                                    key="additional_criteria"
                                    name="additional_criteria"
                                    type="text"
                                    onChange={formik.handleChange}
                                    placeholder='Доп. критерии дисциплины'
                                />
                            </>}
                            <CustomSelect
                                className={s.sportSelector}
                                placeholder="Значимость"
                                defaultValue={formik.values.significance}
                                onSelect={(value: any, option: any) => {
                                    formik.setFieldValue('significance', option.value);
                                }}
                                options={[
                                    { value: 'high', label: 'Высокая' },
                                    { value: 'medium', label: 'Средняя' },
                                    { value: 'low', label: 'Низкая' },
                                ]}
                            />
                            <CustomSelect
                                className={s.sportSelector}
                                placeholder="Страна"
                                defaultValue={formik.values.country}
                                onSelect={(value: any, option: any) => {
                                    console.log(option)
                                    formik.setFieldValue('country', option.value)
                                }}
                                options={cData.map(c => ({ label: c.name, value: c.id }))}
                            />
                            <MnInput
                                key="city"
                                name="city"
                                type="text"
                                onChange={formik.handleChange}
                                placeholder='Город'
                            />
                            <MnInput
                                key="team"
                                name="team"
                                type="text"
                                onChange={formik.handleChange}
                                placeholder='Команда'
                            />
                            <MnInput
                                key="organizer"
                                name="organizer"
                                type="text"
                                onChange={formik.handleChange}
                                placeholder='Кем выдана / организатор'
                            />
                            <MnInput
                                key="institution"
                                name="institution"
                                type="text"
                                onChange={formik.handleChange}
                                placeholder='Школа / учреждение'
                            />
                            <MnInput
                                key="teacher"
                                name="teacher"
                                type="text"
                                onChange={formik.handleChange}
                                placeholder='Педагог / руководитель'
                            />
                            <MnInput
                                key="note"
                                name="note"
                                type="textarea"
                                onChange={formik.handleChange}
                                placeholder='Мои заметки'
                            />
                        </>}
                        {meData?.rewards?.length < 140 && category === 'sport' && <div className={s.stepBody}>
                            <><div className={s.promo}>
                                <div className={s.promoIcon}>🙋‍♂️</div>
                                <div className={s.promoText}>
                                    <div className={s.promoTextInfo}>
                                        Если вы не можете найти нужный вид спорта, то посмотрите в неспортивных наградах.
                                    </div>
                                    <div onClick={() => {
                                        setCategory('non_sport')
                                        formik.setFieldValue('sport', 'non_sport')
                                    }} className={s.promoTextLink}>Перейти к созданию неспортивной награды</div>
                                </div>
                            </div>
                                <div className={s.separator}></div>
                            </>
                        </div>}
                    </div>
                </form>
            }
            {step === 3 && <>
                <div className={s.modalSuccess}>
                    <div className={s.successModalIcon}><PoluchilosIcon /></div>
                    <div className={s.successModalHeading}>Награда успешно добавлена!</div>
                    <div className={s.successModalTitle}>&laquo;{title}&raquo;</div>
                </div>
            </>}
            <div className={s.stepsFooter}>
                <div className={s.stepButton}>
                    {step === 2 && <CustomButtom
                        onClick={() => {
                            setStep(step - 1)
                        }}
                        type='secondary'
                    >Назад
                    </CustomButtom>
                    }
                    {step === 1 &&
                        <CustomButtom
                            onClick={nextClick}
                            type='primary'
                        >Дальше
                        </CustomButtom>
                    }
                    {step === 2 &&
                        <CustomButtom
                            disabled={isFull && !formik.values.country}
                            onClick={formik.submitForm}
                            type='primary'
                        >Дальше
                        </CustomButtom>
                    }
                    {
                        step === 3 &&
                        <CustomButtom
                        style={{marginTop: 24}}
                            onClick={() => {
                                formik.resetForm()
                                setStep(1)
                            }}
                            type='secondary'
                        >Добавить еще
                        </CustomButtom>
                    }
                </div>
            </div>
        </div>
    </>)
}