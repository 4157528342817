
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import axios from "axios";
import { ENDPOINTS, REST_API } from "../../const/constants";

export interface IAuth {
    refresh: string;
    access: string;
}

interface IСreateRelated {
    id: string;
    username: string;
}

interface СreateRelatedInitialStateType {
    loading: boolean,
    data: IСreateRelated
    success: boolean,
    error: boolean,
    errors: any,
}

const initialState: СreateRelatedInitialStateType = {
    loading: false,
    data: {} as IСreateRelated,
    success: false,
    error: false,
    errors: {},
};

interface IСreateRelatedReq {
    token: string | null;
    username: string;
    password: string;
}

export const createRelated = createAsyncThunk(
    "createRelated",
    async (req: IСreateRelatedReq, { rejectWithValue }) => {
        const { token, username, password} = req
        const url = REST_API + ENDPOINTS.user.related;
        const headers = {
            Authorization: `Bearer ${token}`,
          };
        try {
            const response = await axios.post(url, { username: username, password: password}, {headers: headers});
            return response.data;
        } catch (err) {
            const error: any = err;
            return rejectWithValue(error.response);
        }
    }
);

export const createRelatedSlice = createSlice({
    name: "createRelated",
    initialState,
    reducers: {
        createRelatedAction: () => { },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createRelated.pending, (state: any) => {
                state.loading = true;
                state.success = false;
                state.errors.email = "";
            })
            .addCase(createRelated.fulfilled, (state: any, { payload }: any) => {
                state.loading = false;
                state.data = payload
                state.error = false;
                state.success = true;
            })
            .addCase(createRelated.rejected, (state: any, { payload }: any) => {
                const payl: any = payload;
                state.loading = false;
                state.success = false;
                state.error = true;
            });
    },
});

export const { createRelatedAction } = createRelatedSlice.actions;

export const CreateRelated = (state: RootState) => state.createRelated;

export const CreateRelatedReducer = createRelatedSlice.reducer;
