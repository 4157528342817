import { FC, useEffect, useState } from "react";
import s from './nav.module.scss';
import { CupIcon } from "../../icons/cup-icon";
import { UserIcon } from "../../icons/user-icon";
import { AlbumsIcon } from "../../icons/albums-icon";
import { Link } from "react-router-dom";

interface INav {
    active?: "award" | "profile" | "album"
}

export const Nav: FC<INav> = ({ active }) => {

    return (<>
        <div className={s.nav}>
            {active === 'award' ?
                <div className={s.navItem}>
                    <span className={s.navItemImage}><CupIcon size={16} fill="#DDBE90" /></span>
                    <span style={{ color: "#DDBE90" }} className={s.navItemTitle}>Награды</span>
                </div> :
                <Link to="/awards" className={s.navItem}>
                    <span className={s.navItemImage}><CupIcon size={16} stroke="#818181" /></span>
                    <span className={s.navItemTitle}>Награды</span>
                </Link>
            }

            {active === 'profile' ?
                <div className={s.navItem}>
                    <span className={s.navItemImage}><UserIcon size={16} fill="#DDBE90" /></span>
                    <span style={{ color: "#DDBE90" }} className={s.navItemTitle}>Профиль</span>
                </div> :
                <Link to="/profile" className={s.navItem}>
                    <span className={s.navItemImage}><UserIcon size={16} fill="#818181" /></span>
                    <span className={s.navItemTitle}>Профиль</span>
                </Link>
            }

            {active === 'album' ?
                <div className={s.navItem}>
                    <span className={s.navItemImage}><AlbumsIcon size={16} fill="#DDBE90" /></span>
                    <span style={{ color: "#DDBE90" }} className={s.navItemTitle}>Фотоальбом</span>
                </div> :
                <Link to="/albums" className={s.navItem}>
                    <span className={s.navItemImage}><AlbumsIcon size={16} fill="#818181" /></span>
                    <span className={s.navItemTitle}>Фотоальбом</span>
                </Link>
            }
        </div>
    </>)
}