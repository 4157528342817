
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import axios from "axios";
import { ENDPOINTS, REST_API } from "../../const/constants";

interface IAddReward {
    id: number;
    title: string;
    user: string;
}

interface AddRewardInitialStateType {
    loading: boolean,
    data: IAddReward
    success: boolean,
    error: boolean,
    errors: any,
}

const initialState: AddRewardInitialStateType = {
  loading: false,
  data: {} as IAddReward,
  success: false,
  error: false,
  errors: {},
};

interface IAddRewardReq {
    token: string | null,
    title: string,
    user: string,
    category: string
}

export const addReward = createAsyncThunk(
  "addReward",
  async (req: IAddRewardReq, { rejectWithValue }) => {
    const { token, title, user, category} = req;
    const url = REST_API + ENDPOINTS.rewards.rewards;
    const headers = {
        Authorization: `Bearer ${token}`,
      };
    try {
      const response = await axios.post(url, { title: title, user: user, category: category }, {headers: headers });
      return response.data;
    } catch (err) {
      const error: any = err;
      return rejectWithValue(error.response);
    }
  }
);

export const addRewardSlice = createSlice({
  name: "addReward",
  initialState,
  reducers: {
    addRewardAction: () => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(addReward.pending, (state:any) => {
        state.loading = true;
        state.success = false;
        state.errors.email = "";
      })
      .addCase(addReward.fulfilled, (state:any, { payload }:any) => {
        state.loading = false;
        state.data = payload
        state.error = false;
        state.success = true;
      })
      .addCase(addReward.rejected, (state:any, { payload }:any) => {
        const payl: any = payload;
        state.loading = false;
        state.success = false;
        state.error = true;
      });
  },
});

export const { addRewardAction } = addRewardSlice.actions;

export const AddReward = (state: RootState) => state.addReward;

export const AddRewardReducer = addRewardSlice.reducer;
