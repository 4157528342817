
import { AtIcon } from '../../icons/at-icon'
import { CustomInput } from '../common/CustomInput/CustomInput'
import s from './auth.module.scss'
import { CustomButtom } from '../common/CustomButton/CustomButton'
import { FC, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { ResetPassword, resetPassword } from '../../store/slice/resetPasswordSlice'
import { Skeleton, Space, Spin, message } from 'antd'

import { LockIcon } from '../../icons/lock-icon'
import { useFormik } from 'formik'
import { ResetSetNewPassword, resetSetNewPassword } from '../../store/slice/resetSetNwePassword'
import * as Yup from "yup";
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

interface IResetPasswordComponent {
    nft?: boolean
}

export const ResetPasswordComponent: FC<IResetPasswordComponent> = ({ nft }) => {
    const [searchParams] = useSearchParams()
    const key = searchParams.get('key')
    const [email, setEmail] = useState("")
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const dispatch = useAppDispatch()
    const { loading } = useAppSelector(ResetPassword)
    const router = useNavigate()
    const [perror, setpError] = useState('')
    const { loading: rLoading } = useAppSelector(ResetSetNewPassword)

    const resetPass = () => {
        dispatch(resetPassword({ email: email })).then((resp: any) => {
            if (!resp.error) {
                message.success('Письмо успешно отправлено. Следуйте инструкциям в письме')
                setTimeout(() => router('/auth'), 3000)
            }
        })
    }

    const RegisterSchema = Yup.object().shape({
        password: Yup.string().required("Обязательное поле"),
        repeat_password: Yup.string()
            .oneOf([Yup.ref("password"), undefined], "Пароли должны совпадать")
            .required("Обязательное поле"),
    });

    const formik = useFormik({
        initialValues: {
            password: '',
            repeat_password: ''
        },
        onSubmit: values => {
            !!key && dispatch(resetSetNewPassword({
                password: values.password,
                token: key
            })).then((resp: any) => {
                if (resp.error && !!resp.payload.data.password) {
                    setpError(resp.payload.data.password)
                }
                if (resp.error && !resp.payload.data.password) {
                    setpError("Ошибка. Возможно истек срок действия токена. Попробуйте запросить письмо еще раз")
                }
                if (!resp.error) {
                    message.success('Пароль успешно сохранен.')
                    if (!!resp.payload.access) {
                        localStorage.setItem('access', resp.payload.access)
                    }
                    if (!!resp.payload.resresh) {
                        localStorage.setItem('access', resp.payload.refresh)
                    }
                    setTimeout(() => router('/auth'), 3000)
                }
            })
        },
        validationSchema: RegisterSchema,
        validateOnChange: false,
        validateOnBlur: false,
    });

    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Восстановление пароля | Мои награды</title>
            <meta name="description" content="Ваши награды и результаты соревнований"
            />
        </Helmet>
        {!key && !nft && <Spin spinning={loading}>
            <div className={s.registerWrapper}>
                <h2 className={s.h2} style={{ marginBottom: '24px', textAlign: 'center' }}>Забыли пароль?</h2>
                <div className={s.switcher}>Введите адрес своей электронной почты и следуйте инструкциям в письме.</div>
                <div className={s.registrationSteps}>
                    <div className={s.stepBody}>
                        <CustomInput
                            name="email"
                            icon={<AtIcon />}
                            type="email"
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                            placeholder='Электронная почта'
                        />
                    </div>
                </div>

                <div className={s.stepsFooter}>

                    <CustomButtom
                        disabled={!email || !emailRegex.test(email)}
                        onClick={resetPass}
                        type='primary'
                    >Отправить
                    </CustomButtom>

                </div>
            </div>
        </Spin>}
        {!key && nft && <Spin spinning={loading}>
            <div className={s.registerWrapper}>
                <div className={s.hnuT}>
                    Приветствуем на обновленной версии моинаграды.рф.
                    Создайте пароль учетной записи.
                </div>
                <div className={s.hnuD}>
                    🏅Ваши медали уже собраны в новом профиле, время добавить новые достижения!
                </div>
                <div className={s.registrationSteps}>
                    <div className={s.stepBody}>
                        <CustomInput
                            name="email"
                            icon={<AtIcon />}
                            type="email"
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                            placeholder='Электронная почта'
                        />
                    </div>
                </div>

                <div className={s.stepsFooter}>

                    <CustomButtom
                        disabled={!email || !emailRegex.test(email)}
                        onClick={resetPass}
                        type='primary'
                    >Отправить
                    </CustomButtom>

                </div>
            </div>
        </Spin>}
        {!!key && !nft && <>
            <div className={s.registerWrapper}>
                <Spin spinning={rLoading}>
                    <form className={s.profileUserInfo} onSubmit={formik.handleSubmit}>
                        <div className={s.resetTitle}>
                            Введите новый пароль
                        </div>
                        <CustomInput
                            name="password"
                            icon={<LockIcon />}
                            type="password"
                            onChange={formik.handleChange}
                            placeholder='Введите пароль'
                        />
                        <CustomInput
                            name="repeat_password"
                            icon={<LockIcon />}
                            type="password"
                            onChange={formik.handleChange}
                            placeholder='Введите пароль еще раз'
                        />
                        {!!formik.errors.repeat_password && !!formik.touched.repeat_password ? (
                            <div className="form-error" style={{ marginBottom: '16px' }}>{formik.errors.repeat_password}</div>
                        ) : null}
                        {!!perror ? (
                            <div className="form-error" style={{ marginBottom: '16px' }}>{perror}</div>
                        ) : null}
                        <CustomButtom
                            onClick={() => {
                            }}
                            type='submit'
                        >Сменить пароль
                        </CustomButtom>
                    </form>
                </Spin>
            </div>
        </>}
        {!!key && nft && <>
            <div className={s.registerWrapper}>
                <Spin spinning={rLoading}>
                    <form className={s.profileUserInfo} onSubmit={formik.handleSubmit}>
                        <div className={s.hnuT}>
                            Приветствуем на обновленной версии моинаграды.рф.
                            Создайте пароль учетной записи.
                        </div>
                        <div className={s.hnuD}>
                            🏅Ваши медали уже собраны в новом профиле, время добавить новые достижения!
                        </div>
                        <CustomInput
                            name="password"
                            icon={<LockIcon />}
                            type="password"
                            onChange={formik.handleChange}
                            placeholder='Введите пароль'
                        />
                        <CustomInput
                            name="repeat_password"
                            icon={<LockIcon />}
                            type="password"
                            onChange={formik.handleChange}
                            placeholder='Введите пароль еще раз'
                        />
                        {!!formik.errors.repeat_password && !!formik.touched.repeat_password ? (
                            <div className="form-error" style={{ marginBottom: '16px' }}>{formik.errors.repeat_password}</div>
                        ) : null}
                        {!!perror ? (
                            <div className="form-error" style={{ marginBottom: '16px' }}>{perror}</div>
                        ) : null}
                        <CustomButtom
                            onClick={() => {
                            }}
                            type='submit'
                        >Создать пароль
                        </CustomButtom>
                    </form>
                </Spin>
            </div>
        </>}
    </>)
}