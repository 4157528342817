import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { MeSlice, getMe } from "../../store/slice/meSlice";
import { updateUser } from "../../store/slice/userUpdateSlice";
import { useFormik } from "formik";
import { MnInput } from "../common/MnInput/MnInput";
import s from './profile.module.scss'
import { DatePicker, Select } from "antd";
import { CustomButtom } from "../common/CustomButton/CustomButton";
import moment from "moment";
import dayjs from "dayjs";
import { GetCountries } from "../../store/slice/getCountriesSlice";
import { RangePickerProps } from "antd/es/date-picker";
import { FC, MutableRefObject, useRef, useState } from "react";
import { CustomSelect } from "../common/CustomSelect/CustomSelect";
import { GetRelatedUsers, getRelatedUsers } from "../../store/slice/getRelatedUsers";
import { updateRelated } from "../../store/slice/updateRelated";
import { UserIcon } from "../../icons/user-icon";
import { PhotoIcon } from "../../icons/photo-icon";
import { setRelatedPhoto } from "../../store/slice/setRelatedPhotoSlice";
import { setPhoto } from "../../store/slice/setUserPhotoSlice";

interface IProfileEditInfo {
    setStatus: (status: boolean) => void
    subuser?: boolean
}

export const ProfileEditInfo: FC<IProfileEditInfo> = ({ setStatus, subuser }) => {
    const dispatch = useAppDispatch()
    const { data } = useAppSelector(MeSlice)
    const { loading: rLoading, data: rData } = useAppSelector(GetRelatedUsers)
    const { data: cData } = useAppSelector(GetCountries)
    const today = new Date()
    const [allowedDay, setAllowerDay] = useState(dayjs(today).year(Number(dayjs().year()) - 5))
    const inputFile = useRef() as MutableRefObject<HTMLInputElement>

    const formik = useFormik({
        initialValues: {
            firstname: subuser ? rData[0]?.profile?.first_name : data?.profile?.first_name,
            lastname: subuser ? rData[0]?.profile?.last_name : data?.profile?.last_name,
            middlename: subuser ? rData[0]?.profile?.middle_name : data?.profile?.middle_name,
            userGender: subuser ? rData[0]?.profile?.gender : data?.profile?.gender,
            bio: subuser ? rData[0]?.profile?.bio : data?.profile?.bio,
            birth: subuser ? !!rData[0]?.profile?.birth_date ? rData[0]?.profile?.birth_date : dayjs(allowedDay).format("YYYY-MM-DD") : !!data?.profile?.birth_date ? data?.profile?.birth_date : dayjs(allowedDay).format("YYYY-MM-DD"),
            country: subuser ? rData[0]?.profile?.country?.id : data?.profile?.country?.id,
            countryId: subuser ? rData[0]?.profile?.country?.id : data?.profile?.country?.id,
            city: subuser ? !!rData[0]?.profile?.city?.name ? rData[0]?.profile?.city?.name : undefined : !!data?.profile?.city?.name ? data?.profile?.city.name : undefined,
        },

        onSubmit: values => {
            !subuser && dispatch(updateUser({
                token: localStorage.getItem("access"),
                id: data.id,
                data: {
                    first_name: values.firstname,
                    last_name: values.lastname,
                    gender: values.userGender,
                    bio: values.bio,
                    middle_name: values.middlename,
                    birth_date: values.birth,
                    country: values.country,
                    city_name: values.city
                }
            })).then((resp: any) => {
                if (!resp.error) {
                    dispatch(getMe({ token: localStorage.getItem("access") })).then((res: any) => {
                        if (!res.error) {
                            setStatus(false)
                        }
                    })
                }
            })
            subuser && dispatch(updateRelated({
                token: localStorage.getItem("access"),
                id: rData[0].id,
                data: {
                    first_name: values.firstname,
                    last_name: values.lastname,
                    gender: values.userGender,
                    bio: values.bio,
                    middle_name: values.middlename,
                    birth_date: values.birth,
                    country: values.country,
                    city_name: values.city
                }
            })).then((resp: any) => {
                if (!resp.error) {
                    dispatch(getRelatedUsers({ token: localStorage.getItem('access') })).then((res: any) => {
                        if (!res.error) {
                            setStatus(false)
                        }
                    })
                }
            })
        },
    });

    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        // Can not select days before today and today
        return current && current > allowedDay;
    };

    const clickHandler = (e: any) => {
        e.preventDefault()
        const files = Array.prototype.slice.call(inputFile.current.files);
        const formData = new FormData();
        formData.append('file', files[0]);
        const token = localStorage.getItem('access');
        dispatch(setPhoto({
            token: token,
            data: formData
        })).then((resp: any) => {
            if (!resp.error) {
                dispatch(getMe({ token: localStorage.getItem("access") }))
            }
        })
    }

    const clickHandler1 = (e: any) => {
        e.preventDefault()
        const files = Array.prototype.slice.call(inputFile.current.files);
        const formData = new FormData();
        formData.append('file', files[0]);
        const token = localStorage.getItem('access');
        dispatch(setRelatedPhoto({
            token: token,
            id: rData[0].id,
            data: formData
        })).then((resp: any) => {
            if (!resp.error) {
                dispatch(getRelatedUsers({ token: localStorage.getItem('access') }))
            }
        })
    }


    return (<>
        <form className={s.profileUserInfo} onSubmit={formik.handleSubmit}>
            <div className={s.formTop}>
                {!subuser && <><div className={s.profileEditAvatar}>
                    {data?.profile?.image !== null ?
                        <img loading="lazy" style={{ minWidth: 98 }} className={s.userAvatar} width={98} height={98} alt="" src={data?.profile?.image} /> :
                        <UserIcon size={28} fill="#E9E9E9" />}
                </div>
                    <input onChange={e => { clickHandler(e) }} type='file' id='file' ref={inputFile} style={{ display: 'none' }} />
                    <CustomButtom
                        onClick={() => {
                            inputFile.current.click();
                        }}
                        type='secondary'
                    ><PhotoIcon /> Загрузить фотографию
                    </CustomButtom>
                </>}

                {subuser && <><div className={s.profileAvatar}>
                    {data?.profile?.image !== null ?
                        <img loading="lazy" style={{ minWidth: 150 }} className={s.userAvatar} width={98} height={98} alt="" src={rData[0]?.profile?.image} /> :
                        <UserIcon size={28} fill="#E9E9E9" />}
                </div>
                    <input onChange={e => { clickHandler(e) }} type='file' id='file' ref={inputFile} style={{ display: 'none' }} />
                    <CustomButtom
                        onClick={() => {
                            inputFile.current.click();
                        }}
                        type='secondary'
                    ><PhotoIcon /> Загрузить фотографию
                    </CustomButtom>
                </>}

                <MnInput
                    key="lastname"
                    name="lastname"
                    type="text"
                    defaultValue={formik.values.lastname}
                    onChange={formik.handleChange}
                    placeholder='Фамилия'
                />
                <MnInput
                    key="firstname"
                    name="firstname"
                    type="text"
                    defaultValue={formik.values.firstname}
                    onChange={formik.handleChange}
                    placeholder='Имя'
                />
                <MnInput
                    key="middlename"
                    name="middlename"
                    type="text"
                    defaultValue={formik.values.middlename}
                    onChange={formik.handleChange}
                    placeholder='Отчество'
                />

                <div className={s.formLine}>
                    <CustomSelect
                        className={s.select}
                        placeholder="Пол"
                        defaultValue={formik.values.userGender !== '' ? formik.values.userGender : null}
                        defaultMobValue={formik.values.userGender !== '' ? formik.values.userGender : undefined}
                        onChange={(e) => {
                            formik.setFieldValue('userGender', e)
                        }}
                        options={[
                            { value: 'male', label: 'Муж' },
                            { value: 'female', label: 'Жен' },
                        ]}
                    />
                    <DatePicker
                        className={s.date}
                        disabledDate={disabledDate}
                        defaultValue={data?.profile?.birth_date ? dayjs(data.profile.birth_date) : allowedDay}
                        placeholder='Дата рождения' onChange={(e) => {
                            formik.setFieldValue('birth', dayjs(e).format("YYYY-MM-DD"))
                        }} />
                </div>
                <div className={s.formLine}>
                    <CustomSelect
                        className={s.select}
                        placeholder="Страна"
                        defaultValue={formik.values.countryId}
                        defaultMobValue={formik.values.countryId}
                        onChange={(e) => {
                            formik.setFieldValue('country', e)
                        }}
                        options={cData.map(c => ({ label: c.name, value: c.id }))}
                    />
                    <MnInput
                        key="city"
                        name="city"
                        type="text"
                        defaultValue={formik.values.city}
                        onChange={formik.handleChange}
                        placeholder='Город'
                    />
                </div>
                <MnInput
                    key="bio"
                    name="bio"
                    type="textarea"
                    onChange={formik.handleChange}
                    defaultValue={data.profile.bio}
                    placeholder='Информация о себе'
                />
            </div>
            <div className={s.profileUserInfoFooter}>
                <CustomButtom
                    type='submit'
                    onClick={() => { }}>
                    Изменить
                </CustomButtom>
            </div>
        </form>
    </>)
}