import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { ENDPOINTS, REST_API } from "../../const/constants";
import axios from "axios";


type setRelatedPhotoInitialStateErrorType = {
    detail: string
}

interface IVIdeo {
    id: number,
    upload_link: string,
    video: string,
    uploaded: boolean,
    youtube_id: string,
    tik_tok_public: boolean,
    boarding_marketing: boolean
}

interface ISetRelatedPhoto {
    id: string,
    host: string,
    video: IVIdeo
}

export type setRelatedPhotoInitialStateType = {
    loading: boolean,
    data: ISetRelatedPhoto,
    success: boolean,
    getSuccess: boolean,
    error: boolean,
    errors: setRelatedPhotoInitialStateErrorType,
}


const initialState: setRelatedPhotoInitialStateType = {
  loading: false,
  data: {} as ISetRelatedPhoto,
  success: false,
  getSuccess: false,
  error: false,
  errors: {} as setRelatedPhotoInitialStateErrorType,
};

interface IExtension {
  extension: string
}

export type IsetRelatedPhotoReq = {
    token: string | null,
    id: string,
    data?: FormData
}

export const setRelatedPhoto = createAsyncThunk(
  "setRelatedPhoto",
  async (req: IsetRelatedPhotoReq, { rejectWithValue }) => {
    const {token, id, data} = req
    const url = REST_API + ENDPOINTS.user.related + id + '/photo/';
    const headers = {
        Authorization: `Bearer ${token}`,
      }; 
    try {
      const response = await axios.post(url, data, {headers: headers});
      return response.data;
    } catch (err) {
      const error: any = err;
      return rejectWithValue(error.response);
    }
  }
);


export const setRelatedPhotoSlice = createSlice({
  name: "setRelatedPhoto",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(setRelatedPhoto.pending, (state) => {
        state.loading = true;
        state.success = false;
      })
      .addCase(setRelatedPhoto.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload
        state.error = false;
        state.success = true;
      })
      .addCase(setRelatedPhoto.rejected, (state, { payload }) => {
        state.loading = false;
        state.success = false;
        state.error = true;
      })
  },
});

export const SetRelatedPhoto = (state: RootState) => state.setRelatedPhoto;

export const SetRelatedPhotoReducer = setRelatedPhotoSlice.reducer;
