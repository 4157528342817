import { createBrowserRouter } from "react-router-dom";
import { Authentication } from "../components/Authentification/Authentification";
import { Profile } from "../components/Profile/Prolile";
import { Splash } from "../components/Splash/Splash";
import { Album } from "../components/Album/Album";
import { EditAlbum } from "../components/Album/EditAlbum";
import { AddAlbums } from "../components/Album/AddAlbums";
import { Albums } from "../components/Album/Albums";
import { Award } from "../components/Award/Award";
import { AddAwards } from "../components/Award/AddAwards";
import { Awards } from "../components/Award/Awards";
import { OAuth } from "../components/Authentification/OAuth";
import { EmailConfirm } from "../components/Authentification/EmailConfirm";
import { Renew } from "../components/Subscription/Renew";
import { ResetPasswordComponent } from "../components/Authentification/ResetPassword";
import { RestoreUserComponent } from "../components/Authentification/RestoreUser";
import { RenewInfinity } from "../components/Subscription/Infinity";
import { Unsubscribe } from "../components/Subscription/Unsubscribe";
import { User } from "../components/user";
import { OpenId } from "../components/Authentification/openId";

export const appRouter = createBrowserRouter([
    {
        path: "/",
        element: <Splash />,
    },
    {
        path: "/auth/openid",
        element: <OpenId />,
    },
    {
        path: "/auth",
        element: <Authentication loginProp={true} />,
    },
    {
        path: "/signup",
        element: <Authentication  loginProp={false} />,
    },
    {
        path: "/login",
        element: <OAuth />,
    },
    {
        path: "/registration",
        element: <EmailConfirm />,
    },
    {
        path: "/renew-subscription",
        element: <Renew />,
    },
    {
        path: "/reset-password",
        element: <ResetPasswordComponent />,
    },
    {
        path: "/restore",
        element: <RestoreUserComponent />,
    },
    {
        path: "/subscriptions/free-trial",
        element: <RenewInfinity />,
    },
    {
        path: "/subscriptions/infinity",
        element: <RenewInfinity />,
    },
    {
        path: "/unsubscribe",
        element: <Unsubscribe />,
    },
    {
        path: "/profile",
        element: <Profile />,
    },
    {
        path: "/albums/:id",
        element: <Album />,
    },
    {
        path: "/albums/:id/edit",
        element: <EditAlbum />,
    },
    {
        path: "/albums/add",
        element: <AddAlbums />,
    },
    {
        path: "/albums",
        element: <Albums />,
    },
    {
        path: "/awards/:id",
        element: <Award />,
    },
    {
        path: "/awards/add",
        element: <AddAwards />,
    },
    {
        path: "/awards",
        element: <Awards />,
    },
    {
        path: "/user/:id",
        element: <User />,
    },
]);