import { FC } from "react"

interface IIcon {
    fill?: string;
    stroke?: string;
    size?: number;
}

export const PhotoIcon: FC<IIcon> = ({ fill = "#818181", size=23 }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 23 22" fill="none">
            <path d="M11.5 16.0417C12.6459 16.0417 13.62 15.6405 14.4224 14.8381C15.2248 14.0357 15.6257 13.0619 15.625 11.9167C15.625 10.7708 15.2238 9.79672 14.4215 8.99433C13.6191 8.19194 12.6453 7.79106 11.5 7.79167C10.3542 7.79167 9.3801 8.19286 8.57771 8.99525C7.77532 9.79764 7.37443 10.7714 7.37504 11.9167C7.37504 13.0625 7.77624 14.0366 8.57863 14.839C9.38101 15.6414 10.3548 16.0423 11.5 16.0417ZM11.5 14.2083C10.8584 14.2083 10.316 13.9868 9.87296 13.5437C9.4299 13.1007 9.20838 12.5583 9.20838 11.9167C9.20838 11.275 9.4299 10.7326 9.87296 10.2896C10.316 9.84653 10.8584 9.625 11.5 9.625C12.1417 9.625 12.6841 9.84653 13.1271 10.2896C13.5702 10.7326 13.7917 11.275 13.7917 11.9167C13.7917 12.5583 13.5702 13.1007 13.1271 13.5437C12.6841 13.9868 12.1417 14.2083 11.5 14.2083ZM4.16671 19.25C3.66254 19.25 3.23079 19.0703 2.87146 18.711C2.51213 18.3517 2.33276 17.9202 2.33338 17.4167V6.41667C2.33338 5.9125 2.51304 5.48075 2.87238 5.12142C3.23171 4.76208 3.66315 4.58272 4.16671 4.58333H7.05421L8.75004 2.75H14.25L15.9459 4.58333H18.8334C19.3375 4.58333 19.7693 4.763 20.1286 5.12233C20.488 5.48167 20.6673 5.91311 20.6667 6.41667V17.4167C20.6667 17.9208 20.487 18.3526 20.1277 18.7119C19.7684 19.0713 19.3369 19.2506 18.8334 19.25H4.16671ZM18.8334 17.4167V6.41667H15.1209L13.448 4.58333H9.55213L7.87921 6.41667H4.16671V17.4167H18.8334Z" fill={fill} />
        </svg>
    )
}