import { FC } from "react"

interface IIcon {
    fill?: string;
    stroke?: string;
    size?: number
}

export const CrownIcon: FC<IIcon> = ({ fill = "#818181" }) => {
    return (
        <svg width="48" height="61" viewBox="0 0 48 61" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M47.2287 24.857C47.009 24.6637 46.7373 24.5406 46.448 24.5033C46.1588 24.4661 45.8651 24.5164 45.6042 24.6478L38.0265 28.458L31.7636 17.9601C31.6322 17.7401 31.4467 17.5582 31.225 17.4318C31.0033 17.3055 30.7531 17.2391 30.4984 17.2391C30.2438 17.2391 29.9936 17.3055 29.7719 17.4318C29.5502 17.5582 29.3647 17.7401 29.2333 17.9601L22.9704 28.458L15.3956 24.6493C15.1348 24.5179 14.8413 24.4675 14.5521 24.5042C14.2629 24.541 13.9909 24.6633 13.7706 24.8557C13.5502 25.0482 13.3913 25.3021 13.3139 25.5856C13.2366 25.869 13.2441 26.1692 13.3357 26.4483L18.7411 43.1506C18.7818 43.2767 18.8501 43.3919 18.9409 43.4877C19.0318 43.5835 19.1428 43.6575 19.2658 43.7042C19.3889 43.7509 19.5207 43.7691 19.6517 43.7574C19.7826 43.7458 19.9093 43.7046 20.0223 43.6368C20.0325 43.6368 20.9806 43.071 22.7396 42.52C24.3626 42.0102 27.023 41.4032 30.4984 41.4032C33.9739 41.4032 36.6343 42.0102 38.2588 42.52C40.0119 43.071 40.9658 43.6309 40.9731 43.6354C41.0861 43.7034 41.2128 43.7449 41.3438 43.7568C41.4749 43.7687 41.6069 43.7507 41.7301 43.7042C41.8533 43.6576 41.9645 43.5837 42.0555 43.4878C42.1465 43.392 42.215 43.2768 42.2558 43.1506L47.6612 26.4512C47.7554 26.1723 47.7646 25.8714 47.6875 25.5872C47.6104 25.303 47.4506 25.0487 47.2287 24.857ZM40.9045 41.6286C39.2975 40.9037 35.7329 39.6351 30.4984 39.6351C25.264 39.6351 21.6994 40.9037 20.0924 41.6286L15.2057 26.5293L22.4153 30.1538C22.7481 30.3193 23.1303 30.3531 23.4866 30.2487C23.8429 30.1442 24.1476 29.9091 24.3407 29.5895L30.4984 19.2758L36.6562 29.5969C36.8492 29.9161 37.1537 30.151 37.5097 30.2554C37.8657 30.3598 38.2475 30.3262 38.5802 30.1612L45.7912 26.5352L40.9045 41.6286Z" fill="#DDBE90" />
            <rect x="0.5" y="0.5" width="60" height="60" rx="30" stroke="#DDBE90" />
        </svg>
    )
}


