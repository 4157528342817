
import layout from '../../styles/_layout.module.scss'
import s from "./award.module.scss"
import { Nav } from '../Nav/Nav'
import { ShareIcon } from '../../icons/share-icon';
import { Dropdown, MenuProps, Skeleton, Spin } from 'antd';
import { UserIcon } from '../../icons/user-icon';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { MeSlice, getMe } from '../../store/slice/meSlice';
import { ThreeDotsIcon } from '../../icons/threedots-icon';
import { useEffect, useState } from 'react';
import { CustomInput } from '../common/CustomInput/CustomInput';
import { SearchIcon } from '../../icons/search-icon';
import { BlocksViewIcon } from '../../icons/blocksview-icon';
import { TableViewIcon } from '../../icons/tableview-icon';
import { AddAwardPlusIcon } from '../../icons/add-award-plus-icon';
import { Overlay } from '../common/Overlay/Overlay';
import { AddAward } from './AddAward';
import { getSports } from '../../store/slice/getSportsSlice';
import { PoloskiIcon } from '../../icons/poloski-icon';
import { AwardItem } from './AwardItem';
import { GetRewards, getRewards } from '../../store/slice/getRewardsSlice';
import { GetUserRewards, IReward, getUserRewards } from '../../store/slice/getUserRewardsSlice';
import { useDebouncedCallback } from 'use-debounce';
import { customRoute } from '../../utilities';
import { Scripts } from '../common/Scripts/Scripts';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

interface IInitialState {
    id: string,
    title: string,
    category: string
}

export const Awards = () => {
    const { success, data, loading } = useAppSelector(MeSlice)
    const { success: rSuccess, loading: rLoading, data: rewards } = useAppSelector(GetUserRewards)
    const dispatch = useAppDispatch()
    const router = useNavigate()
    const [tableMode, setTableMode] = useState(false)
    const [filterStatus, setFilterStatus] = useState(false)
    const [addStatus, setAddStatus] = useState(false)
    const defaultState: IInitialState = {} as IInitialState
    const [category, setCategory] = useState<'sport' | 'non_sport'>('sport')
    const [initialState, setInitialState] = useState<IInitialState>(defaultState);
    const items: MenuProps['items'] = [
        {
            label: <span className={layout.dropDownItem} onClick={() => { }}><span className={layout.dropDownItemIcon}><ShareIcon size={9} fill='#FFFFFF' /></span> Поделиться</span>,
            key: '0',
        }
    ];

    useEffect(() => {
        dispatch(getMe({ token: localStorage.getItem("access") })).then((resp: any) => {
            if (resp.error) {
                customRoute('/auth')
            }
        })

        // dispatch(getSports({ token: localStorage.getItem("access") }))
    }, [])

    useEffect(() => {
        setInitialState({ ...defaultState, id: data.id })
        if (!loading && success) {
            dispatch(getUserRewards({
                id: data.id
            }))
        }
    }, [data.id])

    useEffect(() => {
        // console.log(initialState)
        if (!loading && success && !!initialState.id) {
            dispatch(getUserRewards({
                ...initialState
            }))
        }
    }, [initialState])

    const debounced = useDebouncedCallback(
        (query) => {
            if (query.length === 0) {
                setInitialState({ ...defaultState, id: data.id, title: '' })
            } else {
                setInitialState({ ...defaultState, id: data.id, title: query.target.value })
            }
        },
        1000
    );

    const setCat = (cat: string) => {
        setInitialState({ ...initialState, category: cat })
    }
    const add = (cat: 'sport' | 'non_sport') => {
        setCategory(cat)
        setAddStatus(true)
    }

    const AwardsFilters = () => {
        return (
            <>
                <div className={s.filtersLine}>
                    <label className={layout.selectLabel}>
                        <span className={layout.selectTitle}>Все</span>
                        <input defaultChecked={initialState.category === ''} className={layout.selectInput} style={{ display: "none" }} onChange={() => { setCat('') }} type="radio" name="category[]" value="personally" />
                        <span></span>
                    </label>
                    <label className={layout.selectLabel}>
                        <span className={layout.selectTitle}>Спортивные</span>
                        <input defaultChecked={initialState.category === 'sport'} className={layout.selectInput} style={{ display: "none" }} onChange={() => { setCat('sport') }} type="radio" name="category[]" value="children" />
                        <span></span>
                    </label>
                    <label className={layout.selectLabel}>
                        <span className={layout.selectTitle}>Неспортивные</span>
                        <input defaultChecked={initialState.category === 'non_sport'} className={layout.selectInput} style={{ display: "none" }} onChange={() => { setCat('non_sport') }} type="radio" name="category[]" value="teacher" />
                        <span></span>
                    </label>
                </div >
            </>
        )
    }


    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Список наград | Мои награды</title>
            <meta name="description" content="Ваши награды и результаты соревнований"
            />
        </Helmet>
        <div className={layout.container}>
            <div className={layout.content}>
                <div className={s.header}>
                    <div className={s.userName}>
                        <UserIcon size={16} fill='#818181' /> <span>{data?.profile?.full_name}</span>
                    </div>
                    {/* <div className={layout.headerButtons}>
                        <Dropdown overlayClassName={'profileDropDown'} menu={{ items }} trigger={['click']}>
                            <div className={layout.round} onClick={(e) => e.preventDefault()}>
                                <ThreeDotsIcon />
                            </div>
                        </Dropdown>
                    </div> */}
                </div>
                {rSuccess && !rLoading && defaultState === {} as IInitialState && rewards === [] as IReward[] ? <>
                    <div className={layout.awardList}>
                        <div onClick={() => add('sport')} className={s.addAwardItem}>
                            <div style={{
                                width: '100%',
                                height: '106px',
                                background: 'url(/images/sportReward.png) no-repeat center',
                                borderRadius: '4px',
                                backgroundSize: 'cover'
                            }} className={layout.awardItemImageContainer}></div>
                                <div className={s.addAwTitle}>Спортивная награда</div>
                                <div className={s.addAwDesc}>Добавить все мои спортивные награды</div>
                                <div className={s.addAwBtn}>Добавить <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.25 8.66536H8.91671V11.9987C8.91671 12.1755 8.84647 12.3451 8.72145 12.4701C8.59642 12.5951 8.42685 12.6654 8.25004 12.6654C8.07323 12.6654 7.90366 12.5951 7.77864 12.4701C7.65361 12.3451 7.58337 12.1755 7.58337 11.9987V8.66536H4.25004C4.07323 8.66536 3.90366 8.59513 3.77864 8.4701C3.65361 8.34508 3.58337 8.17551 3.58337 7.9987C3.58337 7.82189 3.65361 7.65232 3.77864 7.52729C3.90366 7.40227 4.07323 7.33203 4.25004 7.33203H7.58337V3.9987C7.58337 3.82189 7.65361 3.65232 7.77864 3.52729C7.90366 3.40227 8.07323 3.33203 8.25004 3.33203C8.42685 3.33203 8.59642 3.40227 8.72145 3.52729C8.84647 3.65232 8.91671 3.82189 8.91671 3.9987V7.33203H12.25C12.4269 7.33203 12.5964 7.40227 12.7214 7.52729C12.8465 7.65232 12.9167 7.82189 12.9167 7.9987C12.9167 8.17551 12.8465 8.34508 12.7214 8.4701C12.5964 8.59513 12.4269 8.66536 12.25 8.66536Z" fill="#DDBE90" />
                                </svg>
                                </div>
                            
                        </div>
                        <div onClick={() => add('non_sport')} className={s.addAwardItem}>
                            <div style={{
                                width: '100%',
                                height: '106px',
                                background: 'url(/images/nonSportReward.png) no-repeat center',
                                borderRadius: '4px',
                                backgroundSize: 'cover'
                            }} className={layout.awardItemImageContainer}></div>
                            <div className={s.addAwTitle}>Неспортивная награда</div>
                            <div className={s.addAwDesc}>Добавить все мои неспортивные награды</div>
                            <div className={s.addAwBtn}>Добавить <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.25 8.66536H8.91671V11.9987C8.91671 12.1755 8.84647 12.3451 8.72145 12.4701C8.59642 12.5951 8.42685 12.6654 8.25004 12.6654C8.07323 12.6654 7.90366 12.5951 7.77864 12.4701C7.65361 12.3451 7.58337 12.1755 7.58337 11.9987V8.66536H4.25004C4.07323 8.66536 3.90366 8.59513 3.77864 8.4701C3.65361 8.34508 3.58337 8.17551 3.58337 7.9987C3.58337 7.82189 3.65361 7.65232 3.77864 7.52729C3.90366 7.40227 4.07323 7.33203 4.25004 7.33203H7.58337V3.9987C7.58337 3.82189 7.65361 3.65232 7.77864 3.52729C7.90366 3.40227 8.07323 3.33203 8.25004 3.33203C8.42685 3.33203 8.59642 3.40227 8.72145 3.52729C8.84647 3.65232 8.91671 3.82189 8.91671 3.9987V7.33203H12.25C12.4269 7.33203 12.5964 7.40227 12.7214 7.52729C12.8465 7.65232 12.9167 7.82189 12.9167 7.9987C12.9167 8.17551 12.8465 8.34508 12.7214 8.4701C12.5964 8.59513 12.4269 8.66536 12.25 8.66536Z" fill="#DDBE90" />
                            </svg>
                            </div>
                        </div>
                    </div>
                </> : <>
                    <div className={s.title}>Награды</div>
                    <div className={s.search}>
                        <CustomInput
                            name="find-reward"
                            icon={<SearchIcon />}
                            type="text"
                            onChange={debounced}
                            placeholder='Введите название награды'
                            autocomplete='off'
                        />
                    </div>
                    <div className={s.filters}>
                        <div className={s.filterButtonBLock}>
                            <div onClick={() => { setFilterStatus(true) }}><PoloskiIcon /></div>
                            {initialState.category === '' && 'Фильтры'}
                            {initialState.category === 'sport' && <span className={s.filtersItem}>Спортивные <span onClick={() => { setCat('') }}><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.74845 6L8.84339 3.90505C8.94282 3.8058 8.99875 3.67112 8.99887 3.53064C8.99899 3.39015 8.94331 3.25537 8.84406 3.15594C8.74481 3.05652 8.61012 3.00059 8.46964 3.00047C8.32915 3.00034 8.19437 3.05603 8.09495 3.15528L6 5.25023L3.90505 3.15528C3.80563 3.05586 3.67078 3 3.53017 3C3.38956 3 3.25471 3.05586 3.15528 3.15528C3.05586 3.25471 3 3.38956 3 3.53017C3 3.67078 3.05586 3.80563 3.15528 3.90505L5.25023 6L3.15528 8.09495C3.05586 8.19437 3 8.32922 3 8.46983C3 8.61044 3.05586 8.74529 3.15528 8.84472C3.25471 8.94414 3.38956 9 3.53017 9C3.67078 9 3.80563 8.94414 3.90505 8.84472L6 6.74977L8.09495 8.84472C8.19437 8.94414 8.32922 9 8.46983 9C8.61044 9 8.74529 8.94414 8.84472 8.84472C8.94414 8.74529 9 8.61044 9 8.46983C9 8.32922 8.94414 8.19437 8.84472 8.09495L6.74845 6Z" fill="#CDCDCD" />
                            </svg>
                            </span></span>}
                            {initialState.category === 'non_sport' && <span className={s.filtersItem}>Неспортивные <span onClick={() => { setCat('') }}><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.74845 6L8.84339 3.90505C8.94282 3.8058 8.99875 3.67112 8.99887 3.53064C8.99899 3.39015 8.94331 3.25537 8.84406 3.15594C8.74481 3.05652 8.61012 3.00059 8.46964 3.00047C8.32915 3.00034 8.19437 3.05603 8.09495 3.15528L6 5.25023L3.90505 3.15528C3.80563 3.05586 3.67078 3 3.53017 3C3.38956 3 3.25471 3.05586 3.15528 3.15528C3.05586 3.25471 3 3.38956 3 3.53017C3 3.67078 3.05586 3.80563 3.15528 3.90505L5.25023 6L3.15528 8.09495C3.05586 8.19437 3 8.32922 3 8.46983C3 8.61044 3.05586 8.74529 3.15528 8.84472C3.25471 8.94414 3.38956 9 3.53017 9C3.67078 9 3.80563 8.94414 3.90505 8.84472L6 6.74977L8.09495 8.84472C8.19437 8.94414 8.32922 9 8.46983 9C8.61044 9 8.74529 8.94414 8.84472 8.84472C8.94414 8.74529 9 8.61044 9 8.46983C9 8.32922 8.94414 8.19437 8.84472 8.09495L6.74845 6Z" fill="#CDCDCD" />
                            </svg>
                            </span></span>}
                        </div>
                        {/* <div className={s.changeView}>
                            {!tableMode && <div className={s.changeViewButton}>
                                <BlocksViewIcon background="#DDBE90" color="#FFFFFF" />
                            </div>}
                            {tableMode && <div onClick={() => setTableMode(false)} className={s.changeViewButton}>
                                <BlocksViewIcon background="#E9E9E9" color="#CDCDCD" />
                            </div>}
                            {!tableMode && <div className={s.changeViewButton} onClick={() => setTableMode(true)}>
                                <TableViewIcon background="#E9E9E9" color="#CDCDCD" />
                            </div>}
                            {tableMode && <div className={s.changeViewButton}>
                                <TableViewIcon background="#DDBE90" color="#FFFFFF" />
                            </div>}
                        </div> */}
                    </div>
                    <div className={layout.awardListWrap}>
                        <Spin spinning={rLoading}>
                            <div className={layout.awardList}>
                                {rewards?.map((reward: IReward, index: number) => {
                                    return (<AwardItem award={reward} key={index} />)
                                })}
                            </div>
                        </Spin>
                    </div></>}
            </div>
            <div className={layout.footer}>
                <Nav active='award' />
            </div>
            <span onClick={() => { setAddStatus(true) }} className={layout.addAwardButton}>
                <AddAwardPlusIcon />
            </span>
            <Scripts />
            {
                filterStatus && <Overlay
                    title={'Фильтры'}
                    status={filterStatus}
                    setStatus={setFilterStatus}>
                    <AwardsFilters />
                </Overlay>
            }
            {addStatus && <Overlay
                title={'Добавление награды'}
                status={addStatus}
                setStatus={setAddStatus}>
                <AddAward cat={category} />
            </Overlay>}
        </div >
        </>
    )
}

