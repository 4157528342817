
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import axios from "axios";
import { ENDPOINTS, REST_API } from "../../const/constants";


interface IResetPassword {
    detail: string
}

interface ResetPasswordInitialStateType {
    loading: boolean,
    data: IResetPassword
    success: boolean,
    error: boolean,
    errors: any,
}

const initialState: ResetPasswordInitialStateType = {
  loading: false,
  data: {} as IResetPassword,
  success: false,
  error: false,
  errors: {},
};

interface IResetPasswordReq {
    email: string;
}

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (req: IResetPasswordReq, { rejectWithValue }) => {
    const url = REST_API + ENDPOINTS.password_reset.request
    try {
      const response = await axios.post(url, { ...req }, {});
      return response.data;
    } catch (err) {
      const error: any = err;
      return rejectWithValue(error.response);
    }
  }
);

export const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState,
  reducers: {
    resetPasswordAction: () => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetPassword.pending, (state:any) => {
        state.loading = true;
        state.success = false;
        state.errors.email = "";
      })
      .addCase(resetPassword.fulfilled, (state:any, { payload }:any) => {
        state.loading = false;
        state.data = payload
        state.error = false;
        state.success = true;
      })
      .addCase(resetPassword.rejected, (state:any, { payload }:any) => {
        const payl: any = payload;
        state.loading = false;
        state.success = false;
        state.error = true;
      });
  },
});

export const { resetPasswordAction } = resetPasswordSlice.actions;

export const ResetPassword = (state: RootState) => state.resetPassword;

export const ResetPasswordReducer = resetPasswordSlice.reducer;
