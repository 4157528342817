import { FC } from 'react'
import s from './link-btn.module.scss'

interface ILinkBtn {
    children: any;
    onClick: () => void

}

export const LinkBtn: FC<ILinkBtn> = ({ children, onClick }) => {
    return (<>
        <span
            className={s.LinkBtn}
            onClick={onClick}
        >{children}</span>
    </>)
}