import { FC } from 'react'
import s from './error.module.scss'
import { OtmenaIcon } from '../../../icons/otmena-icon'

interface IError {
    title?: string,
    heading?: string
}

export const Error: FC<IError> = ({ title, heading }) => {
    return (
        <div className={s.modalSuccess}>
            <div className={s.errorModalIcon}><OtmenaIcon /></div>
            <div className={s.errorModalHeading}>{heading}</div>
            {!!title && <div className={s.errorModalTitle}>&laquo;{title}&raquo;</div>}
        </div>
    )
}