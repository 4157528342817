
import s from './custom-input.module.scss';
import { FC, useEffect, useState } from "react";
import classnames from 'classnames';

import { EyeIcon } from '../../../icons/eye-icon';
import ReactTextareaAutosize from 'react-textarea-autosize';


const cn = classnames.bind(this)

interface ICustomInout {
    defaultValue?: string
    name: string;
    icon?: any;
    type: "text" | "password" | "email" | "number" | "textarea";
    onChange: (e: any) => any;
    placeholder?: string;
    style?: any;
    min?: number;
    max?: number;
    rows?: number
    autocomplete?: string
}

export const CustomInput: FC<ICustomInout> = ({ name, icon, type = "text", defaultValue, onChange, placeholder, style, min, max, rows, autocomplete }) => {
    const [fieldType, setFieldType] = useState('text')
    const [passVisible, setPassVisible] = useState(false)

    useEffect(()=>{
        setFieldType(type)
    },[])

const passHandler = () =>{
    if(passVisible){
        setPassVisible(false)
        setFieldType('password')
    } else {
        setPassVisible(true)
        setFieldType('text')
    }
}

    return (<>
        <div style={style} className={s.mnInput}>
            {icon && <div className={s.icon}>{icon}</div>}
            {type === 'textarea' ?
                <ReactTextareaAutosize
                    className={cn(s.textarea)}
                    name={name}
                    onChange={onChange}
                    placeholder={placeholder}
                    rows={rows}
                >
                    {defaultValue}
                </ReactTextareaAutosize>
                :
                <input
                    min={min}
                    max={max}
                    className={s.input}
                    defaultValue={defaultValue}
                    name={name}
                    type={fieldType}
                    onChange={onChange}
                    placeholder={placeholder}
                    autoComplete={autocomplete}
                />
            }
            {type === 'password' && <span style={passVisible ? {opacity: .5} : {}} onClick={passHandler} className={s.eye}><EyeIcon /></span>}
        </div>
    </>)
}