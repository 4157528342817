import { memo, SVGProps } from 'react';

const Frame29Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 61 61' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M47.2286 24.857C47.0089 24.6637 46.7371 24.5406 46.4479 24.5033C46.1586 24.4661 45.8649 24.5164 45.6041 24.6478L38.0264 28.458L31.7635 17.9601C31.6321 17.7401 31.4466 17.5581 31.2249 17.4318C31.0032 17.3055 30.7529 17.2391 30.4983 17.2391C30.2437 17.2391 29.9934 17.3055 29.7718 17.4318C29.5501 17.5581 29.3646 17.7401 29.2332 17.9601L22.9703 28.458L15.3955 24.6493C15.1347 24.5179 14.8412 24.4675 14.552 24.5042C14.2628 24.541 13.9908 24.6633 13.7705 24.8557C13.5501 25.0482 13.3912 25.3021 13.3138 25.5855C13.2364 25.869 13.244 26.1692 13.3356 26.4483L18.741 43.1506C18.7817 43.2767 18.85 43.3919 18.9408 43.4877C19.0316 43.5835 19.1427 43.6575 19.2657 43.7042C19.3887 43.7509 19.5206 43.7691 19.6516 43.7574C19.7825 43.7458 19.9092 43.7045 20.0222 43.6368C20.0324 43.6368 20.9805 43.071 22.7395 42.52C24.3625 42.0102 27.0228 41.4032 30.4983 41.4032C33.9738 41.4032 36.6341 42.0102 38.2587 42.52C40.0117 43.071 40.9657 43.6309 40.973 43.6354C41.086 43.7034 41.2127 43.7449 41.3437 43.7568C41.4748 43.7687 41.6068 43.7507 41.73 43.7042C41.8532 43.6576 41.9644 43.5837 42.0554 43.4878C42.1464 43.392 42.2149 43.2768 42.2557 43.1506L47.661 26.4512C47.7553 26.1723 47.7644 25.8714 47.6873 25.5872C47.6103 25.303 47.4505 25.0487 47.2286 24.857ZM40.9044 41.6286C39.2974 40.9037 35.7327 39.6351 30.4983 39.6351C25.2639 39.6351 21.6993 40.9037 20.0923 41.6286L15.2056 26.5293L22.4151 30.1538C22.748 30.3193 23.1302 30.3531 23.4865 30.2487C23.8428 30.1442 24.1475 29.9091 24.3406 29.5895L30.4983 19.2758L36.656 29.5969C36.8491 29.9161 37.1535 30.151 37.5095 30.2554C37.8655 30.3598 38.2474 30.3262 38.5801 30.1612L45.7911 26.5352L40.9044 41.6286Z'
      fill='#DDBE90'
    />
    <rect width={61} height={61} rx={30.5} stroke='#DDBE90' />
  </svg>
);
const Memo = memo(Frame29Icon);
export { Memo as Frame29Icon };
