import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

type InitialStateType = {
  string1: string;
  string2: string;
  string3: string;
  timer: number
};

const initialState: InitialStateType = {
  string1: "",
  string2: "",
  string3: "",
  timer: 0
};

export const testsSlice = createSlice({
  name: "test",
  initialState,
  reducers: {
    setStr1: (state, action: PayloadAction<string>) => {
      state.string1 = action.payload;
    },
    sepStr2: (state, action: PayloadAction<string>) => {
      state.string2 = action.payload;
    },
    setStr3: (state, action: PayloadAction<string>) => {
      state.string3 = action.payload;
    }
  },
});

export const { setStr1, sepStr2, setStr3 } = testsSlice.actions;

export const TestSlice = (state: RootState) => state.test;

export const TestReducer = testsSlice.reducer;
