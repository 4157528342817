import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { MeSlice } from "../../../store/slice/meSlice"
import { useEffect, useState } from "react"
import { Overlay } from "../Overlay/Overlay"
import { EmailReminder } from "../EmailReminder/EmailReminder"
import { StaticSlice, setReminder, setSupport } from "../../../store/slice/staticSlice"
import { SupportForm } from "../SupportForm/SupportForm"

export const Scripts = () => {
    const { success, data } = useAppSelector(MeSlice)
    const { isSupport, reminder } = useAppSelector(StaticSlice)
    const dispatch = useAppDispatch()

    useEffect(() => {
        (success && data.level === 100) && dispatch(setReminder(true))
    }, [data])

    useEffect(()=>{
        if (isSupport){
            switchReminder(false)
        }
    },[isSupport])

    const switchReminder = (state:boolean) => {
        dispatch(setReminder(state))
    }

    const switchSupport = (state:boolean) => {
        dispatch(setSupport(state))
    }
    return (<>
        {reminder && <Overlay
            status={reminder}
            setStatus={switchReminder}
            isClose={false}
        >
            <EmailReminder onClose={switchReminder} />
        </Overlay>
        }
        {isSupport && <Overlay
            status={isSupport}
            setStatus={switchSupport}
            title={'Связаться с поддержкой'}
            isClose
        >
            <SupportForm onClose={switchSupport} />
        </Overlay>
        }
    </>)
}