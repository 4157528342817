import { FC } from "react"

interface IIcon {
    fill?: string;
    stroke?: string;
    size?: number;
}

export const InfoIcon: FC<IIcon> = ({ fill = "#DDBE90", size = 18 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.25 9C0.25 4.16738 4.16738 0.25 9 0.25C13.8326 0.25 17.75 4.16738 17.75 9C17.75 13.8326 13.8326 17.75 9 17.75C4.16738 17.75 0.25 13.8326 0.25 9ZM4.05025 13.9497C2.7375 12.637 2 10.8565 2 9C2 7.14348 2.7375 5.36301 4.05025 4.05025C5.36301 2.7375 7.14348 2 9 2C10.8565 2 12.637 2.7375 13.9497 4.05025C15.2625 5.36301 16 7.14348 16 9C16 10.8565 15.2625 12.637 13.9497 13.9497C12.637 15.2625 10.8565 16 9 16C7.14348 16 5.36301 15.2625 4.05025 13.9497ZM8.38128 7.48878C8.21719 7.65288 8.125 7.87544 8.125 8.1075V13.3575C8.125 13.5896 8.21719 13.8121 8.38128 13.9762C8.54538 14.1403 8.76794 14.2325 9 14.2325C9.23206 14.2325 9.45462 14.1403 9.61872 13.9762C9.78281 13.8121 9.875 13.5896 9.875 13.3575V8.1075C9.875 7.87544 9.78281 7.65288 9.61872 7.48878C9.45462 7.32469 9.23206 7.2325 9 7.2325C8.76794 7.2325 8.54538 7.32469 8.38128 7.48878ZM8.38128 4.05091C8.54538 3.88681 8.76794 3.79463 9 3.79463C9.23206 3.79463 9.45462 3.88681 9.61872 4.05091C9.78281 4.215 9.875 4.43756 9.875 4.66963C9.875 4.90169 9.78281 5.12425 9.61872 5.28834C9.45462 5.45244 9.23206 5.54462 9 5.54462C8.76794 5.54462 8.54538 5.45244 8.38128 5.28834C8.21719 5.12425 8.125 4.90169 8.125 4.66963C8.125 4.43756 8.21719 4.215 8.38128 4.05091Z" fill={fill} />
        </svg>

    )
}