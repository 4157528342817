
import React, { FC, useEffect, useState } from "react"
import s from './auth.module.scss'
import { StatusMenu } from "../common/StatusMenu/StatusMenu"
import { CustomButtom } from "../common/CustomButton/CustomButton"
import { CustomInput } from "../common/CustomInput/CustomInput"
import { AtIcon } from "../../icons/at-icon"
import { LockIcon } from "../../icons/lock-icon"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { createUser } from "../../store/slice/registrationSlice"
import { MeSlice, getMe } from "../../store/slice/meSlice"
import { RegisterCategories } from "./RegisterCategories"
import { RegistrationProfile } from "./RegistrationProfile"
import { updateUser } from "../../store/slice/userUpdateSlice"
import { RegistrationSuccess } from "./RegistrationSuccess"
import { useNavigate } from "react-router-dom"
import { freeSubscription } from "../../store/slice/setFreeSubscription"
import { message } from "antd"
import { customRoute } from "../../utilities"
import { GoogleIcon, GoogleSmallIcon } from "../../icons/google-icon"
import { YandexIcon, YandexSmallIcon } from "../../icons/yandex.icon"
import { ENDPOINTS, REST_API } from "../../const/constants"

interface ILogin {
    changeMode: (state: boolean) => void
}

export const Registration: FC<ILogin> = ({ changeMode }) => {
    const [status, setStatus] = useState<string>("Почта")
    const [step, setStep] = useState(1)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [userName, setUserName] = useState("")
    const items = ["Почта", "Пароль", "Для кого", "Профиль", "done"]
    const dispatch = useAppDispatch()
    const router = useNavigate()
    const { loading: meLoading, success: meSuccess, data: meData } = useAppSelector(MeSlice)
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const [error, setError] = useState('')
    const [pError, setPerror] = useState('')


    const nextClick = () => {
        setError('')
        setPerror('')
        if (step === 1) {
            setStep(step + 1)
        }
        if (step === 2) {
            dispatch(createUser({
                email: email,
                password: password
            })).then((resp: any) => {
                if (resp.error) {
                    if (!!resp.payload.data.email) {
                        setError('Этот email уже зарегистрирован на сервисе')
                    }
                    if (!!resp.payload.data.password) {
                        setError(resp.payload.data.password)
                    }
                    setStep(1)
                }
                if (!resp.error) {
                    setStep(step + 1)
                    dispatch(getMe({ token: resp.payload.tokens.access }))
                }
            })
        }
        if (step === 3) {
            setStep(step + 1)
        }
        if (step === 4) {
            dispatch(updateUser({
                token: localStorage.getItem("access"),
                id: meData.id,
                data: {
                    first_name: !!userName ? userName : undefined
                }
            })).then((resp: any) => {
                if (!resp.error) {
                    // setStep(step + 1)
                    router('/profile')
                }
                if (resp.error) {
                    message.error('Ошибка. Что-то пошло не так')
                }
            })
        }

    }

    const activateProfile = () => {
        dispatch(freeSubscription({ token: localStorage.getItem("access") })).then((resp: any) => {
            if (!resp.error) {
                router('/profile')
                // customRoute('/profile')
            }
            if (resp.error) {
                if (resp.payload.data.detail === "Вы уже активировали бесплатную подписку") {
                    // customRoute('/profile')
                } else (
                    message.error('Ошибка. Что-то пошло не так')
                )
            }
        })
    }

    useEffect(() => {
        dispatch(getMe({ token: localStorage.getItem("access") }))
    }, [])

    useEffect(() => {
        meSuccess && setStep(3)
    }, [meSuccess])

    useEffect(() => {
        setStatus(items[step - 1])
    }, [step])


    return (<>
        <div className={s.registerWrapper}>
            {step !== 5 && <>
                <h2 className={s.h2} style={{ marginBottom: '46px' }}>Регистрация</h2>
                <div className={s.statusMenu}>
                    <StatusMenu
                        items={items}
                        current={status}
                    />
                </div>
            </>
            }
            {step === 1 &&
                <div className={s.registrationSteps}>
                    <div className={s.stepBody}>
                        <CustomInput
                            name="email"
                            icon={<AtIcon />}
                            type="email"
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                            placeholder='Электронная почта'
                        />
                        {!!error && <div className="form-error" style={{ marginBottom: '16px' }}>{error}</div>}
                        <CustomButtom
                            style={{ marginBottom: "16px" }}
                            onClick={() => {
                                window.location.href = REST_API + ENDPOINTS.auth.oauth + '?provider=google'
                            }}
                            type='secondary'
                        ><GoogleSmallIcon /> Использовать почту Google
                        </CustomButtom>
                        <CustomButtom
                            onClick={() => {
                                window.location.href = REST_API + ENDPOINTS.auth.oauth + '?provider=yandex'
                            }}
                            type='secondary'
                        ><YandexSmallIcon /> Использовать почту Яндекс
                        </CustomButtom>
                    </div>
                </div>
            }
            {step === 2 &&
                <div className={s.registrationSteps}>
                    <div className={s.stepBody}>
                        <CustomInput
                            name="password"
                            icon={<LockIcon />}
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder='Введите пароль'
                        />
                        {!!pError && <div className="form-error" style={{ marginBottom: '16px' }}>{pError}</div>}
                    </div>
                </div>}
            {step === 3 && <div className={s.registrationSteps}>
                <div className={s.stepBody}>
                    <RegisterCategories id={meData.id} />
                </div>

            </div>}
            {step === 4 && <div className={s.registrationSteps}>
                <div className={s.stepBody}>
                    <RegistrationProfile id={meData.id} onChange={e => {
                        setUserName(e.target.value)
                    }} />
                </div>
            </div>}
            {step === 5 &&
                <div className={s.registrationStep5}>
                    <div className={s.stepBody}>
                        <RegistrationSuccess />
                    </div>
                </div>}
            <div className={s.stepsFooter}>
                {step === 3 && <div className={s.profilePromoBlock}>
                    <div className={s.profilePromoCat}>🥳</div>
                    <div className={s.profilePromoText}>Вам доступен +1 бесплатный профиль, сможете добавить его в личном кабинете</div>
                </div>}

                {step !== 5 && step !== 3 &&
                    <div className={s.stepButton}>
                        {step > 1 && <CustomButtom
                            onClick={() => {
                                setStep(step - 1)
                            }}
                            type='secondary'
                        >Назад
                        </CustomButtom>
                        }
                        {step === 1 && <CustomButtom
                            onClick={() => { changeMode(true) }}
                            type='secondary'
                        >Назад
                        </CustomButtom>
                        }
                        <CustomButtom
                            disabled={(step === 1 && !email) || (step === 1 && !emailRegex.test(email))}
                            onClick={()=>{
                                if (step === 2){
                                    /^.{8,}$/.test(password) ? nextClick() : setPerror("Пароль должен содержать минимум 8 символов")
                                } else {
                                    nextClick()
                                }
                            }}
                            type='primary'
                        >Дальше
                        </CustomButtom>
                    </div>
                }
                {step === 3 &&
                    <div className={s.stepButton}>
                       <CustomButtom
                            onClick={() => {
                                setStep(step + 1)
                            }}
                            type='secondary'
                        >Пропустить
                        </CustomButtom>
                        <CustomButtom
                            onClick={nextClick}
                            type='primary'
                        >Дальше
                        </CustomButtom>
                    </div>
                }
                {step === 5 &&
                    <div className={s.stepButton}>
                        <CustomButtom
                            onClick={activateProfile}
                            type='primary'
                        > Активировать триал на 1 месяц
                        </CustomButtom>
                    </div>
                }
            </div>
        </div>
    </>)
}