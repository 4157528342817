
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import axios from "axios";
import { ENDPOINTS, REST_API } from "../../const/constants";

export interface IAuth {
    refresh: string;
    access: string;
}

interface ICreateUser {
    id: string;
    email: string;
    tokens: IAuth;
}

interface CreateUserInitialStateType {
    loading: boolean,
    data: ICreateUser
    success: boolean,
    error: boolean,
    errors: any,
}

const initialState: CreateUserInitialStateType = {
  loading: false,
  data: {} as ICreateUser,
  success: false,
  error: false,
  errors: {},
};

interface ICreateUserReq {
    email: string;
    password: string;
}

export const createUser = createAsyncThunk(
  "createUser",
  async (req: ICreateUserReq, { rejectWithValue }) => {
    const url = REST_API + ENDPOINTS.user.user;
    try {
      const response = await axios.post(url, { ...req }, {});
      return response.data;
    } catch (err) {
      const error: any = err;
      return rejectWithValue(error.response);
    }
  }
);

export const createUserSlice = createSlice({
  name: "createUser",
  initialState,
  reducers: {
    createUserAction: () => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUser.pending, (state:any) => {
        state.loading = true;
        state.success = false;
        state.errors.email = "";
      })
      .addCase(createUser.fulfilled, (state:any, { payload }:any) => {
        state.loading = false;
        state.data = payload
        state.error = false;
        state.success = true;
      })
      .addCase(createUser.rejected, (state:any, { payload }:any) => {
        const payl: any = payload;
        state.loading = false;
        state.success = false;
        state.error = true;
      });
  },
});

export const { createUserAction } = createUserSlice.actions;

export const CreateUser = (state: RootState) => state.createUser;

export const CreateUserReducer = createUserSlice.reducer;
