
import { useFormik } from 'formik'
import s from './award.module.scss'
import classnames from 'classnames'
import { FC, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { GetReward, getReward } from '../../store/slice/getRewardSlice'
import { MnInput } from '../common/MnInput/MnInput'
import { Image, Select, Upload, UploadProps, message } from 'antd'
import { DATES, ENDPOINTS, MONTHES, REST_API, YEARS } from '../../const/constants'
import { FotikIcon } from '../../icons/fotik-icon'
import { KorzinaIcon } from '../../icons/korzina-icon'
import axios from 'axios'
import { GetSports, ISport, getSports } from '../../store/slice/getSportsSlice'
import { GetDistances, getDistances } from '../../store/slice/getDistancesSlice'
import { GetCountries, getCountries } from '../../store/slice/getCountriesSlice'
import { CustomButtom } from '../common/CustomButton/CustomButton'
import { deleteReward, updateReward } from '../../store/slice/updateRewardSlice'
import { useWindowSize } from '../../hooks/useWindowSize'
import { CustomSelect } from '../common/CustomSelect/CustomSelect'
import * as Yup from "yup";
import { Success } from '../common/Success/Success'
import { Error } from '../common/Error/Error'
import { customRoute } from '../../utilities'
import { getRewards } from '../../store/slice/getRewardsSlice'

const EditSchema = Yup.object().shape({
    // city: Yup.string().required('Поле "Город" обязательно'),
    country: Yup.string().required('Поле "Страна" обязательно '),
});

const cn = classnames.bind(this)

interface IAwardEdit {
    id: string | undefined
    onClose: (status: boolean) => void
}

type OptionType = { label: string, value: string | number, id: number };

export const AwardEdit: FC<IAwardEdit> = ({ id, onClose }) => {
    const { data } = useAppSelector(GetReward)
    const dispatch = useAppDispatch()
    const [options, setOptions] = useState<OptionType[]>([]);
    const [distances, setDistances] = useState<OptionType[]>([]);
    const { success: sSuccess, data: sData } = useAppSelector(GetSports)
    const { loading: dLoading, data: dData, success: dSuccess } = useAppSelector(GetDistances)
    const { data: cData } = useAppSelector(GetCountries)
    const size = useWindowSize()
    const [isRemove, setIsRemove] = useState(false)
    const [isRemoveSuccess, setIsRemoveSuccess] = useState(false)
    const [isRemoveError, setIsRemoveError] = useState(false)


    const deleteHandler = () => {
        dispatch(deleteReward({ token: localStorage.getItem('access'), id: id })).then((resp: any) => {
            if (resp.error) {
                setIsRemoveError(true)
                setIsRemoveSuccess(false)
            }
            if (!resp.error) {
                setIsRemoveError(false)
                setIsRemoveSuccess(true)
                dispatch(getRewards({}))
                customRoute('/awards')
            }
        })
    }

    useEffect(() => {
        setDistances([])
        dispatch(getSports({ token: localStorage.getItem("access") }))
        dispatch(getCountries({}))
    }, [])

    useEffect(() => {
        !!data.sport && dispatch(getDistances({ sport: +data.sport.id }))
    }, [data.sport])

    useEffect(() => {
        if (sSuccess) {
            formik.setFieldValue('distance', null);
            const newOptions = sData.map((x: ISport, i: number) => {
                return ({
                    value: x.title,
                    label: x.title,
                    id: x.id
                })
            }
            );
            setOptions((state: OptionType[]) => [...state, ...newOptions]);
        }
    }, [sData, sSuccess])

    useEffect(() => {
        setDistances([])
        if (dSuccess) {
            const newDistances = dData.map((x: ISport, i: number) => {
                return ({
                    value: x.title,
                    label: x.title,
                    id: x.id
                })
            }
            );
            setDistances((state: OptionType[]) => [...state, ...newDistances]);
        }
    }, [dData, dSuccess])

    const formik = useFormik({
        initialValues: {
            title: data.title,
            startDay: data.start_day,
            startMonth: data.start_month,
            startYear: data.start_year,
            sport: !!data.sport?.id ?data.sport?.id : undefined,
            distance: !!data.distance?.id ? data.distance?.id : undefined,
            disciplines: data.disciplines,
            age: data.age ?? undefined,
            gender: data.gender,
            result: data.result,
            significance: data.significance,
            protocol: data.protocol,
            country: !!data?.country?.id ? data?.country?.id : null,
            city: !!data?.city?.name ? data?.city?.name : undefined,
            team: data.team,
            organizer: data.organizer,
            institution: data.institution,
            teacher: data.teacher,
            kind_activity: data.kind_activity,
            note: data.note,
            additional_criteria: data.additional_criteria
        },

        onSubmit: values => {
            dispatch(updateReward({
                token: localStorage.getItem('access'),
                id: id,
                data: {
                    title: !!values.title ?values.title: undefined,
                    start_day: !!values.startDay ? values.startDay: undefined,
                    start_month: !!values.startMonth ?values.startMonth : undefined,
                    start_year: !!values.startYear ?values.startYear : undefined,
                    year: !!values.startYear ?values.startYear: undefined ,
                    sport: !!values.sport ?values.sport : undefined,
                    distance: !!values.distance ? values.distance: undefined,
                    disciplines: !!values.disciplines ? values.disciplines: undefined,
                    age: !!values.age ? values.age : undefined,
                    gender: !!values.gender ?values.gender : undefined,
                    result: !!values.result ?values.result : undefined,
                    significance: !!values.significance ? values.significance : undefined,
                    protocol: !!values.protocol ?values.protocol: undefined,
                    country: !!values.country ? values.country: undefined,
                    city_name: !!values.city ? values.city: undefined,
                    team: !!values.team ?values.team : undefined,
                    organizer: !!values.organizer ?values.organizer : undefined,
                    institution: !!values.institution ? values.institution: undefined,
                    teacher: !!values.teacher ? values.teacher: undefined,
                    kind_activity: !!values.kind_activity ?values.kind_activity : undefined,
                    note: !!values.note ? values.note: undefined,
                    additional_criteria: !!values.additional_criteria ? values.additional_criteria: undefined
                }
            })).then((resp: any) => {
                if (!resp.error) {
                    dispatch(getReward({ id: id }))
                    onClose(false)
                }
            })
        },
        validationSchema: EditSchema,
        validateOnChange: false,
        validateOnBlur: false,
    });

    const deletePhoto = (phid: number) => {
        let data = new FormData();

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: REST_API + ENDPOINTS.rewards.rewards + id + '/photos/' + phid + '/delete/',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log(response)
                dispatch(getReward({ id: id }))
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const uploadProps: UploadProps = {
        name: 'image',
        action: REST_API + ENDPOINTS.rewards.rewards + id + '/photos/',
        onRemove: (image) => {
            let data = new FormData();

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: REST_API + ENDPOINTS.rewards.rewards + id + '/photos/' + image.response.id + '/delete/',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        headers: {
            authorization: `Bearer ${typeof window !== 'undefined' && localStorage.getItem('access')}`,
        },
        onChange(info) {
            if (info.file.status === 'done') {
                message.success(`Файл ${info.file.name} успешно загружен`);
                dispatch(getReward({ id: id }))
            } else if (info.file.status === 'error') {
                message.error(`Произошла ошибка при загрузке файла ${info.file.name}`);
            }
        },
    };

    return (
        <div className={s.modalEditWrapper}>
            {!isRemove && <form className={s.profileUserInfo} onSubmit={formik.handleSubmit}>
                <MnInput
                    key="title"
                    name="title"
                    type="text"
                    defaultValue={formik.values.title}
                    onChange={formik.handleChange}
                    placeholder='Название награды'
                />
                <div className={s.subtitle}>
                    Фотография награды
                </div>

                <div className={s.editPhotoBlock}>
                    <div className={s.editPhotoBlockList}>
                        {data?.photos?.map((ph, i) => {
                            return (
                                <div className={s.editPhotoBlockImgContainer} key={i}>
                                    <img className={s.editPhotoBlockImg} src={ph.image} width={110} height={100} alt="" />
                                    <div onClick={() => { deletePhoto(ph.id) }} className={s.editPhotoBlockImgRemove}><KorzinaIcon /></div>
                                </div>
                            )
                        })}
                    </div>
                    <Upload className='noprogreess' {...uploadProps}>
                        <div className={s.addPhotoEdit} >
                            <FotikIcon />
                        </div>
                    </Upload>
                </div>
                <div className={s.subtitle}>
                    Выберите дату получения награды:
                </div>
                <div className={cn(s.stepBody, s.stepLine)}>
                    {/* {size[0] > 996 ?  */}
                    <CustomSelect
                        className={s.sportSelector}
                        popupClassName={s.sportPopupSelector}
                        // defaultValue={formik.values.startDay.toString().length === 1 ?
                        //     { label: '0' + formik.values.startDay, value: formik.values.startDay } :
                        //     { label: formik.values.startDay, value: formik.values.startDay }
                        // }
                        defaultMobValue={formik.values.startDay}
                        defaultValue={formik.values.startDay}
                        bordered={false}
                        options={DATES}
                        placeholder="День"
                        onSelect={(value: any, option: any) => {
                            formik.setFieldValue('startDay', +option.value);
                        }}
                    />
                    {/* // :
                    //     <select className={s.sportSelectorMobile} onChange={e => console.log(e.target.value)}>
                    //         {DATES.map((date, i) => {
                    //             return (
                    //                 <option key={i} value={date.value}>{date.label}</option>
                    //             )
                    //         })}
                    //     </select>} */}
                    <CustomSelect
                        className={s.sportSelector}
                        popupClassName={s.sportPopupSelector}
                        defaultValue={formik.values.startMonth}
                        defaultMobValue={formik.values.startMonth}
                        bordered={false}
                        options={MONTHES}
                        placeholder="Месяц"
                        onSelect={(value: any, option: any) => {
                            formik.setFieldValue('startMonth', +option.value);
                        }}
                    />
                    <CustomSelect
                        className={s.sportSelector}
                        popupClassName={s.sportPopupSelector}
                        defaultValue={formik.values.startYear}
                        defaultMobValue={formik.values.startYear}
                        bordered={false}
                        options={YEARS()}
                        placeholder="Год"
                        onSelect={(value: any, option: any) => {
                            formik.setFieldValue('startYear', +option.value);
                        }}
                    />
                </div>
                {data.category === 'sport' && <><CustomSelect
                    isSearch
                    className={s.sportSelector}
                    popupClassName={s.sportPopupSelector}
                    defaultValue={{ label: data?.sport?.title, value: data?.sport?.title, id: data?.sport?.id }}
                    bordered={false}
                    options={options}
                    placeholder="Вид спорта"
                    onSelect={(value: any, option: any) => {
                        formik.setFieldValue('sport', option.id);
                        dispatch(getDistances({ sport: option.id }))
                    }}
                />
                    {/* <CustomSelect
                        isSearch
                        className={s.sportSelector}
                        popupClassName={s.sportPopupSelector}
                        defaultValue={{ label: data?.distance?.title, value: data?.distance?.title, id: data?.distance?.id }}
                        bordered={false}
                        options={distances}
                        loading={dLoading}
                        disabled={distances.length === 0}
                        placeholder="Дисциплина"
                        onSelect={(value: any, option: any) => {
                            console.log(option)
                            formik.setFieldValue('distance', option.id);
                        }}
                    /> */}
                    <MnInput
                        name="disciplines"
                        type="text"
                        defaultValue={formik.values.disciplines}
                        onChange={(e) => {
                            formik.setFieldValue('disciplines', e.target.value)
                        }}
                        placeholder='Дисциплина'
                        style={{ width: "100%", marginBottom: '0' }}
                    />
                </>}
                {data.category === 'non_sport' &&
                    <MnInput
                        name="kind"
                        type="text"
                        defaultValue={formik.values.kind_activity}
                        onChange={(e) => {
                            formik.setFieldValue('kind_activity', e.target.value)
                        }}
                        placeholder='Направление'
                        style={{ width: "100%", marginBottom: '0' }}
                    />
                }
                {/* <div className={cn(s.stepBody, s.stepLine)}>
                    <Select
                        className={s.sportSelector}
                        placeholder="Пол"
                        defaultValue={formik.values.gender !== '' ? formik.values.gender : null}
                        onChange={(e) => {
                            formik.setFieldValue('gender', e)
                        }}
                        options={[
                            { value: 'male', label: 'Муж' },
                            { value: 'female', label: 'Жен' },
                        ]}
                    />
                    <MnInput
                        name="age"
                        type="text"
                        defaultValue={formik.values.age}
                        onChange={(e) => {
                            formik.setFieldValue('age', e.target.value)
                        }}
                        placeholder='Возраст'
                        style={{ width: "100%", marginBottom: '0' }}
                    />
                </div> */}
                <MnInput
                    key="additional_criteria"
                    name="additional_criteria"
                    type="text"
                    defaultValue={formik.values.additional_criteria}
                    onChange={formik.handleChange}
                    placeholder='Доп. критерии дисциплины'
                />
                <MnInput
                    key="result"
                    name="result"
                    type="textarea"
                    defaultValue={formik.values.result}
                    onChange={formik.handleChange}
                    placeholder='Результат соревнования'
                />
                <CustomSelect
                    className={s.sportSelector}
                    placeholder="Значимость"
                    defaultValue={formik.values.significance}
                    defaultMobValue={formik.values.significance}
                    // onChange={(e:any) => {
                    //     formik.setFieldValue('significance', e)
                    // }}
                    onSelect={(value: any, option: any) => {
                        console.log(option)
                        formik.setFieldValue('significance', option.value);
                    }}
                    options={[
                        { value: 'high', label: 'Высокая' },
                        { value: 'medium', label: 'Средняя' },
                        { value: 'low', label: 'Низкая' },
                    ]}
                />
                <MnInput
                    key="protocol"
                    name="protocol"
                    type="text"
                    defaultValue={formik.values.protocol}
                    onChange={formik.handleChange}
                    placeholder='Ссылка на результат'
                />
                <CustomSelect
                    isSearch
                    className={s.sportSelector}
                    placeholder="Страна"
                    defaultValue={{ label: data?.country?.name, value: data?.country?.name, id: data?.country?.id }}
                    onSelect={(value: any, option: any) => {
                        formik.setFieldValue('country', option.id)
                    }}
                    options={cData.map(c => ({ label: c.name, value: c.name, id: c.id }))}
                />
                {!!formik.errors.country && !!formik.touched.country ? (
                    <div className="form-error" style={{ marginBottom: '16px' }}>{formik.errors.country}</div>
                ) : null}
                <MnInput
                    key="city"
                    name="city"
                    type="text"
                    defaultValue={formik?.values?.city}
                    onChange={formik.handleChange}
                    placeholder='Город'
                />
                {!!formik.errors.city && !!formik.touched.city ? (
                    <div className="form-error" style={{ marginBottom: '16px' }}>{formik.errors.city}</div>
                ) : null}
                <MnInput
                    key="team"
                    name="team"
                    type="text"
                    defaultValue={formik.values.team}
                    onChange={formik.handleChange}
                    placeholder='Команда'
                />
                <MnInput
                    key="organizer"
                    name="organizer"
                    type="text"
                    defaultValue={formik.values.organizer}
                    onChange={formik.handleChange}
                    placeholder='Кем выдана / организатор'
                />
                <MnInput
                    key="institution"
                    name="institution"
                    type="text"
                    defaultValue={formik.values.institution}
                    onChange={formik.handleChange}
                    placeholder='Школа / учреждение'
                />
                <MnInput
                    key="teacher"
                    name="teacher"
                    type="text"
                    defaultValue={formik.values.teacher}
                    onChange={formik.handleChange}
                    placeholder='Педагог / руководитель'
                />
                <MnInput
                    key="note"
                    name="note"
                    type="textarea"
                    defaultValue={formik.values.note}
                    onChange={formik.handleChange}
                    placeholder='Мои заметки'
                />
                <CustomButtom
                    onClick={() => {
                    }}
                    type='submit'
                >Сохранить
                </CustomButtom>
                <CustomButtom
                    onClick={() => {
                        setIsRemove(true)
                    }}
                    type='secondary'
                >Удалить награду
                </CustomButtom>
            </form>}
            {isRemove && !isRemoveError && !isRemoveSuccess && <div className={s.removeBlock}>
                <div className={s.remtitle}>Вы действительно хотите удалить награду?</div>
                <div className={s.removeButtons}>
                    <CustomButtom
                        onClick={() => {
                            setIsRemove(false)
                            onClose(false)
                        }}
                        type='secondary'
                    >Отмена
                    </CustomButtom>
                    <CustomButtom
                        onClick={() => {
                            setIsRemove(true)
                            deleteHandler()
                        }}
                        type='primary'
                    >Удалить
                    </CustomButtom>
                </div>
            </div>}
            {isRemove && !isRemoveError && isRemoveSuccess &&
                <Success heading='Награда удалена' />
            }
            {isRemove && isRemoveError && !isRemoveSuccess &&
                <Error heading='Ошибка' />
            }
        </div>
    )
}