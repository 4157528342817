import { FC } from "react"

interface IIcon {
    fill?: string;
    stroke?: string;
    size?: number
}

export const ArrowBackIcon: FC<IIcon> = ({ fill = "#818181" }) => {
    return (
        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.73232 0.264138C6.90372 0.433317 7 0.662742 7 0.901961C7 1.14118 6.90372 1.37061 6.73232 1.53978L2.2068 6.00545L6.73232 10.4711C6.89886 10.6413 6.99101 10.8691 6.98893 11.1057C6.98684 11.3422 6.89069 11.5685 6.72118 11.7358C6.55167 11.903 6.32237 11.9979 6.08266 12C5.84295 12.002 5.612 11.9111 5.43958 11.7468L0.267679 6.64327C0.0962842 6.47409 0 6.24467 0 6.00545C0 5.76623 0.0962842 5.5368 0.267679 5.36762L5.43958 0.264138C5.61102 0.0950107 5.84352 0 6.08595 0C6.32837 0 6.56087 0.0950107 6.73232 0.264138Z" fill={fill} />
        </svg>
    )
}