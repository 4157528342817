
import { memo, useEffect, useState } from 'react';
import type { FC } from 'react';
import resets from '../../styles/_resets.module.scss';
import classes from './success.module.scss';
import { Frame29Icon } from '../../icons/Frame29Icon';
import {Link} from 'react-router-dom';
import moment from 'moment';

interface Props {
  className?: string;
  hide?: {
    uIIconsClose?: boolean;
  };
}
/* @figmaId 56:21154 */
export const RegistrationSuccess: FC<Props> = memo(function UIDataRegistration5(props = {}) {
  const startDate = new Date()
  const [date, setDate] = useState(moment(startDate, "YYYY-MM-DD").add(28, 'days'))
  const [month, setMonth] = useState('')

  useEffect(() => {
    // console.log(moment(date).format("YYYY-MM-DD"))

    switch (moment(startDate).format("MM")) {
      case '01':
        setMonth('февраля')
        break;
      case '02':
        setMonth('марта')
        break;
      case '03':
        setMonth('апреля')
        break;
      case '04':
        setMonth('мая')
        break;
      case '05':
        setMonth('июня')
        break;
      case '06':
        setMonth('июля')
        break;
      case '07':
        setMonth('августа')
        break;
      case '08':
        setMonth('сентября')
        break;
      case '09':
        setMonth('октября')
        break;
      case '10':
        setMonth('ноября')
        break;
      case '11':
        setMonth('декабря')
        break;
      case '12':
        setMonth('января')
        break;
      default:
        setMonth('января')

    }
  }, [date])

  return (
    <div className={`${resets.storybrainResets} ${classes.root}`}>
      <div className={classes.frame42}>
        <div className={classes.sL_050721_42770_151}></div>
      </div>
      <div className={classes.frame14}>
        <div className={classes.title2}>
          <div className={classes.textBlock}>Регистрация </div>
          <div className={classes.textBlock2}>прошла успешно!</div>
        </div>
      </div>
      <div className={classes.frame29}>
        <Frame29Icon className={classes.icon} />
      </div>
      <div className={classes.rectangle8}></div>
      <div className={classes.title3}>
        <div className={classes.textBlock3}>Вам доступен наш </div>
        <p className={classes.labelWrapper}>
          <span className={classes.label}>пробный функцонал на </span>
          <span className={classes.label2}>1 месяц </span>
          <span className={classes.label3}>!</span>
        </p>
      </div>
      <div className={classes.frame43}>
        <div className={classes.title4}><span className={classes.successSubtitle}>1</span>Старт уже сегодня</div>
        <div className={classes.title5}>Вы потрогаете приложение и поймете какое оно классное</div>
      </div>
      <div className={classes.frame44}>
        <div className={classes.title6}><span className={classes.successSubtitle}>2</span>За 2 дня до окончания</div>
        <div className={classes.title7}>Вы получите уведомление о том, что триал заканчиватся</div>
      </div>
      <div className={classes.frame45}>
        <div className={classes.title8}><span className={classes.successSubtitle}>3</span>Последний день месяца</div>
        <div className={classes.title9}>
          <p className={classes.labelWrapper2}>
            <span className={classes.label4}>Ваша платная подписка начнется </span>
            <span className={classes.label5}>{moment(startDate).format("DD")}</span>
            <span className={classes.label6}> {month}. Вы можете отменить ее в любое время 🤝</span>
          </p>
        </div>
      </div>
      <div className={classes.frame41}>
        <div className={classes.frame38}>
          <div className={classes.frame37}>
            <div className={classes.title10}>1</div>
          </div>
          <div className={classes.frame40}>
            <div className={classes.title11}>2</div>
          </div>
          <div className={classes.frame412}>
            <div className={classes.title12}>3</div>
          </div>
        </div>
        <div className={classes.frame402}></div>
      </div>
      <div className={classes.frame46} style={{marginTop: '12px'}}>
        <div className={classes.frame48}>
          <div className={classes.title13}>Попробуй 28 дней бесплатно, после:</div>
          <div className={classes.frame47}>
            <div className={classes.title14}>200 RUB / месяц</div>
            <div className={classes.title15}>или</div>
            <div className={classes.title16}>990 RUB / год</div>
          </div>
        </div>
      </div>
      <div className={classes._24}>
        <div className={classes.textBlock4}>
          Автоматическое продление подписки может быть отменено в любое время, не позднее, чем за 24 часа до начала
          следующего периода подписки. Автоматическое продление подписки
        </div>
        <div className={classes.textBlock5}>
          <p className={classes.labelWrapper3}>
            <span className={classes.label7}>Согласно </span>
            <span className={classes.label8}><Link to="https://help.xn--80aaifqxigw6i.xn--p1ai/privacy#3">Условиям конфиденциальности</Link></span>
            <span className={classes.label9}> и </span>
            <span className={classes.label10}><Link to="https://help.xn--80aaifqxigw6i.xn--p1ai/privacy#1">Условиям использования</Link></span>
            <span className={classes.label11}> .</span>
          </p>
        </div>
      </div>
    </div>
  );
});
