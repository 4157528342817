
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import axios from "axios";
import { ENDPOINTS, REST_API } from "../../const/constants";

export interface IAuth {
    refresh: string;
    access: string;
}

interface INewPassword {
    id: string;
    email: string;
    tokens: IAuth;
}

interface NewPasswordInitialStateType {
    loading: boolean,
    data: INewPassword
    success: boolean,
    error: boolean,
    errors: any,
}

const initialState: NewPasswordInitialStateType = {
    loading: false,
    data: {} as INewPassword,
    success: false,
    error: false,
    errors: {},
};

interface INewPasswordReq {
    token: string | null;
    data: {
        old_password: string,
        password: string,
        password2: string,
    }
}

export const newPassword = createAsyncThunk(
    "newPassword",
    async (req: INewPasswordReq, { rejectWithValue }) => {
        const {token, data} = req
        const headers = {
            Authorization: `Bearer ${token}`,
          };
        const url = REST_API + ENDPOINTS.user.password
        try {
            const response = await axios.post(url, { ...data }, {headers: headers});
            return response.data;
        } catch (err) {
            const error: any = err;
            return rejectWithValue(error.response);
        }
    }
);

export const newPasswordSlice = createSlice({
    name: "newPassword",
    initialState,
    reducers: {
        newPasswordAction: () => { },
    },
    extraReducers: (builder) => {
        builder
            .addCase(newPassword.pending, (state: any) => {
                state.loading = true;
                state.success = false;
                state.errors.email = "";
            })
            .addCase(newPassword.fulfilled, (state: any, { payload }: any) => {
                state.loading = false;
                state.data = payload
                state.error = false;
                state.success = true;
            })
            .addCase(newPassword.rejected, (state: any, { payload }: any) => {
                const payl: any = payload;
                state.loading = false;
                state.success = false;
                state.error = true;
            });
    },
});

export const { newPasswordAction } = newPasswordSlice.actions;

export const NewPassword = (state: RootState) => state.newPassword;

export const NewPasswordReducer = newPasswordSlice.reducer;
