
import layout from '../../styles/_layout.module.scss'
import { Nav } from '../Nav/Nav'
import s from './albums.module.scss'
import { ArrowBackIcon } from '../../icons/arrowback-icon'

import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { MeSlice, getMe } from '../../store/slice/meSlice'
import { customRoute } from '../../utilities'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

interface IAddAlbums { }

export const AddAlbums = () => {
    const [addStatus, setAddStatus] = useState(false)
    const { success, data } = useAppSelector(MeSlice)
    const dispatch = useAppDispatch()
    const router = useNavigate()

    useEffect(() => {
        !success && dispatch(getMe({ token: localStorage.getItem("access") })).then((resp: any) => {
            if (resp.error) {
                router("/auth")
            }
        })
    }, [])

    const add = () => {
        setAddStatus(true)
    }

    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Добавление альбома | Мои награды</title>
            <meta name="description" content="Ваши награды и результаты соревнований"
            />
        </Helmet>
        <div className={layout.container}>
            <div className={layout.content}>
                <div className={s.header}>
                    <div className={layout.round} onClick={() => { customRoute('/albums') }}>
                        <ArrowBackIcon />
                    </div>
                    <div className={layout.pageName}>Награда</div>
                    <div className={layout.headerButtons}>

                    </div>
                </div>
                <div className={s.pageTitle}>Добавить фотоальбом</div>
                <div className={layout.awardList}>
                    <div onClick={() => add()} className={s.addAwardItem}>
                        <div style={{
                            width: '100%',
                            height: '106px',
                            background: 'url(/images/addAlbum.png) no-repeat center',
                            borderRadius: '4px',
                            backgroundSize: 'cover'
                        }} className={layout.awardItemImageContainer}></div>
                        <div className={s.addAwTitle}>Альбомы</div>
                        <div className={s.addAwDesc}>Добавьте все самые незабываемые моменты! 🥳</div>
                        <div className={s.addAwBtn}>Добавить <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.25 8.66536H8.91671V11.9987C8.91671 12.1755 8.84647 12.3451 8.72145 12.4701C8.59642 12.5951 8.42685 12.6654 8.25004 12.6654C8.07323 12.6654 7.90366 12.5951 7.77864 12.4701C7.65361 12.3451 7.58337 12.1755 7.58337 11.9987V8.66536H4.25004C4.07323 8.66536 3.90366 8.59513 3.77864 8.4701C3.65361 8.34508 3.58337 8.17551 3.58337 7.9987C3.58337 7.82189 3.65361 7.65232 3.77864 7.52729C3.90366 7.40227 4.07323 7.33203 4.25004 7.33203H7.58337V3.9987C7.58337 3.82189 7.65361 3.65232 7.77864 3.52729C7.90366 3.40227 8.07323 3.33203 8.25004 3.33203C8.42685 3.33203 8.59642 3.40227 8.72145 3.52729C8.84647 3.65232 8.91671 3.82189 8.91671 3.9987V7.33203H12.25C12.4269 7.33203 12.5964 7.40227 12.7214 7.52729C12.8465 7.65232 12.9167 7.82189 12.9167 7.9987C12.9167 8.17551 12.8465 8.34508 12.7214 8.4701C12.5964 8.59513 12.4269 8.66536 12.25 8.66536Z" fill="#DDBE90" />
                        </svg>
                        </div>

                    </div>
                </div>
                <div className={layout.footer}>
                    <Nav active='album' />
                </div>
            </div>
        </div >
    </>)
}