import { FC } from "react"

interface IIcon {
    fill?: string;
    stroke?: string;
    size?: number
}

export const BumazhkaIcon: FC<IIcon> = ({ stroke = "#DDBE90", size=18 }) => {
    const viewBox = `0 0 ${size} ${size}`
    return (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.27947 5.77947C8.77856 5.28039 9.45547 5 10.1613 5H17.9052C18.167 5.00004 18.4253 5.06079 18.6597 5.17749C18.8941 5.29419 19.0983 5.46366 19.2561 5.67258L22.1574 9.51258C22.38 9.80677 22.5 10.1648 22.5 10.5335V21.2097C22.5 21.9155 22.2196 22.5924 21.7205 23.0915C21.2214 23.5906 20.5445 23.871 19.8387 23.871H10.1613C9.45547 23.871 8.77856 23.5906 8.27947 23.0915C7.78038 22.5924 7.5 21.9155 7.5 21.2097V7.66129C7.5 6.95547 7.78038 6.27856 8.27947 5.77947ZM10.1613 6.45161C9.49355 6.45161 8.95161 6.99355 8.95161 7.66129V21.2097C8.95161 21.8774 9.49355 22.4194 10.1613 22.4194H19.8387C20.5065 22.4194 21.0484 21.8774 21.0484 21.2097V11.4326H17.9032C17.5026 11.4326 17.1774 11.1074 17.1774 10.7068V6.45161H10.1613ZM18.4164 14.89C18.5526 15.0261 18.629 15.2107 18.629 15.4032C18.629 15.5957 18.5526 15.7803 18.4164 15.9164C18.2803 16.0526 18.0957 16.129 17.9032 16.129H12.0968C11.9043 16.129 11.7197 16.0526 11.5836 15.9164C11.4474 15.7803 11.371 15.5957 11.371 15.4032C11.371 15.2107 11.4474 15.0261 11.5836 14.89C11.7197 14.7539 11.9043 14.6774 12.0968 14.6774H17.9032C18.0957 14.6774 18.2803 14.7539 18.4164 14.89ZM18.4164 18.761C18.5526 18.8971 18.629 19.0817 18.629 19.2742C18.629 19.4667 18.5526 19.6513 18.4164 19.7874C18.2803 19.9235 18.0957 20 17.9032 20H12.0968C11.9043 20 11.7197 19.9235 11.5836 19.7874C11.4474 19.6513 11.371 19.4667 11.371 19.2742C11.371 19.0817 11.4474 18.8971 11.5836 18.761C11.7197 18.6249 11.9043 18.5484 12.0968 18.5484H17.9032C18.0957 18.5484 18.2803 18.6249 18.4164 18.761Z" fill="#DDBE90"/>
    </svg>
    
    )
}