
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import axios from "axios";
import { ENDPOINTS, REST_API } from "../../const/constants";


interface IResetSetNewPassword {
    password: string,
    token: string
}

interface ResetSetNewPasswordInitialStateType {
    loading: boolean,
    data: IResetSetNewPassword
    success: boolean,
    error: boolean,
    errors: any,
}

const initialState: ResetSetNewPasswordInitialStateType = {
  loading: false,
  data: {} as IResetSetNewPassword,
  success: false,
  error: false,
  errors: {},
};

interface IResetSetNewPasswordReq {
    password: string,
    token: string
}

export const resetSetNewPassword = createAsyncThunk(
  "resetSetNewPassword",
  async (req: IResetSetNewPasswordReq, { rejectWithValue }) => {
    const url = REST_API + ENDPOINTS.password_reset.confirm
    try {
      const response = await axios.post(url, { ...req }, {});
      return response.data;
    } catch (err) {
      const error: any = err;
      return rejectWithValue(error.response);
    }
  }
);

export const resetSetNewPasswordSlice = createSlice({
  name: "resetSetNewPassword",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetSetNewPassword.pending, (state:any) => {
        state.loading = true;
        state.success = false;
      })
      .addCase(resetSetNewPassword.fulfilled, (state:any, { payload }:any) => {
        state.loading = false;
        state.data = payload
        state.error = false;
        state.success = true;
      })
      .addCase(resetSetNewPassword.rejected, (state:any, { payload }:any) => {
        const payl: any = payload;
        state.loading = false;
        state.success = false;
        state.error = true;
      });
  },
});

export const ResetSetNewPassword = (state: RootState) => state.resetSetNewPassword;

export const ResetSetNewPasswordReducer = resetSetNewPasswordSlice.reducer;
