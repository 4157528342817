
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import axios from "axios";
import { ENDPOINTS, REST_API } from "../../const/constants";

export interface IAuth {
    refresh: string;
    access: string;
}

interface IFreeSubscription {
    id: string;
    email: string;
    tokens: IAuth;
}

interface FreeSubscriptionInitialStateType {
    loading: boolean,
    data: IFreeSubscription
    success: boolean,
    error: boolean,
    errors: any,
}

const initialState: FreeSubscriptionInitialStateType = {
  loading: false,
  data: {} as IFreeSubscription,
  success: false,
  error: false,
  errors: {},
};

interface IFreeSubscriptionReq {
token: string | null
}

export const freeSubscription = createAsyncThunk(
  "freeSubscription",
  async (req: IFreeSubscriptionReq, { rejectWithValue }) => {
    const {token} = req;
    const url = REST_API + ENDPOINTS.subscriptions.free;
    const headers = {
        Authorization: `Bearer ${token}`,
      }; 
    try {
      const response = await axios.post(url, {}, {headers: headers });
      return response.data;
    } catch (err) {
      const error: any = err;
      return rejectWithValue(error.response);
    }
  }
);

export const freeSubscriptionSlice = createSlice({
  name: "freeSubscription",
  initialState,
  reducers: {
    freeSubscriptionAction: () => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(freeSubscription.pending, (state:any) => {
        state.loading = true;
        state.success = false;
        state.errors.email = "";
      })
      .addCase(freeSubscription.fulfilled, (state:any, { payload }:any) => {
        state.loading = false;
        state.data = payload
        state.error = false;
        state.success = true;
      })
      .addCase(freeSubscription.rejected, (state:any, { payload }:any) => {
        const payl: any = payload;
        state.loading = false;
        state.success = false;
        state.error = true;
      });
  },
});

export const { freeSubscriptionAction } = freeSubscriptionSlice.actions;

export const FreeSubscription = (state: RootState) => state.freeSubscription;

export const FreeSubscriptionReducer = freeSubscriptionSlice.reducer;
