import { FC } from "react"

interface IIcon {
    fill?: string;
    stroke?: string;
    size?: number
}

export const CalendarIcon: FC<IIcon> = ({ fill = "#DDBE90", size = 16 }) => {
    return (
        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 20H2C1.46957 20 0.960859 19.7893 0.585786 19.4142C0.210714 19.0391 0 18.5304 0 18V4C0 3.46957 0.210714 2.96086 0.585786 2.58579C0.960859 2.21071 1.46957 2 2 2H4V0H6V2H12V0H14V2H16C16.5304 2 17.0391 2.21071 17.4142 2.58579C17.7893 2.96086 18 3.46957 18 4V18C18 18.5304 17.7893 19.0391 17.4142 19.4142C17.0391 19.7893 16.5304 20 16 20ZM2 8V18H16V8H2ZM2 4V6H16V4H2Z" fill={fill} />
        </svg>
    )
}