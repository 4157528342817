import { FC } from "react"

interface IIcon {
    fill?: string;
    stroke?: string;
}

export const PersonallyImg: FC<IIcon> = () => {
    return (
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.1" fillRule="evenodd" clipRule="evenodd" d="M42.7717 18.0353C49.2103 18.4101 54.8717 22.2041 58.9885 27.1686C62.7298 31.6802 64.664 37.5895 63.7935 43.3854C63.0209 48.5288 58.6438 51.9152 54.7531 55.3668C51.1987 58.52 47.5177 61.8069 42.7717 62.0345C37.8311 62.2715 33.6825 59.342 29.5831 56.5741C23.9688 52.7832 16.2242 50.0745 15.1524 43.3854C14.0285 36.3705 19.3164 29.9681 24.5504 25.1641C29.5455 20.5794 36.0031 17.6413 42.7717 18.0353Z" fill="#818181" />
            <g clipPath="url(#clip0_624_19464)">
                <path fillRule="evenodd" clipRule="evenodd" d="M68.0758 15.7886C66.5315 11.5889 63.0369 8.77387 59.3382 8.30688C58.2261 8.16655 57.1877 7.6545 56.3642 6.89419C54.8093 5.46094 52.589 4.91383 50.5413 5.66742L50.5242 5.67348C49.5899 6.02082 48.5424 5.92397 47.7439 5.32614C45.3852 3.56004 39.5412 3.15643 36.7925 4.16763C33.2427 5.47319 30.8985 11.7611 32.5931 16.0208C34.8002 21.5725 43.6511 23.1354 44.534 23.2913C46.2628 23.5963 47.6806 23.5844 49.6716 24.5937C52.3927 25.9725 52.6536 27.4689 54.8844 28.7232C57.1245 29.9829 60.3831 30.4534 62.9711 29.3816C67.7095 27.4185 69.7888 20.4447 68.0758 15.7886Z" fill="#1A0F91" />
                <path d="M31.119 45.1674C31.119 45.1674 28.4692 52.5267 28.228 55.5257C27.9869 58.5235 41.086 65.7971 41.086 65.7971C41.1835 65.5981 41.3548 65.1831 41.339 64.6349C41.3219 64.0498 41.1005 63.6242 40.9859 63.4332C39.9923 62.6465 38.5903 61.4026 36.9261 59.8543C34.9113 57.9793 32.9269 55.9342 33.0468 55.253C33.2879 53.8905 35.9378 45.9856 35.9378 45.9856L31.119 45.1674Z" fill="url(#paint0_linear_624_19464)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M31.119 45.1674C31.119 45.1674 28.4692 52.5267 28.228 55.5257C27.9869 58.5235 41.086 65.7971 41.086 65.7971C41.1835 65.5981 41.3548 65.1831 41.339 64.6349C41.3219 64.0498 41.1005 63.6242 40.9859 63.4332C39.9923 62.6465 38.5903 61.4026 36.9261 59.8543C34.9113 57.9793 32.9269 55.9342 33.0468 55.253C33.2879 53.8905 35.9378 45.9856 35.9378 45.9856L31.119 45.1674Z" fill="#FFB3AF" />
                <path fillRule="evenodd" clipRule="evenodd" d="M36.9253 59.8545C36.3587 59.3274 34.2965 57.1493 33.7813 56.6301C33.6548 56.9622 33.3768 57.5499 32.7957 58.0822C32.0077 58.8056 31.1341 58.9901 30.777 59.0455C32.602 60.5621 34.7393 62.1736 37.2534 63.6705C39.3907 64.9421 41.0853 65.7973 41.0853 65.7973C41.1828 65.5983 41.3541 65.1833 41.3383 64.6351C41.3211 64.05 41.0998 63.6244 40.9851 63.4334C39.9916 62.6467 38.5896 61.4028 36.9253 59.8545Z" fill="white" />
                <mask id="mask0_624_19464" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="42" y="22" width="5" height="6">
                    <path d="M45.5606 22.2841C46.0034 22.1458 46.4553 22.2495 46.5713 22.3265C46.678 22.3962 46.7044 22.4867 46.7044 22.4867C46.7228 22.5468 46.711 22.5999 46.7057 22.6227C46.4817 23.5488 46.2959 23.8494 46.2959 23.8494C45.9177 24.4983 45.3854 25.2801 44.6488 26.0807C43.9715 26.8185 43.2968 27.3802 42.721 27.7979C42.7197 27.606 42.725 27.3307 42.7684 27.0036C42.8198 26.6044 42.9608 25.8625 43.6908 24.5386C44.347 23.3498 44.8161 22.5157 45.5606 22.2841Z" fill="url(#paint1_linear_624_19464)" />
                </mask>
                <g mask="url(#mask0_624_19464)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M45.5606 22.2841C46.0034 22.1458 46.4553 22.2495 46.5713 22.3265C46.678 22.3962 46.7044 22.4867 46.7044 22.4867C46.7228 22.5468 46.711 22.5999 46.7057 22.6227C46.4817 23.5488 46.2959 23.8494 46.2959 23.8494C45.9177 24.4983 45.3854 25.2801 44.6488 26.0807C43.9715 26.8185 43.2968 27.3802 42.721 27.7979C42.7197 27.606 42.725 27.3307 42.7684 27.0036C42.8198 26.6044 42.9608 25.8625 43.6908 24.5386C44.347 23.3498 44.8161 22.5157 45.5606 22.2841Z" fill="url(#paint2_linear_624_19464)" />
                </g>
                <path d="M64.4155 30.7978C62.6406 29.2484 60.6166 29.805 60.6166 29.805C60.6166 29.805 60.5428 29.8496 60.4413 29.9295C58.8496 29.7814 56.9481 29.4444 54.886 28.723C49.9064 26.9811 46.6807 23.9444 45.0376 22.1292C44.4499 22.9974 43.8306 23.8718 43.177 24.7498C43.0505 24.9201 42.924 25.0892 42.7975 25.257C44.9611 26.7711 48.2527 28.6948 52.6037 29.9488C55.363 30.7443 57.8587 31.0707 59.9024 31.1842C59.9577 31.3316 60.0592 31.4853 60.2252 31.6422C61.2596 32.6245 63.7013 32.6201 64.741 33.1313C65.7806 33.6425 66.3841 32.7749 66.3841 32.7749C66.3841 32.7749 66.1904 32.3474 64.4155 30.7978Z" fill="url(#paint3_linear_624_19464)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M64.4155 30.7978C62.6406 29.2484 60.6166 29.805 60.6166 29.805C60.6166 29.805 60.5428 29.8496 60.4413 29.9295C58.8496 29.7814 56.9481 29.4444 54.886 28.723C49.9064 26.9811 46.6807 23.9444 45.0376 22.1292C44.4499 22.9974 43.8306 23.8718 43.177 24.7498C43.0505 24.9201 42.924 25.0892 42.7975 25.257C44.9611 26.7711 48.2527 28.6948 52.6037 29.9488C55.363 30.7443 57.8587 31.0707 59.9024 31.1842C59.9577 31.3316 60.0592 31.4853 60.2252 31.6422C61.2596 32.6245 63.7013 32.6201 64.741 33.1313C65.7806 33.6425 66.3841 32.7749 66.3841 32.7749C66.3841 32.7749 66.1904 32.3474 64.4155 30.7978Z" fill="#FFB3AF" />
                <path d="M31.0876 19.8044C27.4494 18.9612 24.9893 17.5162 23.3527 16.254C20.521 14.0698 19.7752 13.5448 17.8184 11.822C17.269 10.9054 17.2321 9.5288 15.1396 9.05403C12.7243 8.50587 11.6266 9.02755 11.6266 9.02755C11.6266 9.02755 11.2484 10.0133 12.321 10.5615C13.395 11.1096 15.1317 13.1428 16.6075 13.4168C16.6602 13.4267 16.7076 13.4289 16.7564 13.4339C18.082 15.3577 19.3997 16.7305 20.3932 17.6488C24.453 21.4003 26.394 20.6389 29.397 23.4819C29.6355 23.7075 30.1586 24.2192 30.5803 24.0737C31.3406 23.8112 31.2628 21.5891 31.0876 19.8044Z" fill="url(#paint4_linear_624_19464)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M31.0876 19.8044C27.4494 18.9612 24.9893 17.5162 23.3527 16.254C20.521 14.0698 19.7752 13.5448 17.8184 11.822C17.269 10.9054 17.2321 9.5288 15.1396 9.05403C12.7243 8.50587 11.6266 9.02755 11.6266 9.02755C11.6266 9.02755 11.2484 10.0133 12.321 10.5615C13.395 11.1096 15.1317 13.1428 16.6075 13.4168C16.6602 13.4267 16.7076 13.4289 16.7564 13.4339C18.082 15.3577 19.3997 16.7305 20.3932 17.6488C24.453 21.4003 26.394 20.6389 29.397 23.4819C29.6355 23.7075 30.1586 24.2192 30.5803 24.0737C31.3406 23.8112 31.2628 21.5891 31.0876 19.8044Z" fill="#FFB3AF" />
                <path fillRule="evenodd" clipRule="evenodd" d="M41.5573 71.1906C41.5573 71.1906 43.8883 71.7085 43.8883 72.2263C43.8883 72.7442 42.3348 75.8526 40.5216 76.3705C38.7085 76.8896 38.9668 75.5944 39.7442 74.8169C40.5216 74.0395 40.7799 70.9311 41.5573 71.1906Z" fill="#91B3FA" />
                <path fillRule="evenodd" clipRule="evenodd" d="M40.6893 63.3477C40.6893 63.3477 43.0677 63.1395 43.2245 63.6337C43.38 64.1278 42.8345 67.5604 41.2611 68.6001C39.6891 69.6397 39.5455 68.3273 40.0528 67.3522C40.5601 66.3771 39.871 63.3346 40.6893 63.3477Z" fill="#91B3FA" />
                <path d="M42.9151 44.8658C42.9151 44.8658 39.0688 56.8884 40.0334 61.25C40.9966 65.6115 42.4421 71.3356 42.4421 71.3356C42.4421 71.3356 42.8585 71.806 41.5632 72.0919C41.3682 72.1341 41.1692 72.0485 41.0612 71.8824C40.1599 70.4883 36.0974 64.0633 35.4557 60.4317C34.7323 56.3429 38.3467 45.9859 38.3467 45.9859L42.9151 44.8658Z" fill="url(#paint5_linear_624_19464)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M42.9151 44.8658C42.9151 44.8658 39.0688 56.8884 40.0334 61.25C40.9966 65.6115 42.4421 71.3356 42.4421 71.3356C42.4421 71.3356 42.8585 71.806 41.5632 72.0919C41.3682 72.1341 41.1692 72.0485 41.0612 71.8824C40.1599 70.4883 36.0974 64.0633 35.4557 60.4317C34.7323 56.3429 38.3467 45.9859 38.3467 45.9859L42.9151 44.8658Z" fill="#FFB3AF" />
                <path fillRule="evenodd" clipRule="evenodd" d="M31.9222 39.1902L29.2117 46.513C29.2117 46.513 29.0839 46.6711 29.2117 46.7989C29.3382 46.9254 33.481 48.3288 34.2835 49.0021C34.526 49.205 34.8132 49.35 35.0741 49.5305C35.1255 49.5648 35.2309 49.6385 35.3535 49.6175C35.4958 49.5924 35.5814 49.458 35.6144 49.3974L38.056 40.4105L31.9222 39.1902Z" fill="#1E1E1E" />
                <path fillRule="evenodd" clipRule="evenodd" d="M36.0531 40.1147C36.0531 40.1147 35.3732 44.5919 35.2717 47.5962C35.169 50.6005 35.4338 50.3357 35.4338 50.3357C35.4338 50.3357 35.2928 51.5387 41.5479 51.3108C42.0552 51.3397 43.7458 51.063 43.9158 50.8931C44.0844 50.7244 44.0844 50.5979 44.0844 50.5979C44.0844 50.5979 45.5853 43.539 44.5707 41.0023L36.0531 40.1147Z" fill="#1E1E1E" />
                <path d="M32.8291 19.2971C32.8291 19.2971 29.1962 18.6434 27.5412 17.8146L27.0049 23.9009C27.0049 23.9009 30.2174 25.3004 32.8291 25.4689C32.8291 25.4689 34.0598 20.5273 32.8291 19.2971Z" fill="url(#paint6_linear_624_19464)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M32.8291 19.2971C32.8291 19.2971 29.1962 18.6434 27.5412 17.8146L27.0049 23.9009C27.0049 23.9009 30.2174 25.3004 32.8291 25.4689C32.8291 25.4689 34.0598 20.5273 32.8291 19.2971Z" fill="#DDBE90" />
                <path d="M32.273 19.1894C31.9422 26.6794 31.3901 39.2617 31.4138 39.4492C31.4244 39.5301 31.4665 39.5844 31.4665 39.5844C31.5377 39.674 31.6589 39.6864 31.6787 39.6894C31.7946 39.7063 44.0781 41.7614 44.6619 41.5163C44.7963 41.4596 44.8622 41.3621 44.8622 41.3621C44.8622 41.3621 44.9399 41.2475 44.932 41.0762C44.9215 40.8651 43.8792 33.9351 42.9555 27.8142C42.9502 27.7831 42.9634 27.7518 42.991 27.5237C43.1531 26.217 43.3903 24.2393 43.4232 23.8614C43.4997 23 43.3046 21.5227 41.6483 19.1764C40.1172 18.9424 38.2948 18.7819 36.2458 18.8323C34.7818 18.8685 33.4497 19.0061 32.273 19.1894Z" fill="url(#paint7_linear_624_19464)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M32.273 19.1894C31.9422 26.6794 31.3901 39.2617 31.4138 39.4492C31.4244 39.5301 31.4665 39.5844 31.4665 39.5844C31.5377 39.674 31.6589 39.6864 31.6787 39.6894C31.7946 39.7063 44.0781 41.7614 44.6619 41.5163C44.7963 41.4596 44.8622 41.3621 44.8622 41.3621C44.8622 41.3621 44.9399 41.2475 44.932 41.0762C44.9215 40.8651 43.8792 33.9351 42.9555 27.8142C42.9502 27.7831 42.9634 27.7518 42.991 27.5237C43.1531 26.217 43.3903 24.2393 43.4232 23.8614C43.4997 23 43.3046 21.5227 41.6483 19.1764C40.1172 18.9424 38.2948 18.7819 36.2458 18.8323C34.7818 18.8685 33.4497 19.0061 32.273 19.1894Z" fill="#DDBE90" />
                <path fillRule="evenodd" clipRule="evenodd" d="M37.2416 14.7161L38.3208 18.6209C38.4077 18.9314 38.3036 19.2521 38.0783 19.3695C37.5618 19.6383 36.6104 20.022 35.7038 19.7901C35.4746 19.7316 35.2888 19.4905 35.2545 19.1991L34.767 15.0671L37.2416 14.7161Z" fill="#FFB3AF" />
                <path fillRule="evenodd" clipRule="evenodd" d="M38.618 13.0246C38.618 13.0246 39.3493 11.703 39.8303 12.444C40.3112 13.1851 38.5982 14.3734 38.1331 14.2709C37.668 14.1684 38.618 13.0246 38.618 13.0246Z" fill="#FFB3AF" />
                <path fillRule="evenodd" clipRule="evenodd" d="M33.4326 8.50133C33.4326 8.50133 29.4848 15.1786 32.6947 15.8427C35.9046 16.5067 38.1236 14.4918 38.6691 14.1376C39.2146 13.7836 40.1462 5.66066 33.4326 8.50133Z" fill="#FFB3AF" />
                <path fillRule="evenodd" clipRule="evenodd" d="M36.3351 14.0221C36.3187 14.2124 36.1565 14.3529 35.9728 14.336C35.789 14.3191 35.6534 14.1514 35.6696 13.9612C35.686 13.7711 35.8482 13.6305 36.0319 13.6474C36.2157 13.6642 36.3514 13.832 36.3351 14.0221Z" fill="#EF6F75" />
                <path fillRule="evenodd" clipRule="evenodd" d="M32.3827 11.8294C32.4241 12.0154 32.6034 12.1315 32.7832 12.0886C32.9629 12.0457 33.075 11.8601 33.0337 11.6741C32.9923 11.488 32.8131 11.3721 32.6332 11.4149C32.4535 11.4579 32.3414 11.6434 32.3827 11.8294Z" fill="#EF6F75" />
                <path fillRule="evenodd" clipRule="evenodd" d="M32.5788 13.7626C32.8311 13.535 33.0232 13.8513 33.5355 13.9706C34.0367 14.0873 34.3669 13.8332 34.4433 14.1748C34.5069 14.4597 34.1427 15.0366 33.2886 14.896C32.558 14.7758 32.3536 13.9655 32.5788 13.7626Z" fill="#801612" />
                <path fillRule="evenodd" clipRule="evenodd" d="M33.5347 13.9706C33.2017 13.893 33.0043 13.7333 32.8375 13.6926L32.8081 13.9327C32.8081 13.9327 32.9553 14.1052 33.3561 14.2096C33.8365 14.3347 34.1795 14.2119 34.1795 14.2119L34.2607 13.9923C34.1016 13.9696 33.8528 14.0446 33.5347 13.9706Z" fill="#FAF9F6" />
                <path fillRule="evenodd" clipRule="evenodd" d="M33.2898 14.896C33.4679 14.9254 33.6233 14.9221 33.7593 14.8982C33.6116 14.4952 33.2331 14.2139 32.7908 14.2287C32.7038 14.2316 32.6211 14.2493 32.541 14.2733C32.6372 14.5454 32.8809 14.8288 33.2898 14.896Z" fill="#EB9599" />
                <path fillRule="evenodd" clipRule="evenodd" d="M34.4919 12.2219C34.5306 12.097 34.3833 11.9981 34.2823 12.0813L33.601 12.6424C33.5329 12.6985 33.5381 12.8043 33.6113 12.8535L34.0314 13.1358C34.1046 13.185 34.2046 13.1497 34.2307 13.0654L34.4919 12.2219Z" fill="#54100D" />
                <path fillRule="evenodd" clipRule="evenodd" d="M34.7312 10.1651L33.8781 9.94016C33.949 9.70108 34.1974 9.55751 34.4329 9.61964C34.6686 9.68181 34.8021 9.92602 34.7312 10.1651Z" fill="#170D8A" />
                <path fillRule="evenodd" clipRule="evenodd" d="M36.5638 12.2738L36.2789 11.4315C36.5104 11.3436 36.7618 11.4609 36.8403 11.6935C36.9191 11.926 36.7952 12.1857 36.5638 12.2738Z" fill="#170D8A" />
                <path fillRule="evenodd" clipRule="evenodd" d="M34.2059 10.729C34.218 10.9183 34.0743 11.0816 33.885 11.0937C33.6956 11.1059 33.5322 10.9621 33.5202 10.7728C33.5081 10.5834 33.6518 10.42 33.8412 10.4081C34.0305 10.3959 34.1938 10.5396 34.2059 10.729Z" fill="#1E1E1E" />
                <path fillRule="evenodd" clipRule="evenodd" d="M36.1642 12.1434C36.1762 12.3328 36.0326 12.4962 35.8433 12.5081C35.6537 12.5203 35.4905 12.3767 35.4784 12.1874C35.4664 11.998 35.6099 11.8346 35.7993 11.8225C35.9888 11.8105 36.1519 11.954 36.1642 12.1434Z" fill="#1E1E1E" />
                <path fillRule="evenodd" clipRule="evenodd" d="M39.9513 12.1289C39.9513 12.1289 37.2263 14.2231 36.9167 8.85625C36.9167 8.85625 35.462 10.1945 35.151 8.73739C35.151 8.73739 32.6395 9.14417 32.5485 8.10148C32.4576 7.05892 36.3896 4.94759 38.8471 7.36673C41.3033 9.78588 39.9513 12.1289 39.9513 12.1289Z" fill="#170D8A" />
                <path fillRule="evenodd" clipRule="evenodd" d="M33.0559 8.66228C33.2416 8.63514 33.434 8.62183 33.6304 8.62301C33.6765 7.96312 34.6384 8.00792 35.8942 7.90132C36.8679 7.81857 37.5808 7.82318 38.4808 8.15932C38.9472 8.33418 39.4058 8.52353 39.8591 8.72329C39.6324 8.28384 39.3149 7.82792 38.8471 7.36673C36.3896 4.94758 32.4576 7.05892 32.5485 8.10161C32.5736 8.38227 32.7765 8.55555 33.0559 8.66228Z" fill="#1B0E8E" />
                <path d="M46.7044 22.487C46.5371 22.1328 46.0719 21.7239 45.5264 21.2432C44.89 20.6815 42.4799 18.556 40.7682 19.3505C40.0646 19.6774 39.751 20.3719 39.6153 20.6765C38.9749 22.1131 39.2621 23.7759 40.2886 24.9222C41.1925 25.9326 42.111 26.8037 43.0044 27.5868C42.4944 27.14 46.2933 22.6986 46.7044 22.487Z" fill="url(#paint8_linear_624_19464)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M46.7044 22.487C46.5371 22.1328 46.0719 21.7239 45.5264 21.2432C44.89 20.6815 42.4799 18.556 40.7682 19.3505C40.0646 19.6774 39.751 20.3719 39.6153 20.6765C38.9749 22.1131 39.2621 23.7759 40.2886 24.9222C41.1925 25.9326 42.111 26.8037 43.0044 27.5868C42.4944 27.14 46.2933 22.6986 46.7044 22.487Z" fill="#DDBE90" />
                <path fillRule="evenodd" clipRule="evenodd" d="M40.1722 61.7915C39.8731 62.0445 39.2116 62.5413 38.2682 62.7547C37.3036 62.9735 36.5104 62.8114 36.1309 62.7073C36.235 63.3042 36.99 64.9131 37.9717 66.7539C39.1853 69.0309 40.7678 71.4291 41.0617 71.8824C41.1697 72.0484 41.3687 72.134 41.5637 72.0919C42.859 71.8059 42.4426 71.3355 42.4426 71.3355C42.4426 71.3355 41.1038 65.8684 40.1722 61.7915Z" fill="white" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_624_19464" x1="1339.79" y1="1076.65" x2="28.2782" y2="1076.65" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A12B24" />
                    <stop offset="1" stopColor="#C0423B" />
                </linearGradient>
                <linearGradient id="paint1_linear_624_19464" x1="596.372" y1="-3370.99" x2="155.891" y2="-1039.03" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E0463C" />
                    <stop offset="1" stopColor="#E95F5A" />
                </linearGradient>
                <linearGradient id="paint2_linear_624_19464" x1="596.372" y1="-3370.99" x2="155.891" y2="-1039.03" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#E0463C" />
                    <stop offset="1" stopColor="#E95F5A" />
                </linearGradient>
                <linearGradient id="paint3_linear_624_19464" x1="2401.46" y1="580.303" x2="42.7566" y2="580.303" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A12B24" />
                    <stop offset="1" stopColor="#C0423B" />
                </linearGradient>
                <linearGradient id="paint4_linear_624_19464" x1="1975.85" y1="773.597" x2="11.5452" y2="773.597" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A12B24" />
                    <stop offset="1" stopColor="#C0423B" />
                </linearGradient>
                <linearGradient id="paint5_linear_624_19464" x1="790.874" y1="1406.7" x2="35.3167" y2="1406.7" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A12B24" />
                    <stop offset="1" stopColor="#C0423B" />
                </linearGradient>
                <linearGradient id="paint6_linear_624_19464" x1="702.56" y1="-230.951" x2="-350.578" y2="1058.16" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FD6F61" />
                    <stop offset="1" stopColor="#FF928E" />
                </linearGradient>
                <linearGradient id="paint7_linear_624_19464" x1="100.064" y1="-1450.48" x2="3045.95" y2="3334.36" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FD6F61" />
                    <stop offset="1" stopColor="#FF928E" />
                </linearGradient>
                <linearGradient id="paint8_linear_624_19464" x1="-714.924" y1="-1948.87" x2="2649.98" y2="3516.55" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FD6F61" />
                    <stop offset="1" stopColor="#FF928E" />
                </linearGradient>
                <clipPath id="clip0_624_19464">
                    <rect width="57.1877" height="73" fill="white" transform="translate(11.5577 3.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}