
import layout from '../../styles/_layout.module.scss'
import s from '../Profile/profile.module.scss'
import { Nav } from '../Nav/Nav'
import { ArrowBackIcon } from '../../icons/arrowback-icon'
import { CustomButtom } from '../common/CustomButton/CustomButton'
import { ShareIcon } from '../../icons/share-icon'
import { ThreeDotsIcon } from '../../icons/threedots-icon'
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { IReward, MeSlice, getMe } from '../../store/slice/meSlice';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { UserIcon } from '../../icons/user-icon';
import { PhotoIcon } from '../../icons/photo-icon';
import { setPhoto } from '../../store/slice/setUserPhotoSlice';
import { InfoIcon } from '../../icons/info-icon';
import { EditIcon } from '../../icons/edit-icon';
import { KubokIcon } from '../../icons/kubok-icon';
import { Overlay } from '../common/Overlay/Overlay';
import { CalendarIcon } from '../../icons/calendar-icon';
import { MarkerIcon } from '../../icons/marker-icon';
import { MnInput } from '../common/MnInput/MnInput';
import { DatePicker, Dropdown, MenuProps, Select, Spin } from 'antd';
import mnInput from '../common/MnInput/mn-input.module.scss'
import { updateUser } from '../../store/slice/userUpdateSlice';
import { useFormik } from 'formik';
import { getCountries } from '../../store/slice/getCountriesSlice';
import { AwardItem } from '../Award/AwardItem';
import { customRoute } from '../../utilities';
import { Scripts } from '../common/Scripts/Scripts';
import { AddAward } from '../Award/AddAward';
import { StaticSlice, setProfileEdit, setSubUser, setSupport } from '../../store/slice/staticSlice';
import { backUser } from '../../store/slice/tokenSlice';
import { GetSports, ISport, getSports } from '../../store/slice/getSportsSlice';
import { GetUserRewards, getUserRewards } from '../../store/slice/getUserRewardsSlice';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet'
import { ProfileEditInfo } from '../Profile/ProfileEditInfo'
import { AddProfile } from '../Profile/AddProfile'
import { Subscription } from '../Profile/Subscription'
import { ChangePassword } from '../Profile/ChangePassword'
import { GetUser, getUser } from '../../store/slice/getUserSlice'

const genderList = ["Муж", "Жен"]

interface ISportsFilter { id: number, title: string, qty: number }

export const User = () => {
    const params = useParams()
    const id = params.id
    const dispatch = useAppDispatch()
    const router = useNavigate();
    const {loading, success, data} = useAppSelector(GetUser)
    const inputFile = useRef() as MutableRefObject<HTMLInputElement>
    const [progress, setProgress] = useState(0)
    const [infoStatus, setInfoStatus] = useState(false)
    // const [editStatus, setEditStatus] = useState(false)
    const [subsStatus, setSubsStatus] = useState(false)
    const [addStatus, setAddStatus] = useState(false)
    const [profileStatus, setProfileStatus] = useState(false)
    const [changePasswordStatus, setChangePasswordStatus] = useState(false)
    const [subsTitle, setSubsTitle] = useState('Управлять подпиской')
    const [profileTitle, setProfileTitle] = useState('Добавить профиль')
    const { profileEdit, isSubUser } = useAppSelector(StaticSlice)
    const { success: sSuccess, data: sData } = useAppSelector(GetSports)
    const [sportsFilter, setSportsFilter] = useState<ISportsFilter[]>([] as ISportsFilter[])
    const { success: rSuccess, loading: rLoading, data: rewards } = useAppSelector(GetUserRewards)



    const setEditStatus = (status: boolean, subuser: boolean = false) => {
        dispatch(setSubUser(subuser))
        dispatch(setProfileEdit(status))
    }

    const clickHandler = (e: any) => {
        e.preventDefault()
        const files = Array.prototype.slice.call(inputFile.current.files);
        const formData = new FormData();
        formData.append('file', files[0]);
        const token = localStorage.getItem('access');
        dispatch(setPhoto({
            token: token,
            data: formData
        })).then((resp: any) => {
            if (!resp.error) {
                dispatch(getMe({ token: localStorage.getItem("access") }))
            }
        })
    }

    useEffect(() => {
        if (sSuccess) {
            sData.map((sport: ISport) => {
                setSportsFilter(curSPortsList => [...curSPortsList, { id: sport.id, title: sport.title, qty: 0 }])
            })
        }
    }, [sSuccess])

    useEffect(() => {
        if (sSuccess && success) {
            // Функция для создания объекта для array3
            const createArray3Object = (event: ISport) => {
                const eventID = event.id;
                const matchingSports = rewards.filter(sport => sport?.sport?.id === eventID);
                return {
                    id: event.id,
                    title: event.title,
                    qty: matchingSports.length
                };
            };

            // Создаем array3
            const newArray3 = sData.map(event => createArray3Object(event));
            setSportsFilter(newArray3);
        }
    }, [sSuccess, rSuccess, sData, rewards])

    useEffect(() => {
        !!id && dispatch(getUser({ id: id})).then((resp: any) => {
            console.log(resp)
            if(!resp.error){
                localStorage.removeItem('savedUserId')
            }
            if (resp.error) {
                localStorage.setItem('savedUserId', id)
                customRoute('/auth')
            }
        })
        dispatch(getSports({}))
        dispatch(getCountries({}))

        return () => {
            setSportsFilter([] as ISportsFilter[])
        }
    }, [])

    useEffect(() => {
        if (!loading && success) {
            dispatch(getUserRewards({
                id: data.id
            }))
        }
    }, [success, data, loading])

    useEffect(() => {
        let _progress = 0
        if (!!data?.profile?.first_name) {
            _progress += 10
        }
        if (!!data?.profile?.last_name) {
            _progress += 10
        }
        if (!!data?.profile?.middle_name) {
            _progress += 10
        }
        if (!!data?.profile?.gender) {
            _progress += 10
        }
        if (!!data?.profile?.birth_date) {
            _progress += 10
        }
        if (!!data?.profile?.country) {
            _progress += 10
        }
        if (!!data?.profile?.city) {
            _progress += 10
        }
        if (!!data?.profile?.bio) {
            _progress += 10
        }
        if (rewards?.length > 0) {
            _progress += 20
        }
        setProgress(_progress)
    }, [data])


    const breakString = (str: string) => {
        const words = str.split(' ');
        const firstWord = words[0];
        const restOfWords = words.slice(1).join(' ');

        if (restOfWords.length === 0) {
            return (<div>{firstWord}</div>)
        }
        return (<><div className={s.statFirstLine}>{firstWord}</div><div className={s.statSecondLine}>{restOfWords}</div></>)
    }


    const ProfileUserInfo = () => {
        return (<>
            <div className={s.profileUserInfo}>
                <div className={s.profileUserInfoItem}>
                    <div className={s.profileUserInfoIcon}>
                        <CalendarIcon />
                    </div>
                    <div className={s.profileUserInfoData}>
                        <div className={s.profileUserInfoDataTitle}>
                            Дата рождения:
                        </div>
                        <div className={s.profileUserInfoDataText}>
                            {data?.profile?.birth_date}
                        </div>
                    </div>
                </div>
                <div className={s.profileUserInfoItem}>
                    <div className={s.profileUserInfoIcon}>
                        <MarkerIcon />
                    </div>
                    <div className={s.profileUserInfoData}>
                        <div className={s.profileUserInfoDataTitle}>
                            Место проживания
                        </div>
                        <div className={s.profileUserInfoDataText}>
                            {data?.profile?.country?.name}, {data?.profile?.city?.name}
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }



    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Профиль | Мои награды</title>
            <meta name="description" content="Ваши награды и результаты соревнований"
            />
        </Helmet>
        <div className={layout.container}>
            <div className={layout.content}>
                <div className={s.userNameBlock}>
                    <div className={s.userAvatar}>
                        {!data?.profile?.image && <>
                            <div className={s.avatar} onClick={() => {
                                inputFile.current.click()
                            }}>
                                <UserIcon size={16} />
                            </div>
                            <input onChange={e => { clickHandler(e) }} type='file' id='file' ref={inputFile} style={{ display: 'none' }} />
                            <div className={s.addPhotoButton} onClick={() => {
                                inputFile.current.click()
                            }}><PhotoIcon size={10} fill="#E9E9E9" /></div></>}
                        {data?.profile?.image &&
                            <div className={s.avatar}>
                                <img width={64} height={64} alt="" src={data.profile.image} className={s.avatarImage} />
                            </div>}
                    </div>
                    <div className={s.userName}>
                        {/* {data?.profile?.first_name && data?.profile?.first_name} {data?.profile?.last_name && data?.profile?.last_name} */}
                        {data?.profile?.full_name}
                    </div>
                </div>
                {!!data?.profile?.bio && <div className={s.userAboutBlock} style={{borderRadius: '0'}}>
                    <div className={s.userAboutBlockTitle}>
                        Обо мне:
                    </div>
                    <div className={s.userAboutBlockContent}>
                        {data?.profile?.bio}
                    </div>
                </div>}
                <div className={s.userStatisticsBlock}>
                    <div className={s.userStatisticsBlockTitle}>
                        Статистика
                    </div>
                    <div className={s.userStatisticsBlockBlocks}>
                        <div className={s.userStatisticsBlockTotal}>
                            <div className={s.userStatisticsBlockTotalTitle}>
                                Всего наград
                            </div>
                            <div className={s.userStatisticsBlockTotalValue}>
                                {rewards?.length}
                            </div>
                            <div className={s.userStatisticsBlockTotalRound}>
                                <KubokIcon />
                            </div>
                        </div>
                        {success && rewards?.filter(reward => reward.category === "non_sport").length > 0 &&
                            <div className={s.userStatisticsBlockItem}>
                                <div className={s.userStatisticsBlockItemTitle}>
                                    Неспортивные награды
                                </div>
                                <div className={s.userStatisticsBlockItemValue}>
                                    {rewards.filter(reward => reward.category === "non_sport").length}
                                </div>
                                <div className={s.userStatisticsBlockItemRound}>
                                </div>
                            </div>
                        }
                        {sportsFilter.filter(sport => sport.qty > 0).map((st: ISportsFilter, i: number) => {
                            return (<div key={i} className={s.userStatisticsBlockItem}>
                                <div className={s.userStatisticsBlockItemTitle}>
                                    {breakString(st.title)}
                                </div>
                                <div className={s.userStatisticsBlockItemValue}>
                                    {st.qty}
                                </div>
                                <div className={s.userStatisticsBlockItemRound}>
                                </div>
                            </div>)
                        })
                        }
                    </div>
                </div>
                <div className={s.userRewardsBlock}>
                    <div className={s.userRewardsBlockTitle}>
                        Награды
                    </div>
                    <Spin spinning={loading}>
                        <div className={layout.awardList}>
                            {rewards?.map((reward: IReward, index: number) => {

                                return (<>
                                    <AwardItem award={reward} key={index} />
                                </>)
                            })}
                        </div>
                    </Spin>
                </div>
                <div className={s.userAlbumsBlock}>

                </div>
            </div>

            <div className={layout.footer}>
                <Nav />
            </div>
        </div>
        <Scripts />
        {infoStatus && <Overlay
            title={data?.profile?.full_name}
            status={infoStatus}
            setStatus={setInfoStatus}>
            <ProfileUserInfo />
        </Overlay>}
        {profileEdit && <Overlay
            title='Изменить профиль'
            status={profileEdit}
            setStatus={setEditStatus}>
            <ProfileEditInfo subuser={isSubUser} setStatus={setEditStatus} />
        </Overlay>}
        {changePasswordStatus && <Overlay
            title='Изменить пароль'
            status={changePasswordStatus}
            setStatus={setChangePasswordStatus}>
            <ChangePassword />
        </Overlay>}
        {subsStatus && <Overlay
            title={subsTitle}
            status={subsStatus}
            setStatus={setSubsStatus}>
            <Subscription handleTitle={setSubsTitle} setStatus={setSubsStatus} />
        </Overlay>}
        {profileStatus && <Overlay
            title={profileTitle}
            status={profileStatus}
            setStatus={setProfileStatus}>
            <AddProfile setRewardStatus={setAddStatus} handleTitle={setProfileTitle} setStatus={setProfileStatus} />
        </Overlay>}
        {addStatus && <Overlay
            title={'Добавление награды'}
            status={addStatus}
            setStatus={setAddStatus}>
            <AddAward />
        </Overlay>}
    </>
    )
}