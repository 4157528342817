
import React, { FC, MutableRefObject, useEffect, useRef, useState } from "react"
import s from './auth.module.scss'
import { UserIcon } from "../../icons/user-icon"
import { CustomButtom } from "../common/CustomButton/CustomButton"
import { PhotoIcon } from "../../icons/photo-icon"
import { CustomInput } from "../common/CustomInput/CustomInput"
import { setPhoto } from "../../store/slice/setUserPhotoSlice"
import { useAppDispatch } from "../../store/hooks"


interface IRegisterProfile {
    id: string
    onChange: (e: any) => void
}

export const RegistrationProfile: FC<IRegisterProfile> = ({ id, onChange }) => {
    const inputFile = useRef() as MutableRefObject<HTMLInputElement>
    const [filename, setFileName] = useState<null | string>(null)
    const dispatch = useAppDispatch()

    const clickHandler = (e: any) => {
        e.preventDefault()
        const files = Array.prototype.slice.call(inputFile.current.files);
        const formData = new FormData();
        formData.append('file', files[0]);
        const token = localStorage.getItem('access');
        dispatch(setPhoto({
            token: token,
            data: formData
        })).then((resp: any) => {
            if (!resp.error) {
                setFileName(resp.payload.image)
            }
        })
    }

    return (<>
        <div className={s.profilePromoBlock}>
            <div className={s.profilePromoCat}>😻</div>
            <div className={s.profilePromoText}>Мы уже близко! Хотим узнать вас по-лучше 👀</div>
        </div>

        <div className={s.profileForm}>
            <div className={s.profileAvatar}>
                {filename !== null ? <img className={s.userAvatar} width={150} height={150} alt="" src={filename} /> : <UserIcon size={28} fill="#E9E9E9" />}
            </div>
            <input onChange={e => { clickHandler(e) }} type='file' id='file' ref={inputFile} style={{ display: 'none' }} />
            <CustomButtom
                onClick={() => {
                    inputFile.current.click();
                }}
                type='secondary'
            ><PhotoIcon /> Загрузить фотографию
            </CustomButtom>
            <CustomInput
                name="username"
                icon={<UserIcon size={16} fill="#DDBE90" />}
                type="text"
                onChange={onChange}
                placeholder='Ваше имя'
                style={{ width: "100%", marginBottom: 0 }}
            />
        </div>
    </>)
}
