
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import axios from "axios";
import { ENDPOINTS, REST_API } from "../../const/constants";

interface IAddAppeal {
    id: number;
    title: string;
    user: string;
}

interface AddAppealInitialStateType {
    loading: boolean,
    data: IAddAppeal
    success: boolean,
    error: boolean,
    errors: any,
}

const initialState: AddAppealInitialStateType = {
  loading: false,
  data: {} as IAddAppeal,
  success: false,
  error: false,
  errors: {},
};

interface IAddAppealReq {
    token: string | null,
    subject: string,
    message: string,
}

export const addAppeal = createAsyncThunk(
  "addAppeal",
  async (req: IAddAppealReq, { rejectWithValue }) => {
    const { token, subject, message} = req;
    const url = REST_API + ENDPOINTS.appeal;
    const headers = {
        Authorization: `Bearer ${token}`,
      };
    try {
      const response = await axios.post(url, { topic: subject, context: message,}, {headers: headers });
      return response.data;
    } catch (err) {
      const error: any = err;
      return rejectWithValue(error.response);
    }
  }
);

export const addAppealSlice = createSlice({
  name: "addAppeal",
  initialState,
  reducers: {
    addAppealAction: () => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(addAppeal.pending, (state:any) => {
        state.loading = true;
        state.success = false;
        state.errors.email = "";
      })
      .addCase(addAppeal.fulfilled, (state:any, { payload }:any) => {
        state.loading = false;
        state.data = payload
        state.error = false;
        state.success = true;
      })
      .addCase(addAppeal.rejected, (state:any, { payload }:any) => {
        const payl: any = payload;
        state.loading = false;
        state.success = false;
        state.error = true;
      });
  },
});

export const { addAppealAction } = addAppealSlice.actions;

export const AddAppeal = (state: RootState) => state.addAppeal;

export const AddAppealReducer = addAppealSlice.reducer;
