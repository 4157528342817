import { FC } from "react"

interface IIcon {
    fill?: string;
    stroke?: string;
}

export const TeacherImg: FC<IIcon> = () => {
    return (
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.1" fillRule="evenodd" clipRule="evenodd" d="M40.4098 19.0257C45.6386 18.6741 50.1029 21.9967 54.1643 25.3035C58.8645 29.1304 65.3234 32.9905 64.9874 39.0367C64.6538 45.0403 57.2086 47.3193 52.7181 51.3259C48.6761 54.9324 45.8013 61.5284 40.4098 60.9663C35.035 60.4059 34.4441 52.6133 30.4289 49.0021C25.9172 44.9445 16.5862 45.0735 16.0261 39.0367C15.475 33.0961 23.7749 30.8248 28.3909 27.0365C32.2594 23.8618 35.4136 19.3616 40.4098 19.0257Z" fill="#818181" />
            <g clipPath="url(#clip0_624_19647)">
                <mask id="mask0_624_19647" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="35" y="48" width="13" height="11">
                    <path d="M47.7246 58.125L47.2799 57.7552H47.7916V58.125H47.7246ZM35.9764 48.3459L35.7728 48.1771L35.9756 48.1763L35.9764 48.3459Z" fill="url(#paint0_linear_624_19647)" />
                </mask>
                <g mask="url(#mask0_624_19647)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M47.7246 58.125L47.2799 57.7552H47.7916V58.125H47.7246ZM35.9764 48.3459L35.7728 48.1771L35.9756 48.1763L35.9764 48.3459Z" fill="url(#paint1_linear_624_19647)" />
                </g>
                <path fillRule="evenodd" clipRule="evenodd" d="M31.3981 20.2355L31.3964 20.233C29.6344 20.1125 28.0717 19.535 26.7962 18.8389C26.7439 18.8031 26.7048 18.7864 26.7048 18.7864L26.7109 18.8025C26.2375 18.5206 25.7955 18.2268 25.3935 17.939C25.1256 18.6171 24.9159 19.314 24.707 20.0124C26.1018 21.9073 27.5801 23.1996 29.0663 24.0482C29.6344 24.3836 30.2122 24.6455 30.7943 24.8448C30.9866 24.9133 31.1789 24.9775 31.3703 25.0334L31.3685 25.0214C36.7084 26.481 44.1356 21.916 44.1356 21.916C44.1356 21.916 41.132 19.6615 39.6554 17.2139C39.6145 17.2263 39.5501 17.2461 39.4718 17.2716C37.2792 17.9793 36.3038 19.0767 35.1509 19.6852C34.4078 20.0772 33.2253 20.4587 31.3981 20.2355Z" fill="url(#paint2_linear_624_19647)" />
                <path d="M23.7797 18.6413C23.8728 18.7915 23.9703 18.9431 24.0703 19.0959C24.3044 19.4515 24.5402 19.7876 24.7751 20.1047C24.9979 19.4033 25.2197 18.7018 25.4416 18.0004C25.0649 17.5543 24.4427 16.9242 23.5283 16.3907C23.3629 16.2945 22.9218 15.8833 22.3536 15.8398C20.0931 15.6666 16.6605 16.1467 16.6605 16.1467C16.7032 16.7626 17.2626 16.7653 17.2626 16.7653L19.0803 16.6641C19.2708 17.1747 19.3665 17.6809 19.7059 18.1709C19.9626 18.5421 20.3619 18.7929 20.8074 18.8844C21.2547 18.9763 21.8446 19.0848 22.3388 19.1324C23.2733 19.2224 23.7797 18.6413 23.7797 18.6413Z" fill="url(#paint3_linear_624_19647)" />
                <path d="M31.3985 20.2353L31.3968 20.2329C29.6348 20.1124 28.0721 19.5349 26.7965 18.8387C26.7443 18.803 26.7052 18.7863 26.7052 18.7863L26.7112 18.8024C26.2379 18.5205 25.7959 18.2267 25.3939 17.9389C25.1259 18.617 24.9162 19.3139 24.7074 20.0122C26.1022 21.9071 27.5805 23.1995 29.0666 24.0481C29.6348 24.3834 30.2125 24.6453 30.7946 24.8447C30.9869 24.9132 31.1792 24.9774 31.3707 25.0333L31.3689 25.0213C36.7087 26.4809 44.1359 21.9158 44.1359 21.9158C44.1359 21.9158 41.1323 19.6614 39.6558 17.2137C39.6149 17.2262 39.5505 17.246 39.4722 17.2714C37.2795 17.9792 36.3041 19.0766 35.1513 19.6851C34.4082 20.0771 33.2257 20.4586 31.3985 20.2353Z" fill="#DDBE90" />
                <path fillRule="evenodd" clipRule="evenodd" d="M23.7797 18.6413C23.8728 18.7915 23.9703 18.9431 24.0703 19.0959C24.3044 19.4515 24.5402 19.7876 24.7751 20.1047C24.9979 19.4033 25.2197 18.7018 25.4416 18.0004C25.0649 17.5543 24.4427 16.9242 23.5283 16.3907C23.3629 16.2945 22.9218 15.8833 22.3536 15.8398C20.0931 15.6666 16.6605 16.1467 16.6605 16.1467C16.7032 16.7626 17.2626 16.7653 17.2626 16.7653L19.0803 16.6641C19.2708 17.1747 19.3665 17.6809 19.7059 18.1709C19.9626 18.5421 20.3619 18.7929 20.8074 18.8844C21.2547 18.9763 21.8446 19.0848 22.3388 19.1324C23.2733 19.2224 23.7797 18.6413 23.7797 18.6413Z" fill="#FFB3AF" />
                <mask id="mask1_624_19647" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="20" y="16" width="4" height="3">
                    <path d="M23.0187 18.3202C23.05 18.3088 23.077 18.2871 23.0953 18.2566C23.1362 18.186 23.1127 18.0952 23.0422 18.0536C22.8673 17.9508 21.3194 17.045 20.8226 16.9659C20.6425 16.937 20.5206 16.8831 20.4719 16.8098C20.4484 16.7748 20.4388 16.7294 20.4423 16.6723L21.7858 16.4577L22.7211 16.8024C22.7977 16.8306 22.883 16.7913 22.9108 16.7147C22.9395 16.6379 22.9004 16.5526 22.8238 16.5244L21.8519 16.1663C21.8284 16.1575 21.8023 16.1551 21.7779 16.1591L20.2901 16.3965C20.23 16.4066 20.183 16.4501 20.1691 16.5081C20.1239 16.6966 20.1421 16.849 20.2257 16.9742C20.324 17.1221 20.5041 17.2151 20.7756 17.2586C21.1497 17.3181 22.4305 18.037 22.8917 18.309C22.9326 18.3325 22.9787 18.3353 23.0187 18.3202Z" fill="url(#paint4_linear_624_19647)" />
                </mask>
                <g mask="url(#mask1_624_19647)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M23.0187 18.3202C23.05 18.3088 23.077 18.2871 23.0953 18.2566C23.1362 18.186 23.1127 18.0952 23.0422 18.0536C22.8673 17.9508 21.3194 17.045 20.8226 16.9659C20.6425 16.937 20.5206 16.8831 20.4719 16.8098C20.4484 16.7748 20.4388 16.7294 20.4423 16.6723L21.7858 16.4577L22.7211 16.8024C22.7977 16.8306 22.883 16.7913 22.9108 16.7147C22.9395 16.6379 22.9004 16.5526 22.8238 16.5244L21.8519 16.1663C21.8284 16.1575 21.8023 16.1551 21.7779 16.1591L20.2901 16.3965C20.23 16.4066 20.183 16.4501 20.1691 16.5081C20.1239 16.6966 20.1421 16.849 20.2257 16.9742C20.324 17.1221 20.5041 17.2151 20.7756 17.2586C21.1497 17.3181 22.4305 18.037 22.8917 18.309C22.9326 18.3325 22.9787 18.3353 23.0187 18.3202Z" fill="#FFB3AF" />
                </g>
                <path d="M54.6605 17.3852C54.6605 17.3852 55.3253 16.182 56.1449 16.8889C59.9691 20.1876 69.602 30.8359 57.3466 39.1055L57.0394 37.9265C57.0394 37.9265 63.4643 29.7483 54.0959 25.212C54.0959 25.212 54.0236 18.8096 54.6605 17.3852Z" fill="url(#paint5_linear_624_19647)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M54.6605 17.3852C54.6605 17.3852 55.3253 16.182 56.1449 16.8889C59.9691 20.1876 69.602 30.8359 57.3466 39.1055L57.0394 37.9265C57.0394 37.9265 63.4643 29.7483 54.0959 25.212C54.0959 25.212 54.0236 18.8096 54.6605 17.3852Z" fill="#DDBE90" />
                <path d="M54.6194 74.8719C54.6202 74.8345 54.6176 74.797 54.608 74.7596C54.0512 72.5505 51.7732 71.4185 51.7732 71.4185L50.6769 71.5046C50.047 73.451 49.6406 74.402 49.7415 74.9337C49.745 74.9484 49.7494 74.9632 49.7529 74.9772C50.6569 75.0581 52.5181 75.1112 54.6194 74.8719Z" fill="url(#paint6_linear_624_19647)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M54.6194 74.8719C54.6202 74.8345 54.6176 74.797 54.608 74.7596C54.0512 72.5505 51.7732 71.4185 51.7732 71.4185L50.6769 71.5046C50.047 73.451 49.6406 74.402 49.7415 74.9337C49.745 74.9484 49.7494 74.9632 49.7529 74.9772C50.6569 75.0581 52.5181 75.1112 54.6194 74.8719Z" fill="url(#paint7_linear_624_19647)" />
                <path d="M49.752 74.9772C49.8015 75.1547 49.9321 75.2931 50.1 75.3287C51.386 75.5994 53.4299 75.381 54.2878 75.2696C54.4775 75.2444 54.6158 75.0695 54.6184 74.8719C52.5171 75.1112 50.656 75.0581 49.752 74.9772Z" fill="url(#paint8_linear_624_19647)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M49.752 74.9772C49.8015 75.1547 49.9321 75.2931 50.1 75.3287C51.386 75.5994 53.4299 75.381 54.2878 75.2696C54.4775 75.2444 54.6158 75.0695 54.6184 74.8719C52.5171 75.1112 50.656 75.0581 49.752 74.9772Z" fill="url(#paint9_linear_624_19647)" />
                <path d="M52.1856 56.4684C52.1151 57.4151 52.049 58.8481 52.136 60.5918C52.2526 62.9132 52.551 63.9765 52.6824 65.6584C52.799 67.1523 52.7981 69.3145 52.1952 72.0231C52.1551 72.0927 52.0794 72.3146 51.8724 72.3338C51.6801 72.352 51.4051 72.3625 51.1293 72.3216C50.8822 72.2859 50.709 72.0336 50.7534 71.7638C49.8581 68.0346 49.7597 65.44 49.8511 63.7207C49.8798 63.1899 49.9633 62.0544 49.7519 60.5422C49.5179 58.8672 49.0358 57.5377 48.6591 56.6676C49.8346 56.6015 51.0101 56.5345 52.1856 56.4684Z" fill="url(#paint10_linear_624_19647)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M52.1856 56.4684C52.1151 57.4151 52.049 58.8481 52.136 60.5918C52.2526 62.9132 52.551 63.9765 52.6824 65.6584C52.799 67.1523 52.7981 69.3145 52.1952 72.0231C52.1551 72.0927 52.0794 72.3146 51.8724 72.3338C51.6801 72.352 51.4051 72.3625 51.1293 72.3216C50.8822 72.2859 50.709 72.0336 50.7534 71.7638C49.8581 68.0346 49.7597 65.44 49.8511 63.7207C49.8798 63.1899 49.9633 62.0544 49.7519 60.5422C49.5179 58.8672 49.0358 57.5377 48.6591 56.6676C49.8346 56.6015 51.0101 56.5345 52.1856 56.4684Z" fill="#FFB3AF" />
                <path d="M40.4412 74.958C41.6115 74.9258 42.2171 74.7875 42.5295 74.6595C42.5304 74.7761 42.5304 74.8945 42.526 75.0172C42.5225 75.1485 42.4251 75.253 42.3059 75.2521L35.8793 75.2103C35.6739 75.2094 35.5547 74.971 35.6435 74.7831C36.4857 74.8527 38.6323 75.0085 40.4412 74.958Z" fill="url(#paint11_linear_624_19647)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M40.4412 74.958C41.6115 74.9258 42.2171 74.7875 42.5295 74.6595C42.5304 74.7761 42.5304 74.8945 42.526 75.0172C42.5225 75.1485 42.4251 75.253 42.3059 75.2521L35.8793 75.2103C35.6739 75.2094 35.5547 74.971 35.6435 74.7831C36.4857 74.8527 38.6323 75.0085 40.4412 74.958Z" fill="url(#paint12_linear_624_19647)" />
                <path d="M35.6436 74.7831C35.6514 74.7675 35.6575 74.7501 35.6688 74.7353C36.2361 73.9418 37.6822 72.3634 40.5379 71.6778L41.9083 71.6351C41.9083 71.6351 42.5182 72.6949 42.5296 74.6596C42.2172 74.7875 41.6116 74.9258 40.4413 74.958C38.6324 75.0085 36.4858 74.8528 35.6436 74.7831Z" fill="url(#paint13_linear_624_19647)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M35.6436 74.7831C35.6514 74.7675 35.6575 74.7501 35.6688 74.7353C36.2361 73.9418 37.6822 72.3634 40.5379 71.6778L41.9083 71.6351C41.9083 71.6351 42.5182 72.6949 42.5296 74.6596C42.2172 74.7875 41.6116 74.9258 40.4413 74.958C38.6324 75.0085 36.4858 74.8528 35.6436 74.7831Z" fill="url(#paint14_linear_624_19647)" />
                <path d="M45.9878 55.0153C45.6868 56.5875 44.2807 59.1648 43.9796 60.7362C44.131 63.0976 43.9849 65.0788 43.79 66.5528C43.79 66.5528 43.4628 69.0308 41.8862 71.8142C41.8627 71.856 41.8131 72.0622 41.6347 72.084C41.4015 72.1118 41.0239 72.137 40.6933 72.0692C40.5158 72.0326 40.3861 71.8612 40.3861 71.6628L41.7539 55.9724L45.9878 55.0153Z" fill="url(#paint15_linear_624_19647)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M45.9878 55.0153C45.6868 56.5875 44.2807 59.1648 43.9796 60.7362C44.131 63.0976 43.9849 65.0788 43.79 66.5528C43.79 66.5528 43.4628 69.0308 41.8862 71.8142C41.8627 71.856 41.8131 72.0622 41.6347 72.084C41.4015 72.1118 41.0239 72.137 40.6933 72.0692C40.5158 72.0326 40.3861 71.8612 40.3861 71.6628L41.7539 55.9724L45.9878 55.0153Z" fill="#FFB3AF" />
                <path d="M51.7598 39.3821C51.7598 39.3821 53.7028 44.8837 52.4846 57.0642C52.4846 57.0642 48.9433 57.3078 48.2124 57.0642C48.2124 57.0642 48.2638 51.7531 47.5268 46.3002C47.4989 46.0949 47.1631 46.0818 47.1648 46.2889C47.2188 51.6626 46.7124 55.291 46.2877 56.9903C46.2877 56.9903 42.0834 57.1417 41.1089 56.6544C41.1089 56.6544 42.0155 44.4983 43.4773 39.8693L51.7598 39.3821Z" fill="url(#paint16_linear_624_19647)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M51.7593 39.3821C51.7593 39.3821 53.7023 44.8837 52.4841 57.0642C52.4841 57.0642 48.9428 57.3078 48.2119 57.0642C48.2119 57.0642 48.2633 51.7531 47.5263 46.3002C47.4984 46.0949 47.1626 46.0818 47.1643 46.2889C47.2183 51.6626 46.7119 55.291 46.2873 56.9903C46.2873 56.9903 42.0829 57.1417 41.1084 56.6544C41.1084 56.6544 42.015 44.4983 43.4768 39.8693L51.7593 39.3821Z" fill="url(#paint17_linear_624_19647)" />
                <path d="M55.776 16.6861C55.776 16.6861 55.5958 16.6385 54.6596 16.5354C51.7517 16.2153 45.1076 15.7381 39.579 17.2123L40.0662 24.7646C40.0662 24.7646 42.0153 35.2405 43.477 39.8694C43.477 39.8694 49.0796 40.8439 51.7596 39.3821C51.7596 39.3821 53.9522 26.2263 55.1704 24.2774C55.1704 24.2774 56.0196 19.1223 55.776 16.6861Z" fill="url(#paint18_linear_624_19647)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M55.776 16.6861C55.776 16.6861 55.5958 16.6385 54.6596 16.5354C51.7517 16.2153 45.1076 15.7381 39.579 17.2123L40.0662 24.7646C40.0662 24.7646 42.0153 35.2405 43.477 39.8694C43.477 39.8694 49.0796 40.8439 51.7596 39.3821C51.7596 39.3821 53.9522 26.2263 55.1704 24.2774C55.1704 24.2774 56.0196 19.1223 55.776 16.6861Z" fill="#DDBE90" />
                <path d="M49.7129 10.0684L50.6352 16.3051C50.6352 16.3051 48.0589 17.4887 46.2543 16.2543L46.6432 11.8528L49.7129 10.0684Z" fill="url(#paint19_linear_624_19647)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M49.7129 10.0684L50.6352 16.3051C50.6352 16.3051 48.0589 17.4887 46.2543 16.2543L46.6432 11.8528L49.7129 10.0684Z" fill="#FFB3AF" />
                <path d="M44.3662 7.25286C44.3522 7.31151 44.6698 10.4894 44.8673 12.456C44.9491 13.2685 45.7209 13.8275 46.5188 13.653L47.2497 13.4932C48.5923 13.1996 49.4902 11.9322 49.3223 10.5685L48.8855 7.02777C48.8855 7.02777 44.6977 5.83069 44.3662 7.25286Z" fill="url(#paint20_linear_624_19647)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M44.3662 7.25286C44.3522 7.31151 44.6698 10.4894 44.8673 12.456C44.9491 13.2685 45.7209 13.8275 46.5188 13.653L47.2497 13.4932C48.5923 13.1996 49.4902 11.9322 49.3223 10.5685L48.8855 7.02777C48.8855 7.02777 44.6977 5.83069 44.3662 7.25286Z" fill="#FFB3AF" />
                <path fillRule="evenodd" clipRule="evenodd" d="M45.8903 10.2494C45.9215 10.1691 46.0379 10.1782 46.0564 10.2623L46.2394 11.0951C46.2517 11.1512 46.2072 11.2037 46.1498 11.2007L45.6528 11.1746C45.5936 11.1714 45.5548 11.1113 45.5763 11.0561L45.8903 10.2494Z" fill="#EA8D74" />
                <path fillRule="evenodd" clipRule="evenodd" d="M46.9164 9.21962L47.3398 9.54503C47.4308 9.42602 47.4098 9.2567 47.2929 9.16689C47.1759 9.07698 47.0074 9.1006 46.9164 9.21962Z" fill="#132529" />
                <path fillRule="evenodd" clipRule="evenodd" d="M45.1079 9.02817L45.6246 8.86278C45.5825 8.72135 45.4328 8.64379 45.2901 8.68952C45.1474 8.73513 45.0659 8.88674 45.1079 9.02817Z" fill="#132529" />
                <path fillRule="evenodd" clipRule="evenodd" d="M46.5981 9.88504C46.5906 9.9976 46.6786 10.0946 46.7945 10.1018C46.9103 10.1091 47.0103 10.0236 47.0176 9.91107C47.0251 9.79852 46.9371 9.70138 46.8212 9.69427C46.7054 9.68705 46.6055 9.77248 46.5981 9.88504Z" fill="#132529" />
                <path fillRule="evenodd" clipRule="evenodd" d="M45.1063 9.57863C45.099 9.69119 45.1868 9.78832 45.3027 9.79543C45.4186 9.80265 45.5185 9.71733 45.5259 9.60477C45.5333 9.49222 45.4454 9.39508 45.3296 9.38787C45.2136 9.38076 45.1138 9.46607 45.1063 9.57863Z" fill="#132529" />
                <path d="M49.6631 6.8182C49.8415 6.39794 49.9259 5.77634 49.175 5.35905C48.4154 4.93644 47.7828 5.27325 47.4113 5.59136C47.2468 5.73214 47.0023 5.75903 46.8231 5.63782C46.743 5.58388 46.6403 5.56082 46.5133 5.61268C46.2723 5.71056 46.0043 5.60894 45.9051 5.36896C45.7346 4.95749 45.3665 4.5201 44.573 4.58657C43.1173 4.70847 42.818 6.28396 43.1156 7.24811C43.414 8.21218 44.9845 7.9904 45.7972 7.58745C46.395 7.29145 47.0963 7.69213 47.4261 7.92557C47.5461 8.01041 47.6201 8.14492 47.6262 8.29154L47.6723 9.2911C47.9055 9.54013 48.1378 9.79611 48.4206 9.98518C48.6233 10.1214 48.8191 10.1729 49.0088 10.1648C49.1315 9.61321 49.8284 9.3293 50.1599 9.22437C50.287 9.18426 50.3905 9.0942 50.4471 8.97395C50.6185 8.61347 50.8316 7.8564 49.8963 7.4351C49.664 7.33043 49.563 7.05278 49.6631 6.8182Z" fill="url(#paint21_linear_624_19647)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M49.6631 6.8182C49.8415 6.39794 49.9259 5.77634 49.175 5.35905C48.4154 4.93644 47.7828 5.27325 47.4113 5.59136C47.2468 5.73214 47.0023 5.75903 46.8231 5.63782C46.743 5.58388 46.6403 5.56082 46.5133 5.61268C46.2723 5.71056 46.0043 5.60894 45.9051 5.36896C45.7346 4.95749 45.3665 4.5201 44.573 4.58657C43.1173 4.70847 42.818 6.28396 43.1156 7.24811C43.414 8.21218 44.9845 7.9904 45.7972 7.58745C46.395 7.29145 47.0963 7.69213 47.4261 7.92557C47.5461 8.01041 47.6201 8.14492 47.6262 8.29154L47.6723 9.2911C47.9055 9.54013 48.1378 9.79611 48.4206 9.98518C48.6233 10.1214 48.8191 10.1729 49.0088 10.1648C49.1315 9.61321 49.8284 9.3293 50.1599 9.22437C50.287 9.18426 50.3905 9.0942 50.4471 8.97395C50.6185 8.61347 50.8316 7.8564 49.8963 7.4351C49.664 7.33043 49.563 7.05278 49.6631 6.8182Z" fill="url(#paint22_linear_624_19647)" />
                <path d="M49.0435 11.239C49.0435 11.239 48.5963 9.53677 49.2367 9.18473C49.8779 8.8326 50.7567 9.11999 50.4557 10.2992C50.0815 11.766 49.0435 11.239 49.0435 11.239Z" fill="url(#paint23_linear_624_19647)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M49.0435 11.239C49.0435 11.239 48.5963 9.53677 49.2367 9.18473C49.8779 8.8326 50.7567 9.11999 50.4557 10.2992C50.0815 11.766 49.0435 11.239 49.0435 11.239Z" fill="#FFB3AF" />
                <path d="M57.4377 39.0593C57.4377 39.0593 56.6616 41.7888 55.2563 41.5104C53.8502 41.2328 53.9077 39.7441 53.9364 38.9993C53.9651 38.2545 56.6676 37.7629 57.1088 37.9291C57.5499 38.0944 57.4377 39.0593 57.4377 39.0593Z" fill="url(#paint24_linear_624_19647)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M57.4377 39.0593C57.4377 39.0593 56.6616 41.7888 55.2563 41.5104C53.8502 41.2328 53.9077 39.7441 53.9364 38.9993C53.9651 38.2545 56.6676 37.7629 57.1088 37.9291C57.5499 38.0944 57.4377 39.0593 57.4377 39.0593Z" fill="#FFB3AF" />
                <path fillRule="evenodd" clipRule="evenodd" d="M44.4135 16.3749C44.4874 17.2819 44.5814 18.1825 44.7241 19.0763C44.7902 19.5237 44.8737 19.9694 44.9555 20.4089C45.0347 20.8509 45.1139 21.2936 45.2026 21.7351C45.381 22.6172 45.5681 23.5028 45.8831 24.3569C46.0423 24.7811 46.225 25.2066 46.5235 25.5712C46.6731 25.7504 46.8611 25.9124 47.0916 26.0006C47.3196 26.0944 47.5885 26.0815 47.8103 25.9988C48.2593 25.8324 48.6082 25.5236 48.9345 25.2086C49.2564 24.8858 49.5358 24.5234 49.7811 24.1417C50.2693 23.3745 50.6138 22.5281 50.8844 21.6662C51.1498 20.8011 51.3412 19.9157 51.4822 19.0225C51.631 18.1299 51.711 17.1991 51.7719 16.2944L51.2368 16.2665C51.2768 18.0398 51.128 19.8825 50.6556 21.5988C50.4198 22.455 50.09 23.2896 49.6245 24.0434C49.3931 24.4202 49.1225 24.773 48.8092 25.0836C48.496 25.3879 48.1505 25.686 47.752 25.8313C47.5545 25.9019 47.3448 25.9139 47.156 25.8322C46.9681 25.7565 46.8054 25.6156 46.6714 25.4498C46.4043 25.1131 46.2311 24.6995 46.0841 24.285C45.803 23.4451 45.6394 22.5643 45.5046 21.6824C45.3775 20.8003 45.2601 19.9053 45.1826 19.0218C45.1121 18.135 45.0843 17.2083 45.1226 16.3224L44.4135 16.3749Z" fill="#100774" />
                <path d="M47.4469 25.7323C47.4469 26.1361 47.1197 26.4634 46.716 26.4634C46.3114 26.4634 45.9843 26.1361 45.9843 25.7323C45.9843 25.3286 46.3114 25.0012 46.716 25.0012C47.1197 25.0012 47.4469 25.3286 47.4469 25.7323Z" fill="url(#paint25_linear_624_19647)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M47.4469 25.7323C47.4469 26.1361 47.1197 26.4634 46.716 26.4634C46.3114 26.4634 45.9843 26.1361 45.9843 25.7323C45.9843 25.3286 46.3114 25.0012 46.716 25.0012C47.1197 25.0012 47.4469 25.3286 47.4469 25.7323Z" fill="url(#paint26_linear_624_19647)" />
                <path d="M46.8341 25.0112L47.8773 25.2685L47.6337 26.7173L46.4817 26.4255L46.8341 25.0112Z" fill="url(#paint27_linear_624_19647)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M46.8341 25.0112L47.8773 25.2685L47.6337 26.7173L46.4817 26.4255L46.8341 25.0112Z" fill="url(#paint28_linear_624_19647)" />
                <path d="M48.2807 26.0025C48.2807 26.4151 48.0579 26.7497 47.7838 26.7497C47.5089 26.7497 47.2861 26.4151 47.2861 26.0025C47.2861 25.5898 47.5089 25.2551 47.7838 25.2551C48.0579 25.2551 48.2807 25.5898 48.2807 26.0025Z" fill="url(#paint29_linear_624_19647)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M48.2807 26.0025C48.2807 26.4151 48.0579 26.7497 47.7838 26.7497C47.5089 26.7497 47.2861 26.4151 47.2861 26.0025C47.2861 25.5898 47.5089 25.2551 47.7838 25.2551C48.0579 25.2551 48.2807 25.5898 48.2807 26.0025Z" fill="url(#paint30_linear_624_19647)" />
                <path d="M45.642 27.6393L46.1962 26.0818L47.2873 26.0024C47.296 26.081 47.3117 26.1739 47.3404 26.276C47.3717 26.3848 47.4109 26.4769 47.4474 26.5511C47.263 26.9961 47.0785 27.4411 46.894 27.8859L45.642 27.6393Z" fill="url(#paint31_linear_624_19647)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M45.642 27.6393L46.1962 26.0818L47.2873 26.0024C47.296 26.081 47.3117 26.1739 47.3404 26.276C47.3717 26.3848 47.4109 26.4769 47.4474 26.5511C47.263 26.9961 47.0785 27.4411 46.894 27.8859L45.642 27.6393Z" fill="url(#paint32_linear_624_19647)" />
                <path d="M45.4471 27.3983L45.9866 25.6622L47.2857 25.9693L46.6992 27.6332L45.4471 27.3983Z" fill="url(#paint33_linear_624_19647)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M45.4471 27.3983L45.9866 25.6622L47.2857 25.9693L46.6992 27.6332L45.4471 27.3983Z" fill="url(#paint34_linear_624_19647)" />
                <mask id="mask2_624_19647" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="45" y="27" width="2" height="1">
                    <path d="M45.4471 27.3983L46.6992 27.6332L46.8924 27.8859L45.6403 27.6392L45.4471 27.3983Z" fill="url(#paint35_linear_624_19647)" />
                </mask>
                <g mask="url(#mask2_624_19647)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M45.4471 27.3983L46.6992 27.6332L46.8924 27.8859L45.6403 27.6392L45.4471 27.3983Z" fill="url(#paint36_linear_624_19647)" />
                </g>
                <path fillRule="evenodd" clipRule="evenodd" d="M44.8261 12.724C44.849 12.497 45.0284 12.2377 45.2271 12.145C45.325 12.0992 45.4092 12.1029 45.4666 12.1452L45.4669 12.1449L45.469 12.1466L45.4694 12.1468L45.7926 12.3938L45.2212 13.2091L44.9023 12.9656L44.902 12.9655L44.9011 12.9647L44.9013 12.9646C44.8443 12.9204 44.8147 12.8369 44.8261 12.724Z" fill="#334F55" />
                <path fillRule="evenodd" clipRule="evenodd" d="M45.5456 12.3884C45.347 12.4813 45.1674 12.7406 45.1446 12.9676C45.1218 13.1946 45.2643 13.3034 45.4629 13.2107C45.6615 13.1179 45.8411 12.8587 45.8639 12.6316C45.8867 12.4046 45.7443 12.2957 45.5456 12.3884Z" fill="#253C40" />
                <path fillRule="evenodd" clipRule="evenodd" d="M46.2651 12.0519L46.2533 12.1698C46.2485 12.2183 46.2101 12.2736 46.1677 12.2934L45.5252 12.5935L45.5457 12.3878L46.2651 12.0519Z" fill="#253C40" />
                <path fillRule="evenodd" clipRule="evenodd" d="M45.9468 11.8088L46.2651 12.0519L45.5457 12.3879L45.2274 12.1448L45.9468 11.8088Z" fill="#334F55" />
                <path fillRule="evenodd" clipRule="evenodd" d="M44.5996 12.4015L44.6002 12.4001C44.6002 12.4001 44.5578 12.3695 44.494 12.3237C44.4297 12.2789 44.3437 12.2193 44.2581 12.1597C44.1722 12.1001 44.0873 12.0388 44.0202 11.9993C44.0033 11.9896 43.9877 11.9809 43.9741 11.973C43.9598 11.9659 43.9488 11.9572 43.9369 11.9554C43.9343 11.955 43.9316 11.9547 43.9291 11.9547C43.9085 11.9547 43.89 11.9674 43.89 11.9674L43.8801 11.9859C43.8801 11.9859 43.8799 11.9863 43.8799 11.987C43.8799 11.9923 43.8807 12.0173 43.8945 12.0343C43.9021 12.0441 43.9148 12.0496 43.9278 12.0586C43.9415 12.067 43.9566 12.0762 43.9733 12.0865C43.9886 12.0956 44.0053 12.105 44.023 12.1146C44.083 12.1471 44.1551 12.1819 44.2269 12.2177C44.3197 12.2641 44.4126 12.3106 44.4823 12.3453C44.5527 12.379 44.5996 12.4015 44.5996 12.4015Z" fill="#A4A6A3" />
                <path fillRule="evenodd" clipRule="evenodd" d="M43.7949 12.939C43.8043 12.939 43.8146 12.9365 43.8263 12.9357C43.8418 12.9334 43.8592 12.931 43.8783 12.9285C43.9545 12.917 44.0547 12.8919 44.1552 12.8688C44.2557 12.8456 44.3563 12.8225 44.4317 12.8051C44.5067 12.7864 44.5569 12.7739 44.5569 12.7739L44.5568 12.7723C44.5568 12.7723 44.5053 12.7757 44.4281 12.7804C44.3512 12.7865 44.2486 12.7948 44.146 12.8029C44.0434 12.8111 43.9406 12.8173 43.8645 12.8293C43.8455 12.8326 43.828 12.8356 43.8126 12.8383C43.7973 12.8417 43.7835 12.842 43.7731 12.8482C43.7521 12.8603 43.7431 12.888 43.7431 12.888L43.7459 12.909C43.7459 12.909 43.7622 12.9328 43.7856 12.9379C43.7886 12.9387 43.7918 12.939 43.7949 12.939Z" fill="#A4A6A3" />
                <path fillRule="evenodd" clipRule="evenodd" d="M44.1 13.7196C44.1074 13.7196 44.1154 13.7185 44.123 13.7151C44.1341 13.7103 44.1427 13.699 44.1549 13.6884C44.1664 13.6772 44.1794 13.6646 44.1934 13.651C44.2491 13.5952 44.3171 13.5139 44.3862 13.4339C44.4554 13.3538 44.5245 13.2739 44.5765 13.2139C44.6274 13.1529 44.6615 13.1123 44.6615 13.1123L44.6604 13.1111C44.6604 13.1111 44.6203 13.1451 44.56 13.1959C44.5008 13.2476 44.4216 13.3166 44.3425 13.3857C44.2634 13.4547 44.1829 13.5224 44.1273 13.5785C44.1138 13.5927 44.1012 13.6057 44.0902 13.6173C44.0796 13.6295 44.0684 13.6381 44.0634 13.6495C44.0592 13.6585 44.058 13.6684 44.058 13.677C44.0578 13.6901 44.0607 13.7002 44.0607 13.7002L44.0746 13.7156C44.0746 13.7156 44.086 13.7196 44.1 13.7196Z" fill="#A4A6A3" />
                <path fillRule="evenodd" clipRule="evenodd" d="M45.3957 12.1147L45.6128 12.2803L45.4901 12.3377L45.2732 12.1719L45.3957 12.1147Z" fill="#253C40" />
                <path fillRule="evenodd" clipRule="evenodd" d="M45.6128 12.2803L45.3957 12.1147L45.3912 12.1604L45.5727 12.299L45.6128 12.2803Z" fill="#132529" />
                <path fillRule="evenodd" clipRule="evenodd" d="M45.3902 12.1605L45.3076 12.1991L45.2722 12.1721L45.3948 12.1149L45.3902 12.1605Z" fill="#000105" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_624_19647" x1="-15603.6" y1="718.24" x2="3156.93" y2="718.24" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#B5B6F7" />
                    <stop offset="1" stopColor="#BEC0FF" />
                </linearGradient>
                <linearGradient id="paint1_linear_624_19647" x1="-15603.6" y1="718.24" x2="3156.93" y2="718.24" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#B5B6F7" />
                    <stop offset="1" stopColor="#BEC0FF" />
                </linearGradient>
                <linearGradient id="paint2_linear_624_19647" x1="-1096.07" y1="1928.74" x2="-1362.72" y2="3038.58" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4042E1" />
                    <stop offset="1" stopColor="#4F52FF" />
                </linearGradient>
                <linearGradient id="paint3_linear_624_19647" x1="-428.398" y1="75.8173" x2="1444.17" y2="1248.25" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A12B24" />
                    <stop offset="1" stopColor="#C0423B" />
                </linearGradient>
                <linearGradient id="paint4_linear_624_19647" x1="-108.657" y1="-101.611" x2="363.8" y2="556.738" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A12B24" />
                    <stop offset="1" stopColor="#C0423B" />
                </linearGradient>
                <linearGradient id="paint5_linear_624_19647" x1="248.062" y1="2217.94" x2="-1767.71" y2="3833.16" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4042E1" />
                    <stop offset="1" stopColor="#4F52FF" />
                </linearGradient>
                <linearGradient id="paint6_linear_624_19647" x1="49.7824" y1="252.747" x2="539.119" y2="252.747" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#08005D" />
                    <stop offset="1" stopColor="#1A0F91" />
                </linearGradient>
                <linearGradient id="paint7_linear_624_19647" x1="49.7824" y1="252.747" x2="539.119" y2="252.747" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#08005D" />
                    <stop offset="1" stopColor="#1A0F91" />
                </linearGradient>
                <linearGradient id="paint8_linear_624_19647" x1="293.12" y1="-1167.54" x2="293.12" y2="-10.3185" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4042E1" />
                    <stop offset="1" stopColor="#4F52FF" />
                </linearGradient>
                <linearGradient id="paint9_linear_624_19647" x1="293.12" y1="-1167.54" x2="293.12" y2="-10.3185" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4042E1" />
                    <stop offset="1" stopColor="#4F52FF" />
                </linearGradient>
                <linearGradient id="paint10_linear_624_19647" x1="241.211" y1="-1225.36" x2="812.083" y2="3756.85" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A12B24" />
                    <stop offset="1" stopColor="#C0423B" />
                </linearGradient>
                <linearGradient id="paint11_linear_624_19647" x1="381.407" y1="-1146.53" x2="381.407" y2="10.7164" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4042E1" />
                    <stop offset="1" stopColor="#4F52FF" />
                </linearGradient>
                <linearGradient id="paint12_linear_624_19647" x1="381.407" y1="-1146.53" x2="381.407" y2="10.7164" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4042E1" />
                    <stop offset="1" stopColor="#4F52FF" />
                </linearGradient>
                <linearGradient id="paint13_linear_624_19647" x1="724.244" y1="238.26" x2="35.5888" y2="238.26" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#08005D" />
                    <stop offset="1" stopColor="#1A0F91" />
                </linearGradient>
                <linearGradient id="paint14_linear_624_19647" x1="724.244" y1="238.26" x2="35.5888" y2="238.26" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#08005D" />
                    <stop offset="1" stopColor="#1A0F91" />
                </linearGradient>
                <linearGradient id="paint15_linear_624_19647" x1="306.92" y1="-213.236" x2="805.626" y2="4139.15" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A12B24" />
                    <stop offset="1" stopColor="#C0423B" />
                </linearGradient>
                <linearGradient id="paint16_linear_624_19647" x1="1194.16" y1="-111.928" x2="-455.5" y2="1358.43" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#08005D" />
                    <stop offset="1" stopColor="#1A0F91" />
                </linearGradient>
                <linearGradient id="paint17_linear_624_19647" x1="1194.16" y1="-111.928" x2="-455.5" y2="1358.43" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#08005D" />
                    <stop offset="1" stopColor="#1A0F91" />
                </linearGradient>
                <linearGradient id="paint18_linear_624_19647" x1="450.068" y1="1765.31" x2="-2279.06" y2="3952.13" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4042E1" />
                    <stop offset="1" stopColor="#4F52FF" />
                </linearGradient>
                <linearGradient id="paint19_linear_624_19647" x1="218.05" y1="-104.793" x2="406.852" y2="1108.11" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A12B24" />
                    <stop offset="1" stopColor="#C0423B" />
                </linearGradient>
                <linearGradient id="paint20_linear_624_19647" x1="215.873" y1="5421.1" x2="242.939" y2="4540.33" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A12B24" />
                    <stop offset="1" stopColor="#C0423B" />
                </linearGradient>
                <linearGradient id="paint21_linear_624_19647" x1="308.337" y1="1068.74" x2="381.448" y2="142.66" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#08005D" />
                    <stop offset="1" stopColor="#1A0F91" />
                </linearGradient>
                <linearGradient id="paint22_linear_624_19647" x1="308.337" y1="1068.74" x2="381.448" y2="142.66" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#08005D" />
                    <stop offset="1" stopColor="#1A0F91" />
                </linearGradient>
                <linearGradient id="paint23_linear_624_19647" x1="51.3196" y1="5183.72" x2="78.3869" y2="4302.93" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A12B24" />
                    <stop offset="1" stopColor="#C0423B" />
                </linearGradient>
                <linearGradient id="paint24_linear_624_19647" x1="14.3495" y1="476.965" x2="520.926" y2="-181.603" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A12B24" />
                    <stop offset="1" stopColor="#C0423B" />
                </linearGradient>
                <linearGradient id="paint25_linear_624_19647" x1="26.668" y1="292.449" x2="178.754" y2="-27.3225" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FD6F61" />
                    <stop offset="1" stopColor="#FF928E" />
                </linearGradient>
                <linearGradient id="paint26_linear_624_19647" x1="26.668" y1="292.449" x2="178.754" y2="-27.3225" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FD6F61" />
                    <stop offset="1" stopColor="#FF928E" />
                </linearGradient>
                <linearGradient id="paint27_linear_624_19647" x1="55.069" y1="314.006" x2="197.744" y2="14.0206" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FD6F61" />
                    <stop offset="1" stopColor="#FF928E" />
                </linearGradient>
                <linearGradient id="paint28_linear_624_19647" x1="55.069" y1="314.006" x2="197.744" y2="14.0206" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FD6F61" />
                    <stop offset="1" stopColor="#FF928E" />
                </linearGradient>
                <linearGradient id="paint29_linear_624_19647" x1="97.0552" y1="-475.459" x2="97.0552" y2="-201.378" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FD6F61" />
                    <stop offset="1" stopColor="#FF928E" />
                </linearGradient>
                <linearGradient id="paint30_linear_624_19647" x1="97.0552" y1="-475.459" x2="97.0552" y2="-201.378" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FD6F61" />
                    <stop offset="1" stopColor="#FF928E" />
                </linearGradient>
                <linearGradient id="paint31_linear_624_19647" x1="135.871" y1="-549.445" x2="135.871" y2="-275.364" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FD6F61" />
                    <stop offset="1" stopColor="#FF928E" />
                </linearGradient>
                <linearGradient id="paint32_linear_624_19647" x1="135.871" y1="-549.445" x2="135.871" y2="-275.364" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FD6F61" />
                    <stop offset="1" stopColor="#FF928E" />
                </linearGradient>
                <linearGradient id="paint33_linear_624_19647" x1="89.6388" y1="234.581" x2="252.918" y2="-108.726" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FD6F61" />
                    <stop offset="1" stopColor="#FF928E" />
                </linearGradient>
                <linearGradient id="paint34_linear_624_19647" x1="89.6388" y1="234.581" x2="252.918" y2="-108.726" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FD6F61" />
                    <stop offset="1" stopColor="#FF928E" />
                </linearGradient>
                <linearGradient id="paint35_linear_624_19647" x1="121.059" y1="75.5678" x2="122.051" y2="137.099" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FD6F61" />
                    <stop offset="1" stopColor="#FF928E" />
                </linearGradient>
                <linearGradient id="paint36_linear_624_19647" x1="121.059" y1="75.5678" x2="122.051" y2="137.099" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FD6F61" />
                    <stop offset="1" stopColor="#FF928E" />
                </linearGradient>
                <clipPath id="clip0_624_19647">
                    <rect width="46.8116" height="71" fill="white" transform="translate(16.5942 4.5)" />
                </clipPath>
            </defs>
        </svg>

    )
}