import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { ENDPOINTS, REST_API } from "../../const/constants";
import axios from "axios";


type setPhotoInitialStateErrorType = {
    detail: string
}

interface IVIdeo {
    id: number,
    upload_link: string,
    video: string,
    uploaded: boolean,
    youtube_id: string,
    tik_tok_public: boolean,
    boarding_marketing: boolean
}

interface ISetPhoto {
    id: string,
    host: string,
    video: IVIdeo
}

export type setPhotoInitialStateType = {
    loading: boolean,
    data: ISetPhoto,
    success: boolean,
    getSuccess: boolean,
    error: boolean,
    errors: setPhotoInitialStateErrorType,
}


const initialState: setPhotoInitialStateType = {
  loading: false,
  data: {} as ISetPhoto,
  success: false,
  getSuccess: false,
  error: false,
  errors: {} as setPhotoInitialStateErrorType,
};

interface IExtension {
  extension: string
}

export type IsetPhotoReq = {
    token: string | null,
    data?: FormData
}

export const setPhoto = createAsyncThunk(
  "setPhoto",
  async (req: IsetPhotoReq, { rejectWithValue }) => {
    const {token, data} = req
    const url = REST_API + ENDPOINTS.user.photo;
    const headers = {
        Authorization: `Bearer ${token}`,
      }; 
    try {
      const response = await axios.post(url, data, {headers: headers});
      return response.data;
    } catch (err) {
      const error: any = err;
      return rejectWithValue(error.response);
    }
  }
);


export const setPhotoSlice = createSlice({
  name: "setPhoto",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(setPhoto.pending, (state) => {
        state.loading = true;
        state.success = false;
      })
      .addCase(setPhoto.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload
        state.error = false;
        state.success = true;
      })
      .addCase(setPhoto.rejected, (state, { payload }) => {
        state.loading = false;
        state.success = false;
        state.error = true;
      })
  },
});

export const SetPhoto = (state: RootState) => state.setPhoto;

export const SetPhotoReducer = setPhotoSlice.reducer;
