import { FC } from 'react'
import s from './success.module.scss'
import { PoluchilosIcon } from '../../../icons/poluchilos-icon'

interface ISuccess {
    title?: string,
    heading?: string
}

export const Success: FC<ISuccess> = ({ title, heading }) => {
    return (
        <div className={s.modalSuccess}>
            <div className={s.successModalIcon}><PoluchilosIcon /></div>
            <div className={s.successModalHeading}>{heading}</div>
            {!!title && <div className={s.successModalTitle}>&laquo;{title}&raquo;</div>}
        </div>
    )
}