
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import axios from "axios";
import { ENDPOINTS, REST_API } from "../../const/constants";

interface IUpdateReward {
    id: number;
    title: string;
    user: string;
}

interface UpdateRewardInitialStateType {
    loading: boolean,
    data: IUpdateReward
    success: boolean,
    error: boolean,
    errors: any,
}

const initialState: UpdateRewardInitialStateType = {
  loading: false,
  data: {} as IUpdateReward,
  success: false,
  error: false,
  errors: {},
};

interface IUpdateRewardReqData {
    start_day?: number | null
    start_month?: number | null
    start_year?: number | null
    end_day?: number | null
    end_month?: number | null
    end_year?: number | null
    year?: number | null
    sport?: string | number | null
    distance?: number | null
    disciplines?:string | null
    result?: string | null
    category?: string | null
    title?: string | null
    note?: string | null
    age?: number | null
    gender?: string | null
    significance?: string | null
    protocol?: string | null
    team?: string | null
    organizer?: string | null
    teacher?: string | null
    institution?: string | null
    kind_activity?: string | null
    country?: string | number | null,
    city_name?: string| null,
    additional_criteria?:string| null
  }

interface IEditRewardReq {
    token: string | null,
    id: number | string | undefined,
    data: IUpdateRewardReqData
}

export const updateReward = createAsyncThunk(
  "updateReward",
  async (req: IEditRewardReq, { rejectWithValue }) => {
    const { token, id, data } = req;
    const url = REST_API + ENDPOINTS.rewards.rewards + id + '/'
    const headers = {
        Authorization: `Bearer ${token}`,
      };
    try {
      const response = await axios.post(url, { ...data }, {headers: headers });
      return response.data;
    } catch (err) {
      const error: any = err;
      return rejectWithValue(error.response);
    }
  }
);

interface IDeleteRewardReq {
  token: string | null,
  id: number | string | undefined
}

export const deleteReward = createAsyncThunk(
  "deleteReward",
  async (req: IDeleteRewardReq, { rejectWithValue }) => {
    const { token, id} = req;
    const url = REST_API + ENDPOINTS.rewards.rewards + id + '/delete/'
    const headers = {
        Authorization: `Bearer ${token}`,
      };
    try {
      const response = await axios.post(url, {}, {headers: headers });
      return response.data;
    } catch (err) {
      const error: any = err;
      return rejectWithValue(error.response);
    }
  }
);

export const updateRewardSlice = createSlice({
  name: "updateReward",
  initialState,
  reducers: {
    updateRewardAction: () => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateReward.pending, (state:any) => {
        state.loading = true;
        state.success = false;
        state.errors.email = "";
      })
      .addCase(updateReward.fulfilled, (state:any, { payload }:any) => {
        state.loading = false;
        state.data = payload
        state.error = false;
        state.success = true;
      })
      .addCase(updateReward.rejected, (state:any, { payload }:any) => {
        const payl: any = payload;
        state.loading = false;
        state.success = false;
        state.error = true;
      });
  },
});

export const { updateRewardAction } = updateRewardSlice.actions;

export const UpdateReward = (state: RootState) => state.updateReward;

export const UpdateRewardReducer = updateRewardSlice.reducer;
