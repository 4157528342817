import { FC } from "react"

interface IIcon {
    fill?: string;
    stroke?: string;
    size?: number
}

export const CloseXIcon: FC<IIcon> = ({ fill = "#818181" }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.2474 10L14.739 6.50842C14.9047 6.343 14.9979 6.11853 14.9981 5.88439C14.9983 5.65025 14.9055 5.42562 14.7401 5.25991C14.5747 5.0942 14.3502 5.00099 14.1161 5.00078C13.8819 5.00057 13.6573 5.09339 13.4916 5.2588L10 8.75038L6.50842 5.2588C6.34271 5.09309 6.11796 5 5.88361 5C5.64926 5 5.42451 5.09309 5.2588 5.2588C5.09309 5.42451 5 5.64926 5 5.88361C5 6.11796 5.09309 6.34271 5.2588 6.50842L8.75038 10L5.2588 13.4916C5.09309 13.6573 5 13.882 5 14.1164C5 14.3507 5.09309 14.5755 5.2588 14.7412C5.42451 14.9069 5.64926 15 5.88361 15C6.11796 15 6.34271 14.9069 6.50842 14.7412L10 11.2496L13.4916 14.7412C13.6573 14.9069 13.882 15 14.1164 15C14.3507 15 14.5755 14.9069 14.7412 14.7412C14.9069 14.5755 15 14.3507 15 14.1164C15 13.882 14.9069 13.6573 14.7412 13.4916L11.2474 10Z" fill={fill} />
        </svg>

    )
}