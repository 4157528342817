import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"
import { ENDPOINTS, REST_API } from "../../const/constants"
import axios from "axios"

export interface IRelatedUser{
    id: string
    username: string
    profile: Profile
    level_display: string
    level: number
}

interface ILocation {
    id: number,
    name: string
}

interface Profile {
    id: number
    full_name: string
    middle_name: string
    first_name: string
    last_name: string
    gender: string
    birth_date: string
    image: string
    bio: string
    country: ILocation
    city: ILocation
  }

interface initialStateErrorType {
    detail?: string,
    code?: string,
    messages?: string[]
}

interface meInitialStateType {
    loading: boolean,
    data: IRelatedUser[],
    success: boolean,
    error: boolean,
    errors: initialStateErrorType,
}

const initialState: meInitialStateType = {
    loading: false,
    data: [] as IRelatedUser[],
    success: false,
    error: false,
    errors: {} as initialStateErrorType,
};



export interface IMeReq {
    token: string | null;
}

export const getRelatedUsers = createAsyncThunk(
    "getRelatedUsers",
    async (req: IMeReq, { rejectWithValue }) => {
        const { token } = req;
        const url = REST_API + ENDPOINTS.user.related
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        try {
            const response = await axios.get(url, { headers: headers });
            return response.data;
        } catch (err) {
            const error: any = err;
            return rejectWithValue(error.response);
        }
    }
);

export const getRelatedUsersSlice = createSlice({
    name: "getRelatedUsers",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRelatedUsers.pending, (state: any) => {
                state.loading = true;
                state.success = false;
                state.checkpoint = true;
            })
            .addCase(getRelatedUsers.fulfilled, (state: any, { payload }: any) => {
                state.loading = false;
                state.data = payload.results;
                state.error = false;
                state.success = true;
            })
            .addCase(getRelatedUsers.rejected, (state, { error, payload }) => {
                const payl: any = payload;
                state.loading = false;
                state.success = false;
                state.error = true;
            })
    },
});

export const GetRelatedUsers = (state: RootState) => state.getRelatedUsers;

export const GetRelatedUsersReducer = getRelatedUsersSlice.reducer;
