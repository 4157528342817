import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"
import { ENDPOINTS, REST_API } from "../../const/constants"
import axios from "axios"

export interface ISupscription {
    id: number
    owner: string
    current_type: CurrentType
    next_type: any
    next_payment: any
    expire_at: string
    status: string
    available_free: boolean
}

interface CurrentType {
    id: string
    title: string
    price: number
    duration: number
    num_users: number
}

interface initialStateErrorType {
    detail?: string,
    code?: string,
    messages?: string[]
}

interface meInitialStateType {
    loading: boolean,
    data: ISupscription[],
    success: boolean,
    error: boolean,
    errors: initialStateErrorType,
}

const initialState: meInitialStateType = {
    loading: false,
    data: [] as ISupscription[],
    success: false,
    error: false,
    errors: {} as initialStateErrorType,
};



export interface IMeReq {
    token: string | null;
}

export const getSubscription = createAsyncThunk(
    "getSubscription",
    async (req: IMeReq, { rejectWithValue }) => {
        const { token } = req;
        const url = REST_API + ENDPOINTS.subscriptions.list
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        try {
            const response = await axios.get(url, { headers: headers });
            return response.data;
        } catch (err) {
            const error: any = err;
            return rejectWithValue(error.response);
        }
    }
);

export const getSubscriptionSlice = createSlice({
    name: "getSubscription",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSubscription.pending, (state: any) => {
                state.loading = true;
                state.success = false;
                state.checkpoint = true;
            })
            .addCase(getSubscription.fulfilled, (state: any, { payload }: any) => {
                state.loading = false;
                state.data = payload.results;
                state.error = false;
                state.success = true;
            })
            .addCase(getSubscription.rejected, (state, { error, payload }) => {
                const payl: any = payload;
                state.loading = false;
                state.success = false;
                state.error = true;
            })
    },
});

export const GetSubscription = (state: RootState) => state.getSubscription;

export const GetSubscriptionReducer = getSubscriptionSlice.reducer;
