import {
  Action,
  configureStore,
  getDefaultMiddleware,
  ThunkAction,
} from "@reduxjs/toolkit";
import { TestReducer } from "./slice/testSlice";
import { CreateUserReducer } from "./slice/registrationSlice";
import { UpdateUserReducer } from "./slice/userUpdateSlice";
import { AuthUserReducer } from "./slice/tokenSlice";
import { MeReducer } from "./slice/meSlice";
import { SetPhotoReducer } from "./slice/setUserPhotoSlice";
import { GetSportsReducer } from "./slice/getSportsSlice";
import { AddRewardReducer } from "./slice/addRewardSlice";
import { GetCountriesReducer } from "./slice/getCountriesSlice";
import { UpdateRewardReducer } from "./slice/updateRewardSlice";
import { FreeSubscriptionReducer } from "./slice/setFreeSubscription";
import { GetRewardReducer } from "./slice/getRewardSlice";
import { GetDistancesReducer } from "./slice/getDistancesSlice";
import { GetRewardsReducer } from "./slice/getRewardsSlice";
import { GetUserRewardsReducer } from "./slice/getUserRewardsSlice";
import { NewPasswordReducer } from "./slice/newPasswordSlice";
import { CreateAlbumReducer } from "./slice/createAlbumSlice";
import { GetAlbumsReducer } from "./slice/getAlbumsSlice";
import { GetAlbumReducer } from "./slice/getAlbumById";
import { ResendEmailReducer } from "./slice/resendEmailSlice";
import { ResetPasswordReducer } from "./slice/resetPasswordSlice";
import { ResetSetNewPasswordReducer } from "./slice/resetSetNwePassword";
import { GetSubscriptionTypesReducer } from "./slice/getSubscriptionTypes";
import { GetSubscriptionReducer } from "./slice/getSubscription";
import { GetRelatedUsersReducer } from "./slice/getRelatedUsers";
import { StaticReducer } from "./slice/staticSlice";
import { CreateRelatedReducer } from "./slice/registerRelatedSlice";
import { UpdateRelatedReducer } from "./slice/updateRelated";
import { SetRelatedPhotoReducer } from "./slice/setRelatedPhotoSlice";
import { AddAppealReducer } from "./slice/appealSlice";
import { GetUserReducer } from "./slice/getUserSlice";

export const store = configureStore({
  reducer: {
    test: TestReducer,
    createUser: CreateUserReducer,
    updateUser: UpdateUserReducer,
    authUser: AuthUserReducer,
    me: MeReducer,
    setPhoto: SetPhotoReducer,
    getSports: GetSportsReducer,
    addReward: AddRewardReducer,
    getCountries: GetCountriesReducer,
    updateReward: UpdateRewardReducer,
    freeSubscription: FreeSubscriptionReducer,
    getReward: GetRewardReducer,
    getDistances: GetDistancesReducer,
    getRewards: GetRewardsReducer,
    getUserRewards: GetUserRewardsReducer,
    newPassword: NewPasswordReducer,
    createAlbum: CreateAlbumReducer,
    getAlbums: GetAlbumsReducer,
    getAlbum: GetAlbumReducer,
    resendEmail: ResendEmailReducer,
    resetPassword: ResetPasswordReducer,
    resetSetNewPassword: ResetSetNewPasswordReducer,
    getSubscriptionTypes: GetSubscriptionTypesReducer,
    getSubscription: GetSubscriptionReducer,
    getRelatedUsers: GetRelatedUsersReducer,
    static: StaticReducer,
    createRelated: CreateRelatedReducer,
    updateRelated: UpdateRelatedReducer,
    setRelatedPhoto: SetRelatedPhotoReducer,
    addAppeal: AddAppealReducer,
    getUser: GetUserReducer
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
