import { FC } from "react"

interface IIcon {
    fill?: string;
    stroke?: string;
}

export const AtIcon: FC<IIcon> = ({ fill = "#DDBE90" }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 4C7.584 4 4 7.584 4 12C4 16.416 7.584 20 12 20H16V18.4H12C8.528 18.4 5.6 15.472 5.6 12C5.6 8.528 8.528 5.6 12 5.6C15.472 5.6 18.4 8.528 18.4 12V13.144C18.4 13.776 17.832 14.4 17.2 14.4C16.568 14.4 16 13.776 16 13.144V12C16 9.792 14.208 8 12 8C9.792 8 8 9.792 8 12C8 14.208 9.792 16 12 16C13.104 16 14.112 15.552 14.832 14.824C15.352 15.536 16.248 16 17.2 16C18.776 16 20 14.72 20 13.144V12C20 7.584 16.416 4 12 4ZM12 14.4C10.672 14.4 9.6 13.328 9.6 12C9.6 10.672 10.672 9.6 12 9.6C13.328 9.6 14.4 10.672 14.4 12C14.4 13.328 13.328 14.4 12 14.4Z" fill={fill} />
        </svg>

    )
}