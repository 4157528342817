
import { PersonallyImg } from '../../icons/personally-img'
import s from './auth.module.scss'
import { FC } from "react"
import { ChildrenImg } from '../../icons/childern-img'
import { TeacherImg } from '../../icons/tescher-img'
import { useAppDispatch } from '../../store/hooks'
import { updateUser } from '../../store/slice/userUpdateSlice'
import { access } from 'fs'

interface IRegisterCategories {
    id: string
}

export const RegisterCategories: FC<IRegisterCategories> = ({ id }) => {
    const dispatch = useAppDispatch()

    const onCategoryChange = (data:any) => {
        const _token = localStorage.getItem("access")
        _token && _token !== null && id && dispatch(updateUser({
            token: localStorage.getItem("access"),
            id: id,
            data: {
                category: data.target.value
            }
        }))
    }

    return (<>
        <label className={s.categoryLabel}>
        <PersonallyImg />
            <span className={s.categoryTitle}>Персонально</span>
            <input className={s.categoryInput} style={{display: "none"}} onChange={onCategoryChange} type="radio" name="category[]" value="personally" />
            <span></span>
        </label>
        <label className={s.categoryLabel}>
            <ChildrenImg />
            <span className={s.categoryTitle}>Детям</span>
            <input className={s.categoryInput} style={{display: "none"}} onChange={onCategoryChange} type="radio" name="category[]" value="children" />
            <span></span>
        </label>
        <label className={s.categoryLabel}>
            <TeacherImg />
            <span className={s.categoryTitle}>Я тренер / педагог</span>
            <input className={s.categoryInput} style={{display: "none"}} onChange={onCategoryChange} type="radio" name="category[]" value="teacher" />
            <span></span>
        </label>
    </>)
}