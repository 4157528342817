
import { Skeleton, Space, message } from 'antd'
import s from './auth.module.scss'

import { useAppDispatch } from '../../store/hooks'
import { useEffect } from 'react'
import { oAuthUser } from '../../store/slice/tokenSlice'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export const OAuth = () => {
    const [searchParams] = useSearchParams()
    const code = searchParams.get('code')

    const dispatch = useAppDispatch()
    const router = useNavigate()


    useEffect(() => {
        !!code && dispatch(oAuthUser({ code: code })).then((resp: any) => {
            if (!resp.error) {
                router('/profile')
            }
            if (resp.error) {
                message.error("Что-то пошло не так. Попробуйте еще раз")
                router('/')
            }
        })
    }, [])
    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Авторизация | Мои награды</title>
            <meta name="description" content="Ваши награды и результаты соревнований"
            />
        </Helmet>
        <h1 style={{
            textAlign: 'center',
            color: 'linear-gradient(90deg, rgba(0, 0, 0, 0.06) 25%, rgba(0, 0, 0, 0.15) 37%, rgba(0, 0, 0, 0.06) 63%)',
            backgroundSize: '400% 100%',
            animationName: 'ant-skeleton-loading',
            animationDuration: '1.4s',
            animationTimingFunction: 'ease',
            animationIterationCount: 'infinite'
        }} className={s.h1}>МОИНАГРАДЫ.РФ</h1 >
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px'
        }}>
            <Space>
                <Skeleton.Input style={{ width: "250px", height: '10px' }} active />
            </Space>
        </div>
    </>)
}