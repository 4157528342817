
import { FC, useEffect, useState } from "react"
import s from './albums.module.scss'
import layout from '../../styles/_layout.module.scss'
import { CustomInput } from "../common/CustomInput/CustomInput"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { MeSlice, getMe } from "../../store/slice/meSlice"
import { Select, Spin, Upload, UploadProps, message } from "antd"
import { CustomButtom } from "../common/CustomButton/CustomButton"
import { createAlbum } from "../../store/slice/createAlbumSlice"
import { FotikIcon } from "../../icons/fotik-icon"
import { ENDPOINTS, REST_API } from "../../const/constants"
import { GetAlbum, getAlbum } from "../../store/slice/getAlbumById"

import { KorzinaIcon } from "../../icons/korzina-icon"
import axios from "axios"
import { customRoute } from "../../utilities"
import { CustomSelect } from "../common/CustomSelect/CustomSelect"
import { GetAlbums } from "../../store/slice/getAlbumsSlice"
import { GetUserRewards, getUserRewards } from "../../store/slice/getUserRewardsSlice"

type OptionType = { label: string, value: string | number | undefined, disabled?: boolean };

interface IAddAlbum {
    setStatus: (status: boolean) => void;
    setAlbumTitle: (title: string) => void;
}

export const AddAlbum: FC<IAddAlbum> = ({ setStatus, setAlbumTitle }) => {
    const [step, setStep] = useState(1)
    const [title, setTitle] = useState("")
    const [reward, setReward] = useState(0)
    const [albumId, setAlbumId] = useState(0)
    const { success, data } = useAppSelector(MeSlice)
    const dispatch = useAppDispatch()
    const { success: alSuccess, data: alData } = useAppSelector(GetAlbum)
    const { loading: aLoading, success: aSuccess, data: aData } = useAppSelector(GetAlbums)
    const [rewardsList, setRewardsList] = useState<OptionType[]>([] as OptionType[])
    const [fstatus, setfStatus] = useState<any>(undefined)
    const { success: rSuccess, loading: rLoading, data: rewards } = useAppSelector(GetUserRewards)

    useEffect(() => {
        !success && dispatch(getMe({ token: localStorage.getItem("access") })).then((resp: any) => {
            if (resp.error) {
                customRoute("/auth")
            }
            if (!resp.error) {
                dispatch(getUserRewards({
                    id: resp.payload.id
                }))
            }
        })
    }, [])

    useEffect(() => {
        if (success && aSuccess) {
            let _arr: OptionType[] = [] as OptionType[]
            const checkItems = (opt: OptionType) => {
                _arr.push({ label: opt.label, value: opt.value, disabled: !!aData.filter(al => al.reward === opt.value).length })
            }
            rewards?.map(reward => (checkItems({ label: reward.title, value: reward.id })))
            setRewardsList(_arr)
        }
    }, [reward, rSuccess, aSuccess])


    useEffect(() => {
        !!albumId && dispatch(getAlbum({ id: albumId }))
    }, [albumId])

    const nextClick = () => {
        dispatch(createAlbum({
            token: localStorage.getItem('access'),
            title: title,
            reward: reward
        })).then((resp: any) => {
            if (!resp.error) {
                setAlbumId(resp.payload.id)
                setAlbumTitle(`Альбом "${resp.payload.title}"`)
                setStep(2)
            }
        })
    }

    const uploadProps: UploadProps = {
        name: 'image',
        multiple: true,
        action: REST_API + ENDPOINTS.albums.albums + albumId + '/photos/',
        headers: {
            authorization: `Bearer ${typeof window !== 'undefined' && localStorage.getItem('access')}`,
        },
        onRemove: (image) => {
            let data = new FormData();

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: REST_API + ENDPOINTS.albums.albums + albumId + '/photos/' + image.response.id + '/delete/',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                },
                data: data,
                onload: console.log('loading')
            };

            axios.request(config)
                .then((response) => {
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        onChange(info) {
            setfStatus(info.file.status)
            if (info.file.status === 'done') {
                message.success(`Файл ${info.file.name} успешно загружен`);
                dispatch(getAlbum({ id: albumId }))
            } else if (info.file.status === 'error') {
                message.error(`Произошла ошибка при загрузке файла ${info.file.name}`);
            }
        },
    };

    const deletePhoto = (phid: number) => {
        let data = new FormData();

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: REST_API + ENDPOINTS.albums.albums + albumId + '/photos/' + phid + '/delete/',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                dispatch(getAlbum({ id: albumId }))
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (<>
        {step === 1 && data?.rewards?.length === 0 && <div>Для добавления фотоальбома, Вам нужно создать хотя-бы одну награду</div>}
        {step === 1 && rewardsList.length > 0 &&
            <div className={s.albumSteps}>
                <div className={s.stepBody}>
                    <CustomInput
                        name="title"
                        type="text"
                        onChange={(e) => {
                            setTitle(e.target.value)
                        }}
                        placeholder='Укажите название альбома'
                    />
                    <CustomSelect
                        // isSearch
                        className={s.sportSelector}
                        popupClassName={s.sportPopupSelector}
                        bordered={false}
                        options={rewardsList}
                        placeholder="Выберите награду"
                        onSelect={(value, option) => {
                            setReward(+option.value);
                        }}
                    />
                </div>
            </div>
        }
        {step === 2 &&
            <div className={s.albumSteps}>
                <div>Добавьте фотографии</div>
                <div className={s.stepBody}>
                    <div className={s.editPhotoBlock}>
                        <div className={s.editPhotoBlockList}>
                            {alData?.photos?.map((ph, i) => {
                                return (
                                    <div className={s.editPhotoBlockImgContainer} key={i}>
                                        <img className={s.editPhotoBlockImg} src={ph.image} width={110} height={100} alt="" />
                                        <div onClick={() => { deletePhoto(ph.id) }} className={s.editPhotoBlockImgRemove}><KorzinaIcon /></div>
                                    </div>
                                )
                            })}
                        </div>
                        <Spin spinning={fstatus === 'uploading'}>
                            <Upload
                                className='noprogreess'
                                {...uploadProps}>
                                <div className={s.addPhotoEdit} >
                                    <FotikIcon />
                                </div>
                            </Upload>
                        </Spin>
                    </div>
                </div>
            </div>
        }
        <div className={s.stepsFooter}>
            <div className={s.stepButton}>
                {step === 1 &&
                    <CustomButtom
                        disabled={!title || reward === 0}
                        onClick={nextClick}
                        type='primary'
                    >Дальше
                    </CustomButtom>
                }
                {step === 2 &&
                    <CustomButtom
                        disabled={!title || reward === 0}
                        onClick={() => setStatus(false)}
                        type='primary'
                    >Сохранить альбом
                    </CustomButtom>
                }
            </div>
        </div>
    </>)
}