
import React, { FC, useEffect, useState } from "react"
import layout from '../../styles/_layout.module.scss'
import s from "./award.module.scss"
import { Nav } from "../Nav/Nav"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { MeSlice, getMe } from "../../store/slice/meSlice"
import { useNavigate, useParams } from "react-router-dom"
import { Dropdown, MenuProps } from "antd"
import { ThreeDotsIcon } from "../../icons/threedots-icon"
import { ShareIcon } from "../../icons/share-icon"
import { ArrowBackIcon } from "../../icons/arrowback-icon"

import { GetReward, getReward } from "../../store/slice/getRewardSlice"
import { Overlay } from "../common/Overlay/Overlay"
import { AwardEdit } from "./AwardEdit"
import { AwardLine } from "./AwardLine"
import { ChelovechecIcon } from "../../icons/chelovechec-icon"
import { CupIcon } from "../../icons/cup-icon"
import { DvachudikaIcon } from "../../icons/dvachudika-icon"
import { BumazhkaIcon } from "../../icons/bumazhka-icon"
import { GeoIcon } from "../../icons/geo-icon"
import { PalkaIcon } from "../../icons/palka-icon"
import moment from "moment"
import { Share } from "../common/Share/Share"
import { customRoute } from "../../utilities"
import { Scripts } from "../common/Scripts/Scripts"
import { KorzinaIcon } from "../../icons/korzina-icon"
import { CustomButtom } from "../common/CustomButton/CustomButton"
import { deleteReward } from "../../store/slice/updateRewardSlice"
import { getRewards } from "../../store/slice/getRewardsSlice"
import { Success } from "../common/Success/Success"
import { Error } from "../common/Error/Error"
import { Helmet } from "react-helmet"

export const Award = (props: unknown) => {
    // const { id } = props.match.params;
    const params = useParams()
    const id = params.id
    const { success, data, loading } = useAppSelector(MeSlice)
    const dispatch = useAppDispatch()
    const router = useNavigate()
    const { data: award } = useAppSelector(GetReward)
    const [editStatus, setEditStatus] = useState(false)
    const [shareStatus, setShareStatus] = useState(false)
    const [isRemove, setIsRemove] = useState(false)
    const [isRemoveSuccess, setIsRemoveSuccess] = useState(false)
    const [isRemoveError, setIsRemoveError] = useState(false)

    useEffect(() => {
        console.log('props', params)
    }, [params])

    useEffect(() => {
        !success && dispatch(getMe({ token: localStorage.getItem("access") })).then((resp: any) => {
            if (resp.error) {
                customRoute('/auth')
            }
        })
    }, [])

    useEffect(() => {
        dispatch(getReward({ id: id }))
    }, [id])

    const deleteHandler = () => {
        dispatch(deleteReward({ token: localStorage.getItem('access'), id: id })).then((resp: any) => {
            if (resp.error) {
                setIsRemoveError(true)
                setIsRemoveSuccess(false)
            }
            if (!resp.error) {
                setIsRemoveError(false)
                setIsRemoveSuccess(true)
                dispatch(getRewards({}))
                customRoute('/awards')
            }
        })
    }

    const items: MenuProps['items'] = [
        {
            label: <span className={layout.dropDownItem} onClick={() => { setEditStatus(true) }}>
                <span className={layout.dropDownItemIcon}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.6665 12.4762H13.3332V13.2381H2.6665V12.4762ZM11.5808 6.00004C11.8856 5.69528 11.8856 5.23814 11.5808 4.93337L10.2094 3.56195C9.9046 3.25718 9.44746 3.25718 9.14269 3.56195L3.42841 9.27623V11.7143H5.8665L11.5808 6.00004ZM9.67603 4.09528L11.0475 5.46671L9.9046 6.60956L8.53317 5.23814L9.67603 4.09528ZM4.19031 10.9524V9.58099L7.99984 5.77147L9.37127 7.1429L5.56174 10.9524H4.19031Z" fill="white" />
                    </svg>
                </span>
                Редактировать
            </span>,
            key: '0',
            disabled: data.id !== award.user
        },
        {
            type: 'divider',
        },
        {
            label: <span className={layout.dropDownItem} onClick={() => { setShareStatus(true) }}>
                <span className={layout.dropDownItemIcon}>
                    <ShareIcon size={9} fill='#FFFFFF' />
                </span>
                Поделиться
            </span>,
            key: '1',
        },
        {
            type: 'divider',
        },
        {
            label: <span className={layout.dropDownItem} onClick={() => { setIsRemove(true) }}>
                <span className={layout.dropDownItemIcon}>
                    <KorzinaIcon size={9} fill='transparent' stroke='#FFFFFF' />
                </span>
                Удалить награду
            </span>,
            key: '2',
            disabled: data.id !== award.user
        }
    ];

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Награда | Мои награды</title>
                <meta name="description" content="Ваши награды и результаты соревнований"
                />
            </Helmet>
            <div className={layout.container}>
                <div className={layout.content}>
                    <div className={s.header}>
                        <div className={layout.round} onClick={() => { award.user === data.id ? customRoute('/awards') : customRoute(`/user/${award.user}`) }}>
                            <ArrowBackIcon />
                        </div>
                        <div className={layout.pageName}>Награда</div>
                        <div className={layout.headerButtons}>
                            <Dropdown overlayClassName={'profileDropDown'} menu={{ items }} trigger={['click']}>
                                <div className={layout.round} onClick={(e) => e.preventDefault()}>
                                    <ThreeDotsIcon />
                                </div>
                            </Dropdown>
                        </div>
                    </div>
                    <div className={s.ap}>
                        <div className={s.apImage}>
                            {award?.photos?.length > 0 ? <img className={s.awardItemImage} src={award?.photos[0]?.image} alt={award.title} width={500} height={252} />
                                : <div style={{ width: "100%", height: "100px", display: "flex", alignItems: 'center', justifyContent: 'center', background: '#E9E9E9' }}>

                                    <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M21.4998 29.1001V34.8001" stroke="#C8C8C8" strokeWidth="3.0875" strokeLinecap="round" />
                                        <path d="M28.1499 40.5H14.8499L15.4959 37.2776C15.5819 36.847 15.8144 36.4596 16.1539 36.1811C16.4934 35.9026 16.9188 35.7503 17.3579 35.75H25.6419C26.081 35.7503 26.5064 35.9026 26.8459 36.1811C27.1853 36.4596 27.4179 36.847 27.5039 37.2776L28.1499 40.5Z" stroke="#C8C8C8" strokeWidth="3.0875" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M34.7999 8.2002L36.603 8.8006C38.484 9.4276 39.4245 9.7411 39.9622 10.4878C40.4999 11.2345 40.4999 12.2263 40.4999 14.208V14.3467C40.4999 15.9807 40.4999 16.7996 40.1066 17.4684C39.7133 18.1372 38.997 18.5343 37.5682 19.3304L31.9499 22.4503M8.19978 8.2002L6.39667 8.8006C4.51567 9.4276 3.57516 9.7411 3.03746 10.4878C2.49976 11.2345 2.49976 12.2282 2.49976 14.208V14.3467C2.49976 15.9807 2.49976 16.7996 2.89306 17.4684C3.28636 18.1372 4.00266 18.5343 5.43147 19.3304L11.0498 22.4503M19.8772 10.1439C20.5992 8.8462 20.9602 8.2002 21.4998 8.2002C22.0394 8.2002 22.4004 8.8462 23.1225 10.1439L23.3087 10.4783C23.5139 10.8469 23.6165 11.0293 23.7761 11.1509C23.9376 11.2725 24.1371 11.3181 24.5361 11.4074L24.8971 11.491C26.2993 11.8083 27.0004 11.966 27.1676 12.5018C27.3348 13.0395 26.856 13.5981 25.9003 14.7153L25.6533 15.0041C25.3816 15.3214 25.2448 15.4791 25.184 15.6767C25.1232 15.8743 25.1441 16.0852 25.184 16.5089L25.222 16.8946C25.3664 18.3861 25.4386 19.1328 25.0035 19.4634C24.5665 19.794 23.9091 19.4919 22.5961 18.8877L22.2579 18.7319C21.8836 18.5609 21.6974 18.4754 21.4998 18.4754C21.3022 18.4754 21.116 18.5609 20.7417 18.7319L20.4035 18.8877C19.0906 19.4938 18.4332 19.794 17.9962 19.4634C17.5592 19.1328 17.6333 18.3861 17.7777 16.8946L17.8157 16.5089C17.8556 16.0852 17.8765 15.8743 17.8157 15.6767C17.7549 15.481 17.6181 15.3214 17.3464 15.0041L17.0994 14.7153C16.1437 13.5981 15.6649 13.0395 15.8321 12.5018C15.9993 11.966 16.7004 11.8083 18.1026 11.491L18.4636 11.4074C18.8626 11.3181 19.0621 11.2744 19.2236 11.1509C19.3832 11.0293 19.4858 10.8469 19.691 10.4783L19.8772 10.1439Z" stroke="#C8C8C8" strokeWidth="3.0875" />
                                        <path d="M32.8999 40.5001H10.0998M30.9999 3.36637C32.4078 3.63427 33.2438 3.93067 33.9639 4.81988C34.8702 5.93328 34.8227 7.13599 34.7258 9.5414C34.3838 18.1921 32.4458 29.1001 21.5017 29.1001C10.5577 29.1001 8.61778 18.194 8.27197 9.5414C8.17697 7.13599 8.12947 5.93138 9.03198 4.81988C9.93638 3.70647 11.0175 3.52407 13.1816 3.15927C15.9312 2.70863 18.7136 2.4881 21.4998 2.49997C22.864 2.49997 24.1332 2.54747 25.2999 2.62917" stroke="#C8C8C8" strokeWidth="3.0875" strokeLinecap="round" />
                                    </svg>

                                </div>
                            }
                        </div>
                        <div className={s.apMainInfo}>
                            <div className={s.apMainInfoCup}>
                                🏆
                            </div>
                            <div className={s.apMainInfoData}>
                                <div className={s.apMainInfoDataTop}>
                                    <div className={s.apMainInfoDataTopType}>
                                        {award.category === 'sport' ? 'Спортивная награда' : 'Неспортивная награда'}
                                    </div>
                                    <div className={s.apMainInfoDataTopDate}>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.5 11H2.5C2.23478 11 1.98043 10.8946 1.79289 10.7071C1.60536 10.5196 1.5 10.2652 1.5 10V3C1.5 2.73478 1.60536 2.48043 1.79289 2.29289C1.98043 2.10536 2.23478 2 2.5 2H3.5V1H4.5V2H7.5V1H8.5V2H9.5C9.76522 2 10.0196 2.10536 10.2071 2.29289C10.3946 2.48043 10.5 2.73478 10.5 3V10C10.5 10.2652 10.3946 10.5196 10.2071 10.7071C10.0196 10.8946 9.76522 11 9.5 11ZM2.5 5V10H9.5V5H2.5ZM2.5 3V4H9.5V3H2.5Z" fill="#DDBE90" />
                                        </svg>

                                        <span>{!!award.start_date && moment(award.start_date).format("DD.MM.YYYY")}
                                            {/* {award.start_day}.{award.start_month}.{award.start_year} */}
                                        </span>
                                    </div>
                                </div>
                                <div className={s.apMainInfoDataTitle}>
                                    {award.title}
                                </div>
                            </div>
                        </div>
                        <div className={s.apSportInfo}>
                            <div className={s.apSport}>
                                <div className={s.apSportTop}>
                                    <div className={s.apSportTopIcon}>
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="32" height="32" rx="16" fill="#DDBE90" />
                                            <path d="M15.6667 10.9999H14.0001V9.33325H15.6667V10.9999ZM19.0001 9.33325H17.3334V10.9999H19.0001V9.33325ZM14.0001 17.6666H15.6667V15.9999H14.0001V17.6666ZM22.3334 14.3333V12.6666H20.6667V14.3333H22.3334ZM22.3334 17.6666V15.9999H20.6667V17.6666H22.3334ZM17.3334 17.6666H19.0001V15.9999H17.3334V17.6666ZM22.3334 9.33325H20.6667V10.9999H22.3334V9.33325ZM17.3334 12.6666V10.9999H15.6667V12.6666H17.3334ZM12.3334 14.3333V12.6666H14.0001V10.9999H12.3334V9.33325H10.6667V22.6666H12.3334V15.9999H14.0001V14.3333H12.3334ZM19.0001 15.9999H20.6667V14.3333H19.0001V15.9999ZM15.6667 14.3333V15.9999H17.3334V14.3333H15.6667ZM14.0001 12.6666V14.3333H15.6667V12.6666H14.0001ZM17.3334 14.3333H19.0001V12.6666H17.3334V14.3333ZM19.0001 10.9999V12.6666H20.6667V10.9999H19.0001Z" fill="white" />
                                        </svg>

                                    </div>
                                    <div className={s.apSportTopTitle}>
                                        {award.category === 'sport' && <>{award.sport?.title}</>}
                                        {award.category === 'non_sport' && <>{award.kind_activity}</>}
                                    </div>
                                </div>
                                <div className={s.apSportBottom}>
                                    {award.category === 'sport' && <><div className={s.apSportBottomCaption}>
                                        Дисциплина:
                                    </div>
                                        <div className={s.apSportBottomDistance}>
                                            {award.disciplines}
                                        </div></>}
                                </div>
                            </div>
                            <div className={s.apResult}>
                                <div className={s.apResultTop}>
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="white" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M17.6231 8.06018C17.9192 8.18874 18.0952 8.49716 18.0551 8.81752L17.43 13.8182H23.1516C23.4338 13.8182 23.6905 13.9814 23.8102 14.237C23.9299 14.4925 23.891 14.7943 23.7103 15.011L16.4376 23.7383C16.2309 23.9863 15.8855 24.0684 15.5893 23.9398C15.2932 23.8113 15.1172 23.5028 15.1573 23.1825L15.7824 18.1818H10.0608C9.77858 18.1818 9.52186 18.0186 9.40217 17.763C9.28247 17.5075 9.32141 17.2057 9.50207 16.989L16.7748 8.26172C16.9814 8.0137 17.3269 7.93163 17.6231 8.06018ZM11.6135 16.7273H16.6062C16.8148 16.7273 17.0134 16.8168 17.1514 16.9732C17.2895 17.1296 17.3537 17.3378 17.3278 17.5447L16.9083 20.9015L21.5989 15.2727H16.6062C16.3976 15.2727 16.199 15.1832 16.061 15.0268C15.9229 14.8704 15.8587 14.6622 15.8845 14.4553L16.3041 11.0985L11.6135 16.7273Z" fill="#DDBE90" />
                                        <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#DDBE90" />
                                    </svg>

                                </div>
                                <div className={s.apResultBottom}>
                                    {award.result}
                                </div>
                            </div>
                        </div>
                        <div className={s.apData}>
                            {!!award.additional_criteria && <AwardLine
                                icon={<ChelovechecIcon />}
                                type="text"
                                caption="Доп. критерии дисциплины:"
                                data={award.additional_criteria}
                            />}
                            {!!award.significance && <AwardLine
                                icon={<CupIcon />}
                                type="type"
                                caption="Значимость:"
                                data={award.significance}
                            />}
                            {!!award.category && <AwardLine
                                icon={<DvachudikaIcon />}
                                type="type"
                                caption="Тип награды:"
                                data={award.category}
                            />}
                            {!!award.protocol && <AwardLine
                                icon={<BumazhkaIcon />}
                                type="link"
                                caption="Протокол:"
                                data={award.protocol}
                            />}
                            {!!award.country && <AwardLine
                                icon={<GeoIcon />}
                                type="location"
                                caption="Локация:"
                                country={award.country.name}
                                city={award?.city?.name}
                            />}
                            {!!award.team && <AwardLine
                                icon={<PalkaIcon />}
                                type="text"
                                caption="Команда:"
                                data={award.team}
                            />}
                            {!!award.organizer && <AwardLine
                                icon={<PalkaIcon />}
                                type="text"
                                caption="Кем выдана / Организатор:"
                                data={award.organizer}
                            />}
                            {!!award.teacher && <AwardLine
                                icon={<PalkaIcon />}
                                type="text"
                                caption="Педагог / Руководитель:"
                                data={award.teacher}
                            />}
                            {!!award.institution && <AwardLine
                                icon={<PalkaIcon />}
                                type="text"
                                caption="Школа / Учереждение:"
                                data={award.institution}
                            />}
                            {!!award.note && <AwardLine
                                icon={<PalkaIcon />}
                                type="text"
                                caption="Мои заметки::"
                                data={award.note}
                            />}
                        </div>
                    </div>
                </div>
            </div>
            <div className={layout.footer}>
                <Nav active='award' />
            </div>
            <Scripts />
            {editStatus && <Overlay
                title={'Редактирование награды'}
                status={editStatus}
                setStatus={setEditStatus}>
                <AwardEdit onClose={setEditStatus} id={id} />
            </Overlay>
            }
            {shareStatus && <Overlay
                title={'Поделиться наградой'}
                status={shareStatus}
                setStatus={setShareStatus}>
                <Share link={window.location.href} />
            </Overlay>
            }
            {isRemove && <Overlay
                title={'Удаление награды'}
                status={isRemove}
                setStatus={setIsRemove}>
                {isRemove && !isRemoveError && !isRemoveSuccess && <div className={s.removeBlock}>
                    <div className={s.remtitle}>Вы действительно хотите удалить награду?</div>
                    <div className={s.removeButtons}>
                        <CustomButtom
                            onClick={() => {
                                setIsRemove(false)
                            }}
                            type='secondary'
                        >Отмена
                        </CustomButtom>
                        <CustomButtom
                            onClick={() => {
                                setIsRemove(true)
                                deleteHandler()
                            }}
                            type='primary'
                        >Удалить
                        </CustomButtom>
                    </div>
                </div>}
                {isRemove && !isRemoveError && isRemoveSuccess &&
                    <Success heading='Награда удалена' />
                }
                {isRemove && isRemoveError && !isRemoveSuccess &&
                    <Error heading='Ошибка' />
                }
            </Overlay>}
        </>
    )
}