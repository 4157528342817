import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

type InitialStateType = {
  profileEdit: boolean;
  isSubUser:boolean;
  isSupport: boolean;
  reminder: boolean;
};

const initialState: InitialStateType = {
  profileEdit: false,
  isSubUser: false,
  isSupport: false,
  reminder: false
};

export const staticSlice = createSlice({
  name: "static",
  initialState,
  reducers: {
    setProfileEdit: (state, action: PayloadAction<boolean>) => {
      state.profileEdit = action.payload;
    },
    setSubUser: (state, action: PayloadAction<boolean>) => {
      state.isSubUser = action.payload;
    },
    setSupport: (state, action: PayloadAction<boolean>) => {
      state.isSupport = action.payload;
    },
    setReminder: (state, action: PayloadAction<boolean>) => {
      state.reminder = action.payload;
    }
  },
});

export const { setProfileEdit, setSubUser, setSupport, setReminder } = staticSlice.actions;

export const StaticSlice = (state: RootState) => state.static;

export const StaticReducer = staticSlice.reducer;
