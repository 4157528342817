import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"
import { ENDPOINTS, REST_API } from "../../const/constants"
import axios from "axios"

export interface IUser {
    id: string,
    profile: IProfile
  }
  interface IProfile {
    id: number,
    full_name: string,
    middle_name: string,
    first_name: string,
    last_name: string,
    gender: string,
    birth_date: string,
    level_display: string,
    level: number,
    image: string,
    phone_number: string,
    country: ILocation,
    city: ILocation,
    bio: string,
    category: string
  }
  interface ILocation {
    id: number,
    name: string
  }

  interface IDistance{
    id: number,
    title: string,
    sport: number
  }

  interface ISport {
    id: number,
    title: string
  }

  interface IPhoto {
    id: number,
    image: string
  }

  interface initialStateErrorType {
    detail?: string,
    code?: string,
    messages?: string[]
  }

   interface meInitialStateType {
    loading: boolean,
    data: IUser,
    success: boolean,
    error: boolean,
    errors: initialStateErrorType,
  }
  
  const initialState: meInitialStateType = {
    loading: false,
    data: {} as IUser,
    success: false,
    error: false,
    errors: {} as initialStateErrorType,
  };

 
  
  export interface IUserReq {
    id: string,    
  }
  
  export const getUser = createAsyncThunk(
    "getUser",
    async (req: IUserReq, { rejectWithValue }) => {
      const {id} = req
      const headers = {
        Authorization: `Bearer ${localStorage.getItem('access')}`,
      };
      const url = REST_API + ENDPOINTS.user.user + id + '/'
      try {
        const response = await axios.get(url, {headers: headers });
        return response.data;
      } catch (err) {
        const error: any = err;
        return rejectWithValue(error.response);
      }
    }
  );
  
  export const getUserSlice = createSlice({
    name: "getUser",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
      builder
        .addCase(getUser.pending, (state:any) => {
          state.loading = true;
          state.success = false;
          state.checkpoint = true;
        })
        .addCase(getUser.fulfilled, (state:any, { payload }:any) => {
          state.loading = false;
          state.data = payload;
          state.error = false;
          state.success = true;
        })
        .addCase(getUser.rejected, (state, { error, payload }) => {
          const payl: any = payload;
          state.loading = false;
          state.success = false;
          state.error = true;
        })
    },
  });
  
  export const GetUser = (state: RootState) => state.getUser;
  
  export const GetUserReducer = getUserSlice.reducer;
  