import { FC } from "react"

interface IIcon {
    fill?: string;
    stroke?: string;
    size?: number
}

export const ThreeDotsIcon: FC<IIcon> = ({ stroke = "#818181", fill = "#DDBE90" }) => {
    return (
        <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 2C1 2.19891 1.07902 2.38968 1.21967 2.53033C1.36032 2.67098 1.55109 2.75 1.75 2.75C1.94891 2.75 2.13968 2.67098 2.28033 2.53033C2.42098 2.38968 2.5 2.19891 2.5 2C2.5 1.80109 2.42098 1.61032 2.28033 1.46967C2.13968 1.32902 1.94891 1.25 1.75 1.25C1.55109 1.25 1.36032 1.32902 1.21967 1.46967C1.07902 1.61032 1 1.80109 1 2ZM6.25 2C6.25 2.19891 6.32902 2.38968 6.46967 2.53033C6.61032 2.67098 6.80109 2.75 7 2.75C7.19891 2.75 7.38968 2.67098 7.53033 2.53033C7.67098 2.38968 7.75 2.19891 7.75 2C7.75 1.80109 7.67098 1.61032 7.53033 1.46967C7.38968 1.32902 7.19891 1.25 7 1.25C6.80109 1.25 6.61032 1.32902 6.46967 1.46967C6.32902 1.61032 6.25 1.80109 6.25 2ZM11.5 2C11.5 2.19891 11.579 2.38968 11.7197 2.53033C11.8603 2.67098 12.0511 2.75 12.25 2.75C12.4489 2.75 12.6397 2.67098 12.7803 2.53033C12.921 2.38968 13 2.19891 13 2C13 1.80109 12.921 1.61032 12.7803 1.46967C12.6397 1.32902 12.4489 1.25 12.25 1.25C12.0511 1.25 11.8603 1.32902 11.7197 1.46967C11.579 1.61032 11.5 1.80109 11.5 2Z" fill={fill} stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}