
import layout from '../../styles/_layout.module.scss'
import { Nav } from '../Nav/Nav'
import s from './albums.module.scss'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { MeSlice, getMe } from '../../store/slice/meSlice'
import { useEffect, useState } from 'react'

import { UserIcon } from '../../icons/user-icon'
import { Dropdown, MenuProps, Spin } from 'antd'
import { ThreeDotsIcon } from '../../icons/threedots-icon'
import { ShareIcon } from '../../icons/share-icon'
import { AddAwardPlusIcon } from '../../icons/add-award-plus-icon'
import { GetAlbums, getAlbums } from '../../store/slice/getAlbumsSlice'

import { Link, useNavigate } from 'react-router-dom'
import { customRoute } from '../../utilities'
import { Scripts } from '../common/Scripts/Scripts'
import { Overlay } from '../common/Overlay/Overlay'
import { AddAlbum } from './AddAlbum'
import { getUserRewards } from '../../store/slice/getUserRewardsSlice'
import { Helmet } from 'react-helmet'


export const Albums = () => {
    const [addStatus, setAddStatus] = useState(false)
    const { success, data, loading } = useAppSelector(MeSlice)
    const dispatch = useAppDispatch()
    const router = useNavigate()
    const { loading: aLoading, success: aSuccess, data: aData } = useAppSelector(GetAlbums)
    const [addAlbumTitle, setAddAlbumTitle] = useState('Добавление фотоальбома')

    useEffect(() => {
        !success && dispatch(getMe({ token: localStorage.getItem("access") })).then((resp: any) => {
            if (resp.error) {
                customRoute('/auth')
            }
            if (!resp.error) {
                dispatch(getUserRewards({
                    id: resp.payload.id
                }))
            }
        })
        dispatch(getAlbums({ token: localStorage.getItem('access') }))
    }, [])

    const items: MenuProps['items'] = [
        {
            label: <span className={layout.dropDownItem} onClick={() => { }}><span className={layout.dropDownItemIcon}><ShareIcon size={9} fill='#FFFFFF' /></span> Поделиться</span>,
            key: '0',
        }
    ];

    const switchModal = (state: boolean) => {
        dispatch(getAlbums({ token: localStorage.getItem('access') }))
        setAddStatus(state)
    }

    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Фотоальбомы | Мои награды</title>
            <meta name="description" content="Ваши награды и результаты соревнований"
            />
        </Helmet>
        <div className={layout.container}>
            <div className={layout.content}>
                <div className={s.header}>
                    <div className={s.userName}>
                        <UserIcon size={16} fill='#818181' /> <span>{data?.profile?.full_name}</span>
                    </div>
                    {/* <div className={layout.headerButtons}>
                        <Dropdown overlayClassName={'profileDropDown'} menu={{ items }} trigger={['click']}>
                            <div className={layout.round} onClick={(e) => e.preventDefault()}>
                                <ThreeDotsIcon />
                            </div>
                        </Dropdown>
                    </div> */}
                </div>
                <div className={s.body}>
                    {aSuccess && aData.length > 0 && <div className={s.pageTitle}>Фотоальбомы</div>}
                    {aSuccess && aData.length === 0 && <div className={s.pageTitle}>Добавить фотоальбом</div>}
                    <Spin spinning={aLoading}>
                        {aData.map((album, i) => {
                            return (<div key={i}>
                                <div className={s.albumItem}>
                                    <div className={s.albumItemTitle}><span>{album.title}</span>

                                        <Link to={`/albums/${album.id}/edit`}><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="32" height="32" rx="16" fill="#DDBE90" />
                                            <path d="M9.33325 21.5956H22.6666V22.5479H9.33325V21.5956ZM20.4761 13.5003C20.8571 13.1194 20.8571 12.5479 20.4761 12.167L18.7618 10.4527C18.3809 10.0718 17.8094 10.0718 17.4285 10.4527L10.2856 17.5956V20.6432H13.3333L20.4761 13.5003ZM18.0952 11.1194L19.8094 12.8337L18.3809 14.2622L16.6666 12.5479L18.0952 11.1194ZM11.238 19.6908V17.9765L15.9999 13.2146L17.7142 14.9289L12.9523 19.6908H11.238Z" fill="white" />
                                        </svg>
                                        </Link></div>
                                    <div className={s.albumItemBody}>
                                        {album.photos.map((ph, index) => {
                                            return (<Link to={`/albums/${album.id}`} key={index}>

                                                <img src={ph.image} width={172} height={150} className={s.albumItemImage} alt="" />
                                            </Link>)
                                        })}
                                        <Link to={`/albums/${album.id}`} className={s.albumItemAddMore}>
                                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12.2266 15.9178C9.32652 15.9178 6.97755 14.1684 5.89788 11.6341C6.97755 9.09985 9.32652 7.35039 12.2266 7.35039C15.1268 7.35039 17.4757 9.09985 18.5554 11.6341C17.4757 14.1684 15.1268 15.9178 12.2266 15.9178ZM12.2266 6.15039C8.7014 6.15039 5.88807 8.35063 4.69101 11.4158C4.6362 11.5562 4.6362 11.712 4.69101 11.8524C5.88807 14.9176 8.7014 17.1178 12.2266 17.1178C15.7519 17.1178 18.5652 14.9176 19.7623 11.8524C19.8171 11.712 19.8171 11.5562 19.7623 11.4158C18.5652 8.35063 15.7519 6.15039 12.2266 6.15039ZM11.1709 10.5784C11.4509 10.2984 11.8307 10.1411 12.2266 10.1411C12.6226 10.1411 13.0024 10.2984 13.2824 10.5784C13.5624 10.8584 13.7197 11.2381 13.7197 11.6341C13.7197 12.0301 13.5624 12.4098 13.2824 12.6898C13.0024 12.9698 12.6226 13.1271 12.2266 13.1271C11.8307 13.1271 11.4509 12.9698 11.1709 12.6898C10.8909 12.4098 10.7336 12.0301 10.7336 11.6341C10.7336 11.2381 10.8909 10.8584 11.1709 10.5784ZM12.2266 8.94109C11.5124 8.94109 10.8274 9.22482 10.3224 9.72986C9.81735 10.2349 9.53362 10.9199 9.53362 11.6341C9.53362 12.3483 9.81735 13.0333 10.3224 13.5384C10.8274 14.0434 11.5124 14.3271 12.2266 14.3271C12.9409 14.3271 13.6259 14.0434 14.1309 13.5384C14.6359 13.0333 14.9197 12.3483 14.9197 11.6341C14.9197 10.9199 14.6359 10.2349 14.1309 9.72986C13.6259 9.22482 12.9409 8.94109 12.2266 8.94109Z" fill="#DDBE90" />
                                            </svg>
                                            <span>Смотреть все</span>
                                        </Link>
                                    </div>

                                </div>

                            </div>)
                        })}
                        {aSuccess && aData.length === 0 && <div className={s.noAlbums}>
                            <div className={layout.awardList}>
                                <div onClick={() => setAddStatus(true)} className={s.addAwardItem}>
                                    <div style={{
                                        width: '100%',
                                        height: '106px',
                                        background: 'url(/images/addAlbum.png) no-repeat center',
                                        borderRadius: '4px',
                                        backgroundSize: 'cover'
                                    }} className={layout.awardItemImageContainer}></div>
                                    <div className={s.addAwTitle}>Альбомы</div>
                                    <div className={s.addAwDesc}>Добавьте все самые незабываемые моменты! 🥳</div>
                                    <div className={s.addAwBtn}>Добавить <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.25 8.66536H8.91671V11.9987C8.91671 12.1755 8.84647 12.3451 8.72145 12.4701C8.59642 12.5951 8.42685 12.6654 8.25004 12.6654C8.07323 12.6654 7.90366 12.5951 7.77864 12.4701C7.65361 12.3451 7.58337 12.1755 7.58337 11.9987V8.66536H4.25004C4.07323 8.66536 3.90366 8.59513 3.77864 8.4701C3.65361 8.34508 3.58337 8.17551 3.58337 7.9987C3.58337 7.82189 3.65361 7.65232 3.77864 7.52729C3.90366 7.40227 4.07323 7.33203 4.25004 7.33203H7.58337V3.9987C7.58337 3.82189 7.65361 3.65232 7.77864 3.52729C7.90366 3.40227 8.07323 3.33203 8.25004 3.33203C8.42685 3.33203 8.59642 3.40227 8.72145 3.52729C8.84647 3.65232 8.91671 3.82189 8.91671 3.9987V7.33203H12.25C12.4269 7.33203 12.5964 7.40227 12.7214 7.52729C12.8465 7.65232 12.9167 7.82189 12.9167 7.9987C12.9167 8.17551 12.8465 8.34508 12.7214 8.4701C12.5964 8.59513 12.4269 8.66536 12.25 8.66536Z" fill="#DDBE90" />
                                    </svg>
                                    </div>

                                </div>
                            </div>
                        </div>}
                    </Spin>
                </div>
            </div>
            <div className={layout.footer}>
                <Nav active='album' />
            </div>
            <span onClick={() => { setAddStatus(true) }} className={layout.addAwardButton}>
                <AddAwardPlusIcon />
            </span>
        </div>
        <Scripts />
        {addStatus && <Overlay
            title={addAlbumTitle}
            status={addStatus}
            setStatus={switchModal}>
            <AddAlbum
                setAlbumTitle={setAddAlbumTitle}
                setStatus={switchModal}
            />
        </Overlay>}
    </>
    )
}