
import { useWindowSize } from "../../../hooks/useWindowSize"
import { AutoComplete, Select } from "antd"
import s from './custom-select.module.scss'
import { FC, useState } from "react"
import classnames from 'classnames';

const cn = classnames.bind(this)
interface ICustomSelect {
    isSearch?: boolean,
    className?: any,
    popupClassName?: any,
    placeholder?: string,
    bordered?: boolean,
    defaultValue?: any,
    defaultMobValue?: string | number,
    onSelect?: (value: any, option: any) => void,
    onChange?: (e: any) => void
    options: OptionType[],
    loading?: boolean
    disabled?: boolean
}

type OptionType = { label: string, value: string | number | undefined, id?: number, disabled?: boolean };

export const CustomSelect: FC<ICustomSelect> = ({
    isSearch = false,
    className,
    popupClassName,
    loading = false,
    disabled = false,
    bordered = false,
    placeholder,
    defaultValue,
    defaultMobValue,
    onSelect,
    onChange,
    options }) => {
    const size = useWindowSize()
    const [optionss, setOptionss] = useState<OptionType[]>([]);
    const [isOpen, setIsOpen] = useState(false)

    const getPanelValue = (searchText: string) => {
        !searchText ? setIsOpen(false) : setIsOpen(true)
        return !searchText ? [] as OptionType[] : options.filter(item => item.label.toLowerCase().includes(searchText.toLowerCase()))
    }

    return (<>
        {isSearch ?
            <AutoComplete
                // showSearch
                className={cn(s.customSelect, className)}
                popupClassName={popupClassName}
                placeholder={placeholder}
                defaultValue={defaultValue}
                onSelect={onSelect}
                options={optionss}
                // open={isOpen}
                bordered={bordered}
                // loading={loading}
                disabled={disabled}
                onSearch={(text) => setOptionss(getPanelValue(text))}
            /> :
            size[0] > 996 ?
                <Select
                    showSearch
                    className={cn(s.customSelect, className)}
                    popupClassName={popupClassName}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    onSelect={onSelect}
                    options={options}
                    bordered={bordered}
                    loading={loading}
                    disabled={disabled}
                /> : <div className={s.mobWrapper}>
                    <span></span>
                    <select
                    defaultValue={!!defaultMobValue ? defaultMobValue : 0} 
                    className={s.selectorMobile} 
                    onChange={e => {
                        !!onSelect && onSelect(e.target.value, { value: e.target.value })
                        !!onChange && onChange(e.target.value)
                    }}>
                        {!defaultMobValue && <option value={0} disabled>{placeholder}</option>}
                        {options.map((date: OptionType, i: number) => {
                            return (
                                <option
                                    disabled={defaultMobValue == date.value ? false : date.disabled}
                                    key={i}
                                    selected={defaultMobValue == date.value}
                                    value={!!date.id ? date.id : date.value}>
                                    {date.label}
                                </option>
                            )
                        })}
                    </select>
                </div>
        }

    </>)
}