import { FC } from "react"

interface IIcon {
    fill?: string;
    stroke?: string;
    size?: number
}

export const PalkaIcon: FC<IIcon> = ({}) => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="14" y="4" width="2" height="22" rx="1" fill="#DDBE90" />
        </svg>
    )
}