import { FC } from "react"

interface IIcon {
    fill?: string;
    stroke?: string;
}

export const ChildrenImg: FC<IIcon> = () => {
    return (
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.1" fillRule="evenodd" clipRule="evenodd" d="M40.6951 11.3106C47.5816 12.8519 48.4811 22.1218 53.056 27.4949C56.9173 32.0299 64.955 33.8997 64.9998 39.8557C65.0446 45.814 57.1086 47.8693 53.2433 52.4039C48.6786 57.7589 47.7122 67.8466 40.6951 68.3689C33.7262 68.8876 30.671 59.7327 26.1287 54.4221C22.1718 49.7958 17.1786 45.8808 16.3075 39.8557C15.3112 32.9638 16.6821 25.6605 21.2054 20.366C26.0428 14.7041 33.4279 9.68402 40.6951 11.3106Z" fill="#818181" />
            <g clipPath="url(#clip0_624_19539)">
                <path fillRule="evenodd" clipRule="evenodd" d="M30.7562 30.7783C30.7562 30.7783 39.4658 29.9574 40.023 30.9944C40.5817 32.0298 41.8168 36.334 41.8168 36.334L39.8636 37.2904C39.8636 37.2904 38.5481 33.6746 37.9909 33.6686C37.4322 33.6642 31.6948 35.4177 31.6948 35.4177L30.7562 30.7783Z" fill="#FCAFA9" />
                <path fillRule="evenodd" clipRule="evenodd" d="M39.8029 37.1236C39.8029 37.1236 39.064 38.3259 39.8029 38.8846C40.5419 39.4418 40.3825 39.8411 41.8172 39.4031C43.2519 38.9636 42.6142 36.9314 41.8172 36.334C41.0201 35.7366 39.8029 37.1236 39.8029 37.1236Z" fill="#FEB3B2" />
                <path fillRule="evenodd" clipRule="evenodd" d="M39.4161 48.4939C39.4161 48.4939 37.6655 46.4364 39.3014 46.2696C40.9372 46.1027 45.6347 47.8175 43.8692 49.5621C42.1037 51.3052 39.4161 48.4939 39.4161 48.4939Z" fill="#170B83" />
                <path fillRule="evenodd" clipRule="evenodd" d="M21.3138 57.6537L15.1563 69.6097C15.1563 69.6097 13.7142 69.8481 13.659 67.3377C13.6307 66.0609 14.4516 63.1125 15.2666 60.5232C16.0502 58.0247 16.8294 55.8615 16.8294 55.8615L21.3138 57.6537Z" fill="#F097AB" />
                <path fillRule="evenodd" clipRule="evenodd" d="M40.1793 47.2531L39.2035 46.9745C39.0381 46.9283 38.8623 46.9984 38.7744 47.1473C38.4541 47.6971 37.6526 49.0603 36.6737 50.4041C35.3895 52.1636 33.8043 53.8889 32.7644 53.7086V54.1868C32.7644 54.1868 34.7802 57.6537 37.5289 55.8614C38.5629 55.1865 39.3167 53.8427 39.8665 52.4273C40.4296 50.9733 40.7663 49.4432 40.9451 48.4852C41.0464 47.9385 40.7142 47.4066 40.1793 47.2531Z" fill="#FCA3A5" />
                <path fillRule="evenodd" clipRule="evenodd" d="M18.5038 50.9299L16.3257 57.3049L20.9293 58.3985C20.9293 58.3985 25.9158 50.4815 26.9915 51.3173C28.0686 52.1546 31.2956 55.383 32.7303 55.1432C34.165 54.9048 36.1972 51.6763 36.1972 51.6763L32.7303 46.8939C32.7303 46.8939 19.7285 47.2232 18.5038 50.9299Z" fill="#110473" />
                <path fillRule="evenodd" clipRule="evenodd" d="M14.6677 35.7843C14.6677 35.7843 17.6667 33.7506 23.0465 32.0731C28.4263 30.3955 31.6876 30.5162 31.6876 30.5162L32.7305 35.7843L28.547 37.5691L32.7305 46.894L19.3487 49.7635L17.3077 41.2758L11.6895 42.8968L10.8522 37.9282L14.6677 35.7843Z" fill="#DDBE90" />
                <path fillRule="evenodd" clipRule="evenodd" d="M19.9169 28.1235L22.6523 33.0877C22.6523 33.0877 20.6216 35.8782 17.6643 35.5117L16.1014 30.8142L19.9169 28.1235Z" fill="#FFA29E" />
                <path fillRule="evenodd" clipRule="evenodd" d="M10.7451 20.7517C10.7451 20.7517 5.20144 30.528 11.8074 32.9386C18.0424 35.2151 20.1535 28.8326 20.1535 28.8326C20.1535 28.8326 22.6177 25.8932 21.5763 23.8342C20.5349 21.7767 12.1739 19.4849 10.7451 20.7517Z" fill="#FFB3B0" />
                <path fillRule="evenodd" clipRule="evenodd" d="M17.3635 29.2475C17.345 29.4627 17.1616 29.6215 16.9539 29.6024C16.7461 29.5833 16.5928 29.3937 16.6111 29.1787C16.6296 28.9637 16.813 28.8047 17.0207 28.8238C17.2285 28.8429 17.3819 29.0325 17.3635 29.2475Z" fill="#EB9599" />
                <path fillRule="evenodd" clipRule="evenodd" d="M9.76575 28.854C9.81253 29.0642 10.0153 29.1955 10.2185 29.1471C10.4217 29.0985 10.5485 28.8887 10.5017 28.6784C10.455 28.468 10.2523 28.3369 10.049 28.3853C9.84576 28.4339 9.71897 28.6436 9.76575 28.854Z" fill="#EB9599" />
                <path fillRule="evenodd" clipRule="evenodd" d="M15.0344 29.6592C14.7725 29.4039 14.5602 29.7472 14.0131 29.8675C13.4777 29.9854 13.1337 29.6998 13.0437 30.0734C12.9685 30.3848 13.3402 31.0258 14.2509 30.889C15.0298 30.7722 15.2679 29.8865 15.0344 29.6592Z" fill="#9C4A4D" />
                <path fillRule="evenodd" clipRule="evenodd" d="M14.0765 29.851C14.4193 29.7748 14.6257 29.6072 14.7976 29.567L14.8218 29.8253C14.8218 29.8253 14.6665 30.0071 14.2531 30.1111C13.7579 30.2355 13.4096 30.0969 13.4096 30.0969L13.3319 29.8597C13.4952 29.8388 13.7486 29.924 14.0765 29.851Z" fill="#FAF9F6" />
                <path fillRule="evenodd" clipRule="evenodd" d="M14.2892 30.8894C14.0948 30.918 13.926 30.9114 13.7788 30.8821C13.9502 30.4411 14.3693 30.1388 14.8496 30.1643C14.9441 30.1695 15.0333 30.1907 15.1197 30.2187C15.0077 30.5164 14.7353 30.8239 14.2892 30.8894Z" fill="#EB9599" />
                <path fillRule="evenodd" clipRule="evenodd" d="M13.63 26.5873L13.2186 28.971L11.9912 28.3438C12.4463 27.5374 13.0903 26.761 13.63 26.5873Z" fill="#9C4A4D" />
                <path fillRule="evenodd" clipRule="evenodd" d="M11.9902 25.7622L10.98 26.1404C10.8783 25.852 11.0216 25.5335 11.3007 25.4289C11.5795 25.3243 11.8881 25.4735 11.9902 25.7622Z" fill="#0F0571" />
                <path fillRule="evenodd" clipRule="evenodd" d="M15.893 26.5874L15.119 25.8955C15.2996 25.6607 15.6195 25.6256 15.8333 25.8165C16.0471 26.0077 16.0739 26.3529 15.893 26.5874Z" fill="#0F0571" />
                <path fillRule="evenodd" clipRule="evenodd" d="M14.6769 27.2698C14.6431 27.5142 14.8136 27.7403 15.0584 27.7748C15.3027 27.8093 15.5282 27.6391 15.5622 27.3948C15.596 27.1505 15.4253 26.9243 15.181 26.8898C14.9364 26.8553 14.7107 27.0255 14.6769 27.2698Z" fill="#132529" />
                <path fillRule="evenodd" clipRule="evenodd" d="M11.1019 26.9719C11.0678 27.2162 11.2386 27.4424 11.4832 27.4769C11.7276 27.5114 11.9533 27.3414 11.9871 27.0971C12.0212 26.8525 11.8501 26.6263 11.6058 26.5918C11.3611 26.5573 11.1354 26.7275 11.1019 26.9719Z" fill="#132529" />
                <path fillRule="evenodd" clipRule="evenodd" d="M13.8456 18.5041C12.0578 19.2877 11.7152 18.3068 10.4428 17.689C9.17201 17.0714 7.53318 20.1825 8.50307 22.8925C9.29566 25.1079 12.0578 23.2561 13.0396 22.5082C13.1186 22.4486 13.2199 22.4277 13.3153 22.4516C13.4106 22.4769 13.4896 22.5439 13.5283 22.6333C14.4058 24.6282 17.5866 25.0081 17.8995 24.9768C18.2332 24.9441 19.7812 27.085 19.9659 28.1025C20.1506 29.1186 22.0785 26.644 22.0785 26.644C25.9327 18.7342 15.6349 17.7204 13.8456 18.5041Z" fill="#0F0571" />
                <path fillRule="evenodd" clipRule="evenodd" d="M19.4166 29.7236L20.2867 26.6843C20.2867 26.6843 22.3024 25.5804 22.1415 27.2117C21.9806 28.8431 20.172 29.8651 19.4166 29.7236Z" fill="#FDB2B0" />
                <path fillRule="evenodd" clipRule="evenodd" d="M18.9912 62.1634L18.6619 62.8025L18.3341 63.4402L15.1563 69.6096C15.1563 69.6096 13.7141 69.848 13.659 67.3376C13.6352 66.25 14.2267 63.9497 14.9075 61.6926C14.9775 61.4542 15.052 61.2173 15.1235 60.9819C15.1712 60.83 15.2189 60.6765 15.2666 60.5231C16.3541 60.8434 17.7531 61.5108 18.9912 62.1634Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M40.9413 48.4852C40.8176 49.1497 40.6165 50.0883 40.317 51.0864C40.2381 51.3472 40.1532 51.6124 40.0593 51.8776C39.9967 52.0608 39.9297 52.2455 39.8582 52.4273C39.1267 51.2563 37.4655 50.641 36.6565 50.4041C36.7459 50.2819 36.8338 50.1613 36.9172 50.0406C37.0632 49.835 37.2048 49.6324 37.3388 49.4312C38.0063 48.4464 38.5337 47.5645 38.775 47.1473C38.8615 46.9998 39.0387 46.9283 39.2041 46.976L40.1785 47.2531C40.7148 47.4065 41.0426 47.9399 40.9413 48.4852Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M15.508 68.9287C15.508 68.9287 9.69758 63.6711 8.74111 65.7435C7.78463 67.8158 10.4067 69.2505 12.1245 70.207C13.8408 71.1635 15.499 71.4749 15.508 68.9287Z" fill="#100875" />
                <path fillRule="evenodd" clipRule="evenodd" d="M11.2116 37.8863C11.2116 37.8863 6.25043 40.7721 5.71259 39.5892C5.17476 38.4063 4.03801 35.2985 4.03801 35.2985L2.5437 35.5443C2.5437 35.5443 2.84316 43.6073 6.36961 43.6073C9.89607 43.6073 12.907 42.5451 12.907 42.5451L11.2116 37.8863Z" fill="#FFB3AF" />
                <path fillRule="evenodd" clipRule="evenodd" d="M2.21387 35.5443C2.21387 35.5443 4.33689 35.8661 4.51567 35.2985C4.69595 34.7294 4.93432 33.5941 4.39649 33.4303C3.85866 33.2649 4.09703 32.0402 3.08096 32.2488C2.06489 32.4589 0.870035 33.6999 2.21387 35.5443Z" fill="#FDAAA7" />
            </g>
            <g clipPath="url(#clip1_624_19539)">
                <path fillRule="evenodd" clipRule="evenodd" d="M48.743 8.08357C48.4492 7.71915 47.8697 7.00016 46.9433 6.89736C46.1836 6.81318 45.5604 7.18415 45.2741 7.38468C44.9135 7.14959 44.1592 6.73079 43.1391 6.69295C41.9162 6.64736 41.036 7.1764 40.5623 7.47005C38.5416 8.72212 37.9674 10.9178 37.8625 11.356C37.8019 11.6096 37.1614 14.4789 38.9056 16.694C39.184 17.0474 39.6411 17.6265 40.4395 18.0436C41.494 18.5949 42.1552 18.3465 43.5686 19.0254C44.3442 19.3979 44.2589 19.5275 44.7344 19.7003C46.127 20.2069 47.8188 19.4336 48.6612 18.78C51.7571 16.3789 50.428 10.174 48.743 8.08357Z" fill="#E35147" />
                <path fillRule="evenodd" clipRule="evenodd" d="M30.4774 67.4572C30.4774 67.4572 28.4683 66.4948 28.1699 66.8747C27.8715 67.2546 27.2215 70.4309 28.2533 71.8552C29.2855 73.281 29.8415 72.1815 29.7189 71.1624C29.5963 70.1449 31.1967 67.7149 30.4774 67.4572Z" fill="#91B3FA" />
                <path d="M46.7381 60.045C49.8033 55.416 49.4021 50.3223 49.1352 48.3572C47.2437 47.946 45.3782 47.575 43.6223 47.1936C43.861 48.9799 44.2245 53.585 41.6497 58.3197C37.7599 65.474 30.1674 67.4033 29.794 67.3795C29.785 67.3795 29.7242 67.3735 29.6719 67.4093C29.3496 67.6342 30.0171 68.8276 30.0697 68.9095C30.0828 68.9304 30.119 68.9855 30.1708 68.9885C30.1844 68.99 30.1953 68.9855 30.2009 68.984C30.2009 68.984 33.6226 68.4849 36.8034 67.1813C39.8 65.9537 43.9632 64.2359 46.7381 60.045Z" fill="url(#paint0_linear_624_19539)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M46.7381 60.045C49.8033 55.416 49.4021 50.3223 49.1352 48.3572C47.2437 47.946 45.3782 47.575 43.6223 47.1936C43.861 48.9799 44.2245 53.585 41.6497 58.3197C37.7599 65.474 30.1674 67.4033 29.794 67.3795C29.785 67.3795 29.7242 67.3735 29.6719 67.4093C29.3496 67.6342 30.0171 68.8276 30.0697 68.9095C30.0828 68.9304 30.119 68.9855 30.1708 68.9885C30.1844 68.99 30.1953 68.9855 30.2009 68.984C30.2009 68.984 33.6226 68.4849 36.8034 67.1813C39.8 65.9537 43.9632 64.2359 46.7381 60.045Z" fill="url(#paint1_linear_624_19539)" />
                <path d="M38.3684 62.6047C34.7098 65.787 30.0612 67.3961 29.794 67.3797C29.785 67.3797 29.7242 67.3737 29.6719 67.4095C29.3496 67.6344 30.0171 68.8278 30.0697 68.9097C30.0828 68.9306 30.119 68.9857 30.1708 68.9887C30.1844 68.9902 30.1953 68.9857 30.2009 68.9842C30.2009 68.9842 33.6226 68.4851 36.8034 67.1815C37.932 66.7182 39.8286 65.8526 41.1515 65.1449C40.7746 65.0451 40.3281 64.7948 39.7055 64.321C39.0245 63.8026 38.5613 62.9578 38.3684 62.6047Z" fill="url(#paint2_linear_624_19539)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M38.3684 62.6047C34.7098 65.787 30.0612 67.3961 29.794 67.3797C29.785 67.3797 29.7242 67.3737 29.6719 67.4095C29.3496 67.6344 30.0171 68.8278 30.0697 68.9097C30.0828 68.9306 30.119 68.9857 30.1708 68.9887C30.1844 68.9902 30.1953 68.9857 30.2009 68.9842C30.2009 68.9842 33.6226 68.4851 36.8034 67.1815C37.932 66.7182 39.8286 65.8526 41.1515 65.1449C40.7746 65.0451 40.3281 64.7948 39.7055 64.321C39.0245 63.8026 38.5613 62.9578 38.3684 62.6047Z" fill="url(#paint3_linear_624_19539)" />
                <path d="M68.4215 27.3414C67.1256 26.6218 64.6171 27.1552 63.2813 27.5217C61.4488 27.5485 59.3147 27.4412 56.9597 27.0569C54.4261 26.6427 52.7983 25.0068 51.0315 24.3662C50.8229 24.4109 49.6138 25.5804 49.1236 26.1928C48.7134 26.7053 48.4547 27.4874 48.6577 28.0431C48.9047 28.721 49.8094 28.9937 53.2877 29.3855C56.6127 29.7609 62.4866 29.0458 63.2288 28.949C64.8435 29.3319 68.4224 29.6686 69.2965 29.8935C70.2817 30.1483 70.6189 29.3035 70.6189 29.3035C70.6189 29.3035 70.24 28.3515 68.4215 27.3414Z" fill="url(#paint4_linear_624_19539)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M68.4215 27.3414C67.1256 26.6218 64.6171 27.1552 63.2813 27.5217C61.4488 27.5485 59.3147 27.4412 56.9597 27.0569C54.4261 26.6427 52.7983 25.0068 51.0315 24.3662C50.8229 24.4109 49.6138 25.5804 49.1236 26.1928C48.7134 26.7053 48.4547 27.4874 48.6577 28.0431C48.9047 28.721 49.8094 28.9937 53.2877 29.3855C56.6127 29.7609 62.4866 29.0458 63.2288 28.949C64.8435 29.3319 68.4224 29.6686 69.2965 29.8935C70.2817 30.1483 70.6189 29.3035 70.6189 29.3035C70.6189 29.3035 70.24 28.3515 68.4215 27.3414Z" fill="url(#paint5_linear_624_19539)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M54.0783 38.7151L42.7093 42.3518C42.6972 47.0865 42.3882 49.4479 41.8298 54.5908C43.2617 54.5536 47.8966 56.6632 49.4957 55.7827C51.2161 54.8352 53.0731 51.8257 53.3908 51.4011C55.426 48.6791 54.9817 45.041 54.6857 42.6155C54.6857 42.6155 54.6781 42.5544 54.0783 38.7151Z" fill="#E2493E" />
                <path fillRule="evenodd" clipRule="evenodd" d="M72.2063 66.4126C72.2063 66.4126 70.9045 68.8083 71.3507 69.1971C71.7968 69.5845 75.6389 70.5767 77.4439 69.4042C79.2496 68.2317 77.94 67.4838 76.6885 67.5702C75.4371 67.6566 72.566 65.5485 72.2063 66.4126Z" fill="#91B3FA" />
                <path d="M58.9065 47.3801C56.0074 47.0463 54.8973 46.7052 52.7113 45.1364L49.4388 51.5769C49.4388 51.5769 61.7552 53.5465 64.7881 57.6466C67.8209 61.7466 71.6662 67.2843 71.6662 67.2843C71.6662 67.2843 71.7596 67.995 72.9771 67.0936C73.1598 66.9581 73.2358 66.7212 73.1729 66.5037C72.6492 64.6831 70.1618 56.3668 67.5164 53.0921C66.2045 51.4667 61.8418 48.8997 58.9065 47.3801Z" fill="url(#paint6_linear_624_19539)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M58.9065 47.3801C56.0074 47.0463 54.8973 46.7052 52.7113 45.1364L49.4388 51.5769C49.4388 51.5769 61.7552 53.5465 64.7881 57.6466C67.8209 61.7466 71.6662 67.2843 71.6662 67.2843C71.6662 67.2843 71.7596 67.995 72.9771 67.0936C73.1598 66.9581 73.2358 66.7212 73.1729 66.5037C72.6492 64.6831 70.1618 56.3668 67.5164 53.0921C66.2045 51.4667 61.8418 48.8997 58.9065 47.3801Z" fill="url(#paint7_linear_624_19539)" />
                <path d="M65.2068 58.2126C68.1635 62.2367 71.6682 67.2843 71.6682 67.2843C71.6682 67.2843 71.7616 67.995 72.9791 67.0936C73.1618 66.958 73.2378 66.7211 73.1749 66.5036C72.7141 64.902 70.7335 58.2737 68.4627 54.4731C68.3012 54.9991 67.9347 55.9436 67.0956 56.8435C66.4064 57.5824 65.677 57.9936 65.2068 58.2126Z" fill="url(#paint8_linear_624_19539)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M65.2068 58.2126C68.1635 62.2367 71.6682 67.2843 71.6682 67.2843C71.6682 67.2843 71.7616 67.995 72.9791 67.0936C73.1618 66.958 73.2378 66.7211 73.1749 66.5036C72.7141 64.902 70.7335 58.2737 68.4627 54.4731C68.3012 54.9991 67.9347 55.9436 67.0956 56.8435C66.4064 57.5824 65.677 57.9936 65.2068 58.2126Z" fill="url(#paint9_linear_624_19539)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M62.1586 48.8654C56.9622 43.6703 47.9441 40.1647 47.2455 37.0271L41.9586 38.8477C41.5765 40.5997 40.6459 45.8559 43.4875 48.6464C44.5104 49.6505 45.9583 50.2733 48.7133 51.4562C50.55 52.2458 54.9046 54.1662 55.9676 54.497C57.7746 52.8403 60.3518 50.5221 62.1586 48.8654Z" fill="#E85A52" />
                <path d="M51.9995 23.7988C51.8811 23.9538 49.1235 26.4269 47.8904 27.5473C49.6021 28.8345 51.2485 29.782 52.8066 30.4644C52.9476 30.1754 53.085 29.9265 53.1458 29.8178C53.7684 28.6959 54.4244 27.5979 55.1535 26.5521C55.3737 26.2362 55.273 25.7997 54.9306 25.6224C53.8272 25.0518 52.856 24.429 51.9995 23.7988Z" fill="url(#paint10_linear_624_19539)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M51.9995 23.7988C51.8811 23.9538 49.1235 26.4269 47.8904 27.5473C49.6021 28.8345 51.2485 29.782 52.8066 30.4644C52.9476 30.1754 53.085 29.9265 53.1458 29.8178C53.7684 28.6959 54.4244 27.5979 55.1535 26.5521C55.3737 26.2362 55.273 25.7997 54.9306 25.6224C53.8272 25.0518 52.856 24.429 51.9995 23.7988Z" fill="#DDBE90" />
                <path d="M54.4456 39.8967C54.6097 39.8669 54.7209 39.7164 54.7026 39.551L53.1575 25.621C52.8255 23.7244 51.2572 22.2986 49.3397 22.1288C48.2329 22.029 47.0802 22.0647 45.7106 22.2346C43.7872 22.473 41.1014 24.5349 40.7568 26.4419L40.6723 40.3525C40.5127 41.236 41.0452 42.0271 41.9381 41.9467C44.0834 41.7515 52.7474 40.2021 54.4456 39.8967Z" fill="url(#paint11_linear_624_19539)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M54.4456 39.8967C54.6097 39.8669 54.7209 39.7164 54.7026 39.551L53.1575 25.621C52.8255 23.7244 51.2572 22.2986 49.3397 22.1288C48.2329 22.029 47.0802 22.0647 45.7106 22.2346C43.7872 22.473 41.1014 24.5349 40.7568 26.4419L40.6723 40.3525C40.5127 41.236 41.0452 42.0271 41.9381 41.9467C44.0834 41.7515 52.7474 40.2021 54.4456 39.8967Z" fill="#DDBE90" />
                <path d="M41.4428 26.8275C41.4186 26.3671 40.8008 25.0844 40.5801 25.0576C36.7821 24.5987 34.0533 28.8358 31.8087 31.7112C30.9978 32.7496 29.7578 35.2645 29.2323 36.9063C29.2305 36.9122 29.5591 36.437 29.1008 37.1134C28.6424 37.7883 27.0193 41.5278 27.5656 42.8001C27.6139 42.9133 27.7897 43.7759 28.4601 44.5596C28.6967 44.8367 28.9726 45.1511 29.2702 45.1108C29.6277 45.0617 29.8378 44.5253 30.0213 43.9368C31.0546 40.6205 30.5558 37.8747 30.4625 37.4843C30.2932 36.7752 30.4248 37.3145 30.4264 37.3115C31.3135 35.5729 32.4563 34.0279 33.7839 32.7571C35.8323 30.7949 37.9957 29.5539 40.5654 28.4857C41.1093 28.1177 41.4744 27.4294 41.4428 26.8275Z" fill="url(#paint12_linear_624_19539)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M41.4428 26.8275C41.4186 26.3671 40.8008 25.0844 40.5801 25.0576C36.7821 24.5987 34.0533 28.8358 31.8087 31.7112C30.9978 32.7496 29.7578 35.2645 29.2323 36.9063C29.2305 36.9122 29.5591 36.437 29.1008 37.1134C28.6424 37.7883 27.0193 41.5278 27.5656 42.8001C27.6139 42.9133 27.7897 43.7759 28.4601 44.5596C28.6967 44.8367 28.9726 45.1511 29.2702 45.1108C29.6277 45.0617 29.8378 44.5253 30.0213 43.9368C31.0546 40.6205 30.5558 37.8747 30.4625 37.4843C30.2932 36.7752 30.4248 37.3145 30.4264 37.3115C31.3135 35.5729 32.4563 34.0279 33.7839 32.7571C35.8323 30.7949 37.9957 29.5539 40.5654 28.4857C41.1093 28.1177 41.4744 27.4294 41.4428 26.8275Z" fill="url(#paint13_linear_624_19539)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M45.4942 17.6613L44.5069 22.4906C44.4283 22.875 44.647 23.2578 45.0182 23.383C45.8677 23.672 47.4163 24.0668 48.7856 23.7167C49.1314 23.6273 49.3766 23.3204 49.3783 22.9643L49.4034 17.9025L45.4942 17.6613Z" fill="#FFA8A7" />
                <path fillRule="evenodd" clipRule="evenodd" d="M41.8285 16.7689C43.2034 19.8 46.4103 22.0958 49.8817 20.3617C52.2837 19.1623 52.3479 16.5177 50.9729 13.4869C49.5982 10.4558 46.6565 8.7087 44.2114 9.81789C41.7663 10.9269 40.4537 13.7378 41.8285 16.7689Z" fill="#FFA09D" />
                <path fillRule="evenodd" clipRule="evenodd" d="M42.0462 16.769C43.421 19.8001 46.6279 22.096 50.0994 20.3618C52.5013 19.1625 52.5655 16.5178 51.1905 13.487C49.8159 10.456 46.8742 8.70882 44.429 9.81801C41.9839 10.9271 40.6714 13.7379 42.0462 16.769Z" fill="#FFA09D" />
                <path fillRule="evenodd" clipRule="evenodd" d="M51.0338 17.0316C51.0153 17.2467 50.8319 17.4055 50.6242 17.3865C50.4164 17.3674 50.2631 17.1777 50.2814 16.9628C50.2999 16.7478 50.4833 16.5888 50.691 16.6079C50.8988 16.6269 51.0522 16.8166 51.0338 17.0316Z" fill="#EB9599" />
                <path fillRule="evenodd" clipRule="evenodd" d="M44.4785 17.5319C44.5252 17.7421 44.728 17.8733 44.9312 17.8249C45.1344 17.7763 45.2612 17.5666 45.2144 17.3562C45.1677 17.1458 44.965 17.0147 44.7617 17.0632C44.5585 17.1117 44.4317 17.3215 44.4785 17.5319Z" fill="#EB9599" />
                <path fillRule="evenodd" clipRule="evenodd" d="M46.2244 17.7063C46.4789 17.3974 46.7575 17.7326 47.375 17.7828C47.9791 17.8318 48.3197 17.4786 48.4679 17.8633C48.5914 18.1842 48.2671 18.9193 47.2477 18.9035C46.3758 18.8902 45.9973 17.9816 46.2244 17.7063Z" fill="#B56262" />
                <path fillRule="evenodd" clipRule="evenodd" d="M47.3743 17.7827C46.9729 17.75 46.7152 17.5982 46.5139 17.5797L46.5213 17.8644C46.5213 17.8644 46.7226 18.0399 47.2077 18.0921C47.789 18.1546 48.1672 17.9521 48.1672 17.9521L48.2238 17.6821C48.0346 17.6833 47.7577 17.8138 47.3743 17.7827Z" fill="#FAF9F6" />
                <path fillRule="evenodd" clipRule="evenodd" d="M47.2464 18.9036C47.4591 18.907 47.6395 18.8762 47.7938 18.8248C47.5519 18.3808 47.0621 18.1188 46.5495 18.2128C46.4486 18.2312 46.3553 18.2662 46.2662 18.308C46.4255 18.6082 46.7584 18.8962 47.2464 18.9036Z" fill="#EB9599" />
                <path fillRule="evenodd" clipRule="evenodd" d="M47.7568 14.52L48.144 16.9038L49.2992 16.2765C48.8709 15.4701 48.2647 14.6937 47.7568 14.52Z" fill="#C85C60" />
                <path fillRule="evenodd" clipRule="evenodd" d="M49.2991 13.6951L50.2498 14.0733C50.3456 13.7849 50.2107 13.4663 49.948 13.3618C49.6856 13.2572 49.3951 13.4064 49.2991 13.6951Z" fill="#E55149" />
                <path fillRule="evenodd" clipRule="evenodd" d="M45.6248 14.5201L46.3533 13.8282C46.1833 13.5934 45.8822 13.5583 45.681 13.7492C45.4798 13.9404 45.4546 14.2856 45.6248 14.5201Z" fill="#E55149" />
                <path fillRule="evenodd" clipRule="evenodd" d="M46.7705 15.2026C46.8023 15.4469 46.6418 15.6731 46.4114 15.7076C46.1815 15.7421 45.9693 15.5719 45.9372 15.3276C45.9054 15.0832 46.0661 14.8571 46.2961 14.8226C46.5263 14.7881 46.7387 14.9583 46.7705 15.2026Z" fill="#132529" />
                <path fillRule="evenodd" clipRule="evenodd" d="M50.1366 14.9045C50.1686 15.1488 50.0079 15.375 49.7776 15.4095C49.5476 15.444 49.3352 15.274 49.3034 15.0297C49.2713 14.7851 49.4323 14.559 49.6623 14.5245C49.8925 14.49 50.105 14.6602 50.1366 14.9045Z" fill="#132529" />
                <path fillRule="evenodd" clipRule="evenodd" d="M42.971 17.2565C42.971 17.2565 41.8762 15.6403 41.3434 16.6509C40.8106 17.6614 43.1558 18.9912 43.7428 18.8035C44.3298 18.6176 42.971 17.2565 42.971 17.2565Z" fill="#FFA59D" />
                <path d="M44.0931 24.4246C42.8874 22.75 41.411 23.0957 39.776 24.1207C39.3858 24.3203 39.0058 24.5036 38.6349 24.6734C38.2744 24.8373 38.1588 25.2813 38.3784 25.6105C38.9819 26.5179 39.4143 27.5295 39.797 28.556C39.9158 28.8733 40.2675 29.0387 40.5895 28.9299C41.4442 28.6409 42.4176 28.2788 43.5544 27.8021C44.3984 27.4475 44.8565 25.4839 44.0931 24.4246Z" fill="url(#paint14_linear_624_19539)" />
                <path fillRule="evenodd" clipRule="evenodd" d="M44.0931 24.4246C42.8874 22.75 41.411 23.0957 39.776 24.1207C39.3858 24.3203 39.0058 24.5036 38.6349 24.6734C38.2744 24.8373 38.1588 25.2813 38.3784 25.6105C38.9819 26.5179 39.4143 27.5295 39.797 28.556C39.9158 28.8733 40.2675 29.0387 40.5895 28.9299C41.4442 28.6409 42.4176 28.2788 43.5544 27.8021C44.3984 27.4475 44.8565 25.4839 44.0931 24.4246Z" fill="#DDBE90" />
                <path fillRule="evenodd" clipRule="evenodd" d="M41.1507 16.2592C41.1507 16.2592 44.9036 18.6365 44.6735 11.6899C44.6735 11.6899 46.7044 13.2421 46.933 11.3296C48.9054 11.0333 49.4505 10.7411 49.4585 10.4969C49.4822 9.75619 44.6143 7.70006 42.0142 9.99814C40.9155 10.9691 40.3537 12.601 40.4742 14.107C40.5526 15.0852 40.9045 15.831 41.1507 16.2592Z" fill="#E55149" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_624_19539" x1="776.738" y1="950.784" x2="1505.9" y2="1523.19" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF928E" />
                    <stop offset="1" stopColor="#FDB3B0" />
                </linearGradient>
                <linearGradient id="paint1_linear_624_19539" x1="776.738" y1="950.784" x2="1505.9" y2="1523.19" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF928E" />
                    <stop offset="1" stopColor="#FDB3B0" />
                </linearGradient>
                <linearGradient id="paint2_linear_624_19539" x1="290.939" y1="332.595" x2="732.48" y2="679.215" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#08005D" />
                    <stop offset="1" stopColor="#1A0F91" />
                </linearGradient>
                <linearGradient id="paint3_linear_624_19539" x1="290.939" y1="332.595" x2="732.48" y2="679.215" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#08005D" />
                    <stop offset="1" stopColor="#1A0F91" />
                </linearGradient>
                <linearGradient id="paint4_linear_624_19539" x1="270.298" y1="335.343" x2="1612.68" y2="-363.541" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF928E" />
                    <stop offset="1" stopColor="#FDB3B0" />
                </linearGradient>
                <linearGradient id="paint5_linear_624_19539" x1="270.298" y1="335.343" x2="1612.68" y2="-363.541" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF928E" />
                    <stop offset="1" stopColor="#FDB3B0" />
                </linearGradient>
                <linearGradient id="paint6_linear_624_19539" x1="1037.1" y1="1507.19" x2="1702.67" y2="742.802" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF928E" />
                    <stop offset="1" stopColor="#FDB3B0" />
                </linearGradient>
                <linearGradient id="paint7_linear_624_19539" x1="1037.1" y1="1507.19" x2="1702.67" y2="742.802" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF928E" />
                    <stop offset="1" stopColor="#FDB3B0" />
                </linearGradient>
                <linearGradient id="paint8_linear_624_19539" x1="414.877" y1="1014.23" x2="780.466" y2="594.363" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#08005D" />
                    <stop offset="1" stopColor="#1A0F91" />
                </linearGradient>
                <linearGradient id="paint9_linear_624_19539" x1="414.877" y1="1014.23" x2="780.466" y2="594.363" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#08005D" />
                    <stop offset="1" stopColor="#1A0F91" />
                </linearGradient>
                <linearGradient id="paint10_linear_624_19539" x1="0.245218" y1="1186.22" x2="-1077.81" y2="3868.81" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FD6F61" />
                    <stop offset="1" stopColor="#FF928E" />
                </linearGradient>
                <linearGradient id="paint11_linear_624_19539" x1="669.399" y1="1495.09" x2="-522.843" y2="4461.82" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FD6F61" />
                    <stop offset="1" stopColor="#FF928E" />
                </linearGradient>
                <linearGradient id="paint12_linear_624_19539" x1="551.113" y1="842.214" x2="922.576" y2="1016.42" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF928E" />
                    <stop offset="1" stopColor="#FDB3B0" />
                </linearGradient>
                <linearGradient id="paint13_linear_624_19539" x1="551.113" y1="842.214" x2="922.576" y2="1016.42" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF928E" />
                    <stop offset="1" stopColor="#FDB3B0" />
                </linearGradient>
                <linearGradient id="paint14_linear_624_19539" x1="33.9461" y1="939.199" x2="-1073.18" y2="3694.14" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FD6F61" />
                    <stop offset="1" stopColor="#FF928E" />
                </linearGradient>
                <clipPath id="clip0_624_19539">
                    <rect width="42.7584" height="53.4853" fill="white" transform="translate(1.56152 17.4996)" />
                </clipPath>
                <clipPath id="clip1_624_19539">
                    <rect width="51.1015" height="66" fill="white" transform="translate(27.3361 6.62402)" />
                </clipPath>
            </defs>
        </svg>

    )
}