
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import axios from "axios";
import { ENDPOINTS, REST_API } from "../../const/constants";

export interface IAuth {
    refresh: string;
    access: string;
}

interface IUpdateRelated {
    id: string;
    username: string;
}

interface UpdateRelatedInitialStateType {
    loading: boolean,
    data: IUpdateRelated
    success: boolean,
    error: boolean,
    errors: any,
}

const initialState: UpdateRelatedInitialStateType = {
    loading: false,
    data: {} as IUpdateRelated,
    success: false,
    error: false,
    errors: {},
};

interface IUpdateRelatedReq {
    token: string | null;
    id: string;
    data: {
        first_name?: string
        last_name?: string
        birth_date?: string
        gender?: string
        bio?: string
        middle_name?: string
        category?: string
        country?: string | number,
        city_name?: string
    }
}

export const updateRelated = createAsyncThunk(
    "updateRelated",
    async (req: IUpdateRelatedReq, { rejectWithValue }) => {
        const { token, id, data } = req
        const url = REST_API + ENDPOINTS.user.related + id + "/";
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        try {
            const response = await axios.post(url, { ...data }, { headers: headers });
            return response.data;
        } catch (err) {
            const error: any = err;
            return rejectWithValue(error.response);
        }
    }
);

export const updateRelatedSlice = createSlice({
    name: "updateRelated",
    initialState,
    reducers: {
        updateRelatedAction: () => { },
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateRelated.pending, (state: any) => {
                state.loading = true;
                state.success = false;
                state.errors.email = "";
            })
            .addCase(updateRelated.fulfilled, (state: any, { payload }: any) => {
                state.loading = false;
                state.data = payload
                state.error = false;
                state.success = true;
            })
            .addCase(updateRelated.rejected, (state: any, { payload }: any) => {
                const payl: any = payload;
                state.loading = false;
                state.success = false;
                state.error = true;
            });
    },
});

export const UpdateRelated = (state: RootState) => state.updateRelated;

export const UpdateRelatedReducer = updateRelatedSlice.reducer;
