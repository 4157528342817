
import s from './mn-input.module.scss'
import { FC} from "react";
import classnames from 'classnames'
import ReactTextareaAutosize from 'react-textarea-autosize';
const cn = classnames.bind(this)



interface ICustomInout {
    defaultValue?: string | number
    name: string;
    icon?: any;
    type: "text" | "password" | "email" | "textarea";
    onChange: (e: any) => any;
    placeholder?: string;
    style?: any
}

export const MnInput: FC<ICustomInout> = ({ name, icon, type = "text", defaultValue, onChange, placeholder, style }) => {
    if (type === "textarea") {
        return (<>
            <div style={style} className={s.mnInput}>
                {icon && <div className={s.icon}>{icon}</div>}
                <ReactTextareaAutosize
                    className={cn(s.input, s.textarea)}
                    defaultValue={defaultValue}
                    name={name}
                    onChange={onChange}
                    placeholder={' '}
                >
                    {/* {defaultValue} */}
                </ReactTextareaAutosize>
                <span>{placeholder}</span>
            </div>
        </>)
    }

    return (<>
        <div style={style} className={s.mnInput}>
            {icon && <div className={s.icon}>{icon}</div>}
            <input

                className={s.input}
                defaultValue={defaultValue}
                name={name}
                type={type}
                onChange={onChange}
                placeholder={' '}
            />
            <span>{placeholder}</span>
        </div>
    </>)
}