
import { FC } from 'react';
import s from './custom-button.module.scss';

interface ICustomButton {
    children: any;
    type: "primary" | "secondary" | 'submit';
    style?: any;
    onClick: () => void;
    disabled?: boolean
}

export const CustomButtom: FC<ICustomButton> = ({ children, type, style, onClick, disabled }) => {
    return (<>
        <button
        disabled={disabled}
        type={ type === 'submit' ? 'submit' : 'button'}
        onClick={onClick}
        className={type==="secondary" ? s.btnSecondary : s.btnPrimary}
        style={style}
        >{children}</button>
    </>)
}