
import s from './custom-checkbox.module.scss'
import { FC } from "react";
import classnames from 'classnames'
const cn = classnames.bind(this)

interface ICustomInout {
    value?: boolean
    onChange: (e: any) => any;
    style?: any;
}

export const CustomCheckbox: FC<ICustomInout> = ({ value, onChange, style }) => {

    return (<>
        <div onClick={()=>onChange(!value)} style={style} className={s.checkbox}>
            <div className={cn(s.chButton, {[s.chTrue]: value})}>
                <span></span>
            </div>
        </div>
    </>)
}