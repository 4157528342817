import { FC } from "react"

interface IIcon {
    fill?: string;
    stroke?: string;
    size?: number
}

export const EditIcon: FC<IIcon> = ({ fill = "#818181", size = 20 }) => {
    const viewBox = `0 0 ${size} ${size}`
    return (
        <svg width={size} height={size}  viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 17.3929H20V18.8214H0V17.3929ZM16.7143 5.25C17.2857 4.67857 17.2857 3.82143 16.7143 3.25L14.1429 0.678571C13.5714 0.107143 12.7143 0.107143 12.1429 0.678571L1.42857 11.3929V15.9643H6L16.7143 5.25ZM13.1429 1.67857L15.7143 4.25L13.5714 6.39286L11 3.82143L13.1429 1.67857ZM2.85714 14.5357V11.9643L10 4.82143L12.5714 7.39286L5.42857 14.5357H2.85714Z" fill={fill} />
        </svg>

    )
}