import { ConfirmEmailIcon } from '../../../icons/confirm-email-icon'
import s from './email-reminder.module.scss'
import { CustomButtom } from '../CustomButton/CustomButton'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { ResendEmail, resendEmail } from '../../../store/slice/resendEmailSlice'
import { Spin, message } from 'antd'
import { FC } from 'react'
import { setReminder, setSupport } from '../../../store/slice/staticSlice'

interface IReminder {
    onClose: (state: boolean) => void
}

export const EmailReminder:FC<IReminder> = ({onClose}) => {
    const dispatch = useAppDispatch()
    const { loading } = useAppSelector(ResendEmail)

    const sendEmail = () => {
        dispatch(resendEmail({token: localStorage.getItem('access')})).then((resp:any)=>{
            if(!resp.error){
                message.success("Письмо повторно отправлено на Вашу почту")
                onClose(false)
            }
        })
    }

    return (<>
        <Spin spinning={loading}>
            <div className={s.reminder}>
                <div className={s.reminderIcon}><ConfirmEmailIcon /></div>
                <div className={s.reminderTitle}>Пожалуйста, подтвердите свою почту 🙏 </div>
                <div className={s.reminderDesc}>Воспользуйтесь ссылкой, которую мы отправили вам в письме с подтверждением, чтобы продолжить
                    пользоваться приложением. </div>

                <div className={s.reminderButtons}>
                    <CustomButtom
                        onClick={sendEmail}
                        type='primary'
                    >Отправить письмо повторно
                    </CustomButtom>
                    <CustomButtom
                        onClick={() => {
                            console.log("alala")
                            dispatch(setReminder(false))
                            dispatch(setSupport(true)) 
                        }}
                        type='secondary'
                    >Связаться с поддержкой
                    </CustomButtom>
                </div>
            </div>
        </Spin>
    </>)
}