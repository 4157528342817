
import React, { FC, useEffect, useState } from "react"
import { Login } from "./Login"
import { Registration } from "./Registration"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { MeSlice, getMe } from "../../store/slice/meSlice"
import { useNavigate } from "react-router-dom"
import { customRoute } from "../../utilities"
import { Helmet } from "react-helmet";

interface IAuth {
    loginProp?: boolean
}

export const Authentication:FC<IAuth> = ({loginProp = true}) => {
    const [isLogin, setIsLogin] = useState(loginProp)
    const { success: meSuccess, data, completed } = useAppSelector(MeSlice)
    const router = useNavigate()
    const dispatch = useAppDispatch()

    const switcher = (state: boolean) => {
        setIsLogin(state)
    }

    useEffect(() => {
        const savedUser = localStorage.getItem('savedUserId')
        if (meSuccess) {
            if (meSuccess && completed && !savedUser) {
                customRoute('/profile')
            } else if (meSuccess && completed && !!savedUser){
                customRoute(`/user/${savedUser}`)
            }
             else {
                setIsLogin(false)
            }
        } else {
            const _token = localStorage.getItem('access')
            !!_token && dispatch(getMe({ token: _token }))
        }
    }, [meSuccess, data])


    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Мои награды</title>
            <meta name="description" content="Ваши награды и результаты соревнований"
            />
        </Helmet>
        {isLogin ? <Login changeMode={switcher} /> : <Registration changeMode={switcher} />}
    </>
    )
}