import moment from "moment";

export const DEV_MODE = process.env.DEV_MODE ? process.env.REACT_APP_REACT_APP_TEST : false

export const REST_API = process.env.REACT_APP_SERVER_URL

export const GTAG_TRACKING_ID = process.env.REACT_APP_NEXT_PUBLIC_GTAG_ID

export const API_VER = {
    v1: "v1"
};

export const ENDPOINTS = {
    appeal: `${API_VER.v1}/appeals/`,
    auth: {
        token: `${API_VER.v1}/token/`,
        refresh: `${API_VER.v1}/token/refresh/`,
        oauth: `${API_VER.v1}/oauth/signin/`,
        oauthTokens: `${API_VER.v1}/oauth/tokens/`,
        oauthCode: `${API_VER.v1}/oauth/oauth-code/`,
        openId: `accounts/login/`
    },
    user: {
        user: `${API_VER.v1}/users/`,
        me: `${API_VER.v1}/users/me/`,
        photo: `${API_VER.v1}/users/my-photo/`,
        password: `${API_VER.v1}/users/password/`,
        verify: `${API_VER.v1}/users/verify/`,
        send_email: `${API_VER.v1}/users/verify/send-mail/`,
        related: `${API_VER.v1}/users/related/`,
        switch: `${API_VER.v1}/switch/account/`,
        back: `${API_VER.v1}/switch/back/`,
        unsubscribe: `${API_VER.v1}/users/email-unsubscribe/`,
        restore: `${API_VER.v1}/users/restore/`,
    },
    sports: {
        sports: `${API_VER.v1}/sports/`,
    },
    rewards: {
        rewards: `${API_VER.v1}/rewards/`,
        user: `${API_VER.v1}/rewards/users/`
    },
    countries: {
        countries: `${API_VER.v1}/countries/`
    },
    subscriptions: {
        free: `${API_VER.v1}/subscriptions/activate-free/`,
        types: `${API_VER.v1}/subscription-types/`,
        buy: `${API_VER.v1}/subscriptions/buy/`,
        cancel: `${API_VER.v1}/subscriptions/cancel/`,
        list: `${API_VER.v1}/subscriptions/`,
        renewFree: `${API_VER.v1}/subscriptions/renew-free/`,
        infinity: `${API_VER.v1}/subscriptions/infinity/`
    },
    distances: {
        distances: `${API_VER.v1}/distances/`
    },
    albums: {
        albums: `${API_VER.v1}/albums/`
    },
    password_reset: {
        request: `${API_VER.v1}/password-reset/requested/`,
        confirm: `${API_VER.v1}/password-reset/confirm/`
    }
}

export const DATES = [
    // { label: '--', value: '--' },
    { label: '01', value: '01' },
    { label: '02', value: '02' },
    { label: '03', value: '03' },
    { label: '04', value: '04' },
    { label: '05', value: '05' },
    { label: '06', value: '06' },
    { label: '07', value: '07' },
    { label: '08', value: '08' },
    { label: '09', value: '09' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
    { label: '13', value: '13' },
    { label: '14', value: '14' },
    { label: '15', value: '15' },
    { label: '16', value: '16' },
    { label: '17', value: '17' },
    { label: '18', value: '18' },
    { label: '19', value: '19' },
    { label: '20', value: '20' },
    { label: '21', value: '21' },
    { label: '22', value: '22' },
    { label: '23', value: '23' },
    { label: '24', value: '24' },
    { label: '25', value: '25' },
    { label: '26', value: '26' },
    { label: '27', value: '27' },
    { label: '28', value: '28' },
    { label: '29', value: '29' },
    { label: '30', value: '30' },
    { label: '31', value: '31' },
]

export const MONTHES = [
    // { label: '--', value: '--' },
    { label: 'Январь', value: '01' },
    { label: 'Февраль', value: '02' },
    { label: 'Март', value: '03' },
    { label: 'Апрель', value: '04' },
    { label: 'Май', value: '05' },
    { label: 'Июнь', value: '06' },
    { label: 'Июль', value: '07' },
    { label: 'Август', value: '08' },
    { label: 'Сентябрь', value: '09' },
    { label: 'Октябрь', value: '10' },
    { label: 'Ноябрь', value: '11' },
    { label: 'Декабрь', value: '12' },
]

type OptionType = { label: string, value: number | string };

export const YEARS = () => {
    const _y = moment().format('YYYY')
    let _years = []
    let _formatted: OptionType[] = []
    for (let i = 1900; i <= +_y; i++) {
        _years.push(i.toString())
        // if (i.toString() === _y) {
        //     _years.push('--')
        // }
    }
    _years.map(year => {
        _formatted.push({ label: year, value: +year })
    })

    return _formatted.reverse()
}

//Browsers
//@ts-ignore
export var iOS = !window.MSStream && /iPad|iPhone|iPod/.test(navigator.userAgent); // fails on iPad iOS 13
