
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import axios from "axios";
import { ENDPOINTS, REST_API } from "../../const/constants";

export interface IAuth {
    refresh: string;
    access: string;
}

interface IUpdateUser {
    id: string;
    email: string;
    tokens: IAuth;
}

interface UpdateUserInitialStateType {
    loading: boolean,
    data: IUpdateUser
    success: boolean,
    error: boolean,
    errors: any,
}

const initialState: UpdateUserInitialStateType = {
    loading: false,
    data: {} as IUpdateUser,
    success: false,
    error: false,
    errors: {},
};

interface IUpdateUserReq {
    token: string | null;
    id: string;
    data: {
        username?: string,
        first_name?: string,
        last_name?: string,
        phone_number?: string,
        gender?: string,
        bio?: string,
        middle_name?: string,
        category?: string,
        full_name?: string,
        birth_date?: string,
        country?: string | number,
        city_name?: string
    }
}

export const updateUser = createAsyncThunk(
    "updateUser",
    async (req: IUpdateUserReq, { rejectWithValue }) => {
        const {token, id, data} = req
        console.log(data)
        const headers = {
            Authorization: `Bearer ${token}`,
          };
        const url = REST_API + ENDPOINTS.user.user + id + "/";
        try {
            const response = await axios.post(url, { ...data }, {headers: headers});
            return response.data;
        } catch (err) {
            const error: any = err;
            return rejectWithValue(error.response);
        }
    }
);

export const updateUserSlice = createSlice({
    name: "updateUser",
    initialState,
    reducers: {
        updateUserAction: () => { },
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateUser.pending, (state: any) => {
                state.loading = true;
                state.success = false;
                state.errors.email = "";
            })
            .addCase(updateUser.fulfilled, (state: any, { payload }: any) => {
                state.loading = false;
                state.data = payload
                state.error = false;
                state.success = true;
            })
            .addCase(updateUser.rejected, (state: any, { payload }: any) => {
                const payl: any = payload;
                state.loading = false;
                state.success = false;
                state.error = true;
            });
    },
});

export const { updateUserAction } = updateUserSlice.actions;

export const UpdateUser = (state: RootState) => state.updateUser;

export const UpdateUserReducer = updateUserSlice.reducer;
