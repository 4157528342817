
import { CustomButtom } from '../common/CustomButton/CustomButton';
import s from './profile.module.scss';
import classnames from 'classnames';
import { FC, useEffect, useState } from "react";
import { StatusMenu } from '../common/StatusMenu/StatusMenu';
import { MnInput } from '../common/MnInput/MnInput';
import {Link} from 'react-router-dom';
import { Checkbox } from 'antd';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { GetSubscription, getSubscription } from '../../store/slice/getSubscription';
import { GetSubscriptionTypes, getSubscriptionTypes } from '../../store/slice/getSubscriptionTypes';
import { MeSlice } from '../../store/slice/meSlice';
import moment from 'moment';

const cn = classnames.bind(this)

interface ISubscription {
    handleTitle: (title: string) => void
    setStatus: (status: boolean) => void
}

export const Subscription: FC<ISubscription> = ({ handleTitle }) => {
    const items = ["Подтверждение", "Причина", "done"]
    const [status, setStatus] = useState<string>("Подтверждение")
    const [wnd, setWnd] = useState<"plans" | "cards" | 'new-card' | 'card-s' | 'card-d' | 'choose' | 'uns-1' | 'uns-2' | 'uns-s'>('plans')
    const [cvar, setCvar] = useState<'1' | '2' | '3' | '4' | '5' | '6' | 'other' | null>(null)
    const [selectedPlan, setSelectedPlan] = useState(2)
    const { success: sSuccess, data: sData, } = useAppSelector(GetSubscription)
    const { success: stSuccess, data: stData, } = useAppSelector(GetSubscriptionTypes)
    const { data: meData } = useAppSelector(MeSlice)
    const dispatch = useAppDispatch()

    const handleChangeCardNumber = () => {

    }
    const handleChangeExpiryDate = () => {

    }
    const handleChangeCVC = () => {

    }

    useEffect(() => {
        dispatch(getSubscriptionTypes({}))
        dispatch(getSubscription({ token: localStorage.getItem('access') }))
    }, [])

    useEffect(() => {
        switch (wnd) {
            case 'plans':
                handleTitle('Управлять подпиской');
                break;
            case 'cards':
                handleTitle('Выберите способ оплаты');
                break;
            case 'new-card':
                handleTitle('Добавить новую карту');
                break;
            case 'card-s':
                handleTitle('');
                break;
            case 'card-d':
                handleTitle('');
                break;
            case 'choose':
                handleTitle('Выберите подписку и пополняйте свои награды!');
                break;
            case 'uns-1':
                handleTitle('Отписаться 🥺');
                break;
            case 'uns-2':
                handleTitle('Отписаться 🥺');
                break;
            case 'uns-s':
                handleTitle('');
                break;
            default:
                handleTitle('Управлять подпиской');

        }
    }, [wnd])

    const Choose = () => {
        const [chk, setChk] = useState(false)
        return (<>
            <div className={s.planBlock}>
                <div className={s.tariffsWrap}>
                    <div className={selectedPlan === 1 ? s.yt : s.mt} onClick={() => { setSelectedPlan(1) }}>
                        <div className={selectedPlan === 1 ? s.ytTop : s.mtTop}>
                            <div className={selectedPlan === 1 ? s.ytTitle : s.mtTitle}>
                                1 месяц
                            </div>
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" fill="white" />
                                <g clipPath="url(#clip0_1235_28432)">
                                    <rect x="8" y="8" width="16" height="16" rx="8" fill="white" />
                                    <circle cx="16.0001" cy="16.0003" r="5.33333" fill={selectedPlan === 1 ? '#DDBE90' : 'white'} />
                                </g>
                                <rect x="8.33333" y="8.33333" width="15.3333" height="15.3333" rx="7.66667" stroke="#DDBE90" strokeWidth="0.666667" />
                                <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#E9E9E9" />
                                <defs>
                                    <clipPath id="clip0_1235_28432">
                                        <rect x="8" y="8" width="16" height="16" rx="8" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <svg width="56" height="2" viewBox="0 0 56 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="56" height="2" rx="1" fill={selectedPlan === 1 ? "#818181" : "#CDCDCD"} />
                        </svg>
                        <div className={selectedPlan === 1 ? s.ytT : s.mtT}>
                            200 RUB / месяц
                        </div>
                        <div className={selectedPlan === 1 ? s.ytDes : s.mtDes}>
                            Ежемесячная оплата
                        </div>

                    </div>

                    <div className={selectedPlan === 2 ? s.yt : s.mt} onClick={() => { setSelectedPlan(2) }}>
                        <div className={s.ytE}>
                            Экономия - <span>59%</span>
                        </div>
                        <div className={selectedPlan === 2 ? s.ytTop : s.mtTop}>
                            <div className={selectedPlan === 2 ? s.ytTitle : s.mtTitle}>
                                1 год
                            </div>
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" fill="white" />
                                <g clipPath="url(#clip0_1235_28432)">
                                    <rect x="8" y="8" width="16" height="16" rx="8" fill="white" />
                                    <circle cx="16.0001" cy="16.0003" r="5.33333" fill={selectedPlan === 2 ? '#DDBE90' : 'white'} />
                                </g>
                                <rect x="8.33333" y="8.33333" width="15.3333" height="15.3333" rx="7.66667" stroke="#DDBE90" strokeWidth="0.666667" />
                                <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="#E9E9E9" />
                                <defs>
                                    <clipPath id="clip0_1235_28432">
                                        <rect x="8" y="8" width="16" height="16" rx="8" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                        </div>
                        <svg width="57" height="2" viewBox="0 0 57 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" width="56" height="2" rx="1" fill={selectedPlan === 2 ? "#818181" : "#CDCDCD"} />
                        </svg>

                        <div className={selectedPlan === 2 ? s.ytT : s.mtT}>
                            990 RUB / год
                        </div>
                        <div className={selectedPlan === 2 ? s.ytDes : s.mtDes}>
                            83 RUB / месяц
                        </div>
                        <div className={selectedPlan === 2 ? s.ytDes : s.mtDes}>
                            Оплата 1 раз в год
                        </div>
                    </div>
                </div>
                <div className={s.с}>Отписаться можно в любой момент</div>
            </div>
            <div className={s.profileUserPlanFooter}>
            
                <Checkbox className={s.chk} onChange={(e) => {
                    console.log(e)
                    setChk(e.target.checked)
                }}>
                    Соглашаюсь с <Link target="_blank" to="https://help.моинаграды.рф/privacy#3)">Политикой конфиденциальности</Link> и <Link target="_blank" to="https://help.моинаграды.рф/privacy#2">Условиями подписки</Link>
                </Checkbox>
                
                <CustomButtom
                    type='primary'
                    disabled={!chk}
                    onClick={() => { setWnd('cards') }}>
                    Продолжить
                </CustomButtom>
            </div>
        </>)
    }

    const Plans = () => {
        return (<>
            <div className={s.planBlock}>
                <div className={s.yplanBlock}>
                    <div className={s.yplanBlockl}>
                        <div className={s.yplanBlocklt}>Ваш план:</div>
                        <div className={s.yplanBlockld}>{meData.own_subscription.current_type.title}</div>
                    </div>
                    <div className={s.yplanBlockr}>
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="48" height="48" rx="24" fill="#DDBE90" />
                            <g clipPath="url(#clip0_1703_19910)">
                                <path d="M19.6784 15.5L16.1427 23.8443C16.0938 23.9633 16.0749 24.0925 16.0876 24.2206C16.1003 24.3487 16.1443 24.4716 16.2157 24.5787C16.2871 24.6858 16.3837 24.7737 16.497 24.8347C16.6103 24.8957 16.7369 24.9279 16.8655 24.9286H21.2498L18.107 35.9286L31.6055 23.1371C31.7177 23.0291 31.7952 22.8902 31.8282 22.738C31.8612 22.5858 31.8483 22.4273 31.791 22.2825C31.7338 22.1377 31.6348 22.0131 31.5066 21.9247C31.3785 21.8362 31.2269 21.7879 31.0713 21.7857H25.1784L28.3213 15.5H19.6784Z" fill="white" stroke="#DDBE90" strokeWidth="0.857143" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1703_19910">
                                    <rect width="22" height="22" fill="white" transform="translate(13 14.7148)" />
                                </clipPath>
                            </defs>
                        </svg>

                    </div>
                </div>
                <div className={s.updplanBlock}>
                    <div className={s.updplanBlockw}>
                        <div className={s.updplanBlockl}>
                            <div className={s.updplanBlocklt}>
                                Оплата <span>картой</span>
                            </div>
                            <div className={s.updplanBlocklb}>
                                {meData.own_subscription.price} RUB /
                                {meData.own_subscription.current_type.duration === 30 && 'месяц'}
                                {meData.own_subscription.current_type.duration > 300 && 'год'}
                            </div>
                        </div>
                        <div className={s.updplanBlockr}>
                            <div className={s.updplanBlockrt}>
                                Дата следующего списания:

                            </div>
                            <div className={s.updplanBlockrb}>
                                ---
                            </div>
                        </div>
                    </div>
                    <CustomButtom
                        type='secondary'
                        onClick={() => {
                            setWnd('cards')
                        }}>
                        Обновить способ оплаты
                    </CustomButtom>
                </div>
            </div>
            <div className={s.profileUserPlanFooter}>
                <CustomButtom
                    type='primary'
                    onClick={() => {
                        setWnd('choose')
                    }}>
                    Сменить план
                </CustomButtom>
                <CustomButtom
                    type='secondary'
                    onClick={() => {
                        setWnd('uns-1')
                    }}>
                    Отменить подписку
                </CustomButtom>
            </div>
        </>)
    }

    return (<>
        <div className={s.profileUserPlans}>
            {wnd === 'plans' && <>
                {!meData.own_subscription.infinity && meData.own_subscription.current_type.price === 0 && <>
                    <div className={s.yplanBlock}>
                    <div className={s.yplanBlockl}>
                        <div className={s.yplanBlocklt}>Ваш план:</div>
                        <div className={s.yplanBlockld}>{meData.own_subscription.current_type.title} до {moment(meData.own_subscription.expire_at).format("DD.MM.YYYY")}</div>
                    </div>
                    <div className={s.yplanBlockr}>
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="48" height="48" rx="24" fill="#DDBE90" />
                            <g clipPath="url(#clip0_1703_19910)">
                                <path d="M19.6784 15.5L16.1427 23.8443C16.0938 23.9633 16.0749 24.0925 16.0876 24.2206C16.1003 24.3487 16.1443 24.4716 16.2157 24.5787C16.2871 24.6858 16.3837 24.7737 16.497 24.8347C16.6103 24.8957 16.7369 24.9279 16.8655 24.9286H21.2498L18.107 35.9286L31.6055 23.1371C31.7177 23.0291 31.7952 22.8902 31.8282 22.738C31.8612 22.5858 31.8483 22.4273 31.791 22.2825C31.7338 22.1377 31.6348 22.0131 31.5066 21.9247C31.3785 21.8362 31.2269 21.7879 31.0713 21.7857H25.1784L28.3213 15.5H19.6784Z" fill="white" stroke="#DDBE90" strokeWidth="0.857143" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1703_19910">
                                    <rect width="22" height="22" fill="white" transform="translate(13 14.7148)" />
                                </clipPath>
                            </defs>
                        </svg>

                    </div>
                </div>
                    <Choose />
                </>}
                {meData.own_subscription.infinity && <>
                    <div className={s.yplanBlock}>
                    <div className={s.yplanBlockl}>
                        <div className={s.yplanBlocklt}>Ваш план:</div>
                        <div className={s.yplanBlockld}>Бесконечная подписка</div>
                    </div>
                    <div className={s.yplanBlockr}>
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="48" height="48" rx="24" fill="#DDBE90" />
                            <g clipPath="url(#clip0_1703_19910)">
                                <path d="M19.6784 15.5L16.1427 23.8443C16.0938 23.9633 16.0749 24.0925 16.0876 24.2206C16.1003 24.3487 16.1443 24.4716 16.2157 24.5787C16.2871 24.6858 16.3837 24.7737 16.497 24.8347C16.6103 24.8957 16.7369 24.9279 16.8655 24.9286H21.2498L18.107 35.9286L31.6055 23.1371C31.7177 23.0291 31.7952 22.8902 31.8282 22.738C31.8612 22.5858 31.8483 22.4273 31.791 22.2825C31.7338 22.1377 31.6348 22.0131 31.5066 21.9247C31.3785 21.8362 31.2269 21.7879 31.0713 21.7857H25.1784L28.3213 15.5H19.6784Z" fill="white" stroke="#DDBE90" strokeWidth="0.857143" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1703_19910">
                                    <rect width="22" height="22" fill="white" transform="translate(13 14.7148)" />
                                </clipPath>
                            </defs>
                        </svg>

                    </div>
                </div>
                </>}
                {meData.own_subscription.current_type.price > 0 && <>
                    <Plans />
                </>}
            </>}

            {wnd === 'cards' && <>
                <div className={s.planBlock}>
                    <div className={s.planBlockCards}>
                        <div className={s.planBlockCardItem}
                            onClick={() => {
                                setWnd('new-card')
                            }}
                        >
                            <div className={s.planBlockCardItemIcon}>
                                <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.794 7.538C3.69133 7.538 3.60733 7.50533 3.542 7.44C3.47667 7.37467 3.444 7.29067 3.444 7.188V4.542H0.854C0.751333 4.542 0.667333 4.50933 0.602 4.444C0.536667 4.37867 0.504 4.29933 0.504 4.206V3.324C0.504 3.22133 0.536667 3.13733 0.602 3.072C0.667333 3.00667 0.751333 2.974 0.854 2.974H3.444V0.411999C3.444 0.309333 3.47667 0.225333 3.542 0.16C3.60733 0.0946664 3.69133 0.0619996 3.794 0.0619996H4.76C4.86267 0.0619996 4.94667 0.0946664 5.012 0.16C5.07733 0.225333 5.11 0.309333 5.11 0.411999V2.974H7.7C7.79333 2.974 7.87267 3.00667 7.938 3.072C8.00333 3.13733 8.036 3.22133 8.036 3.324V4.206C8.036 4.29933 8.00333 4.37867 7.938 4.444C7.87267 4.50933 7.79333 4.542 7.7 4.542H5.11V7.188C5.11 7.29067 5.07733 7.37467 5.012 7.44C4.94667 7.50533 4.86267 7.538 4.76 7.538H3.794Z" fill="#DDBE90" />
                                </svg>
                            </div>
                            <div className={s.planBlockCardItemTitleAdd}>
                                Добавить карту
                            </div>
                            <div className={s.planBlockCardItemR}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.0908 12C13.0907 12 13.0906 12 13.0905 11.9999L8.63877 7.23851C8.27929 6.85403 8.2794 6.25669 8.63901 5.87234L8.72472 5.78073C9.12001 5.35825 9.79026 5.35837 10.1854 5.78099L15.3615 11.317C15.7208 11.7014 15.7208 12.2986 15.3615 12.683L10.1857 18.2187C9.79042 18.6415 9.11998 18.6415 8.72474 18.2187L8.63957 18.1276C8.28019 17.7433 8.28019 17.1461 8.63957 16.7617L13.0911 12.0006C13.0913 12.0004 13.0911 12 13.0908 12Z" fill="#818181" />
                                </svg>
                            </div>
                        </div>
                        <div className={s.planBlockCardsSeparator}></div>
                    </div>
                </div>
                <div className={s.profileUserPlanFooter}>
                    <CustomButtom
                        type='primary'
                        onClick={() => { }}>
                        Изменить способ оплаты
                    </CustomButtom>
                </div></>}
            {wnd === 'new-card' && <>
                <div className={s.planBlock}>
                    <div className={s.planBlockAddCard}>
                        <div className={s.planBlockAddCardNumber}>
                            <input
                                placeholder='Номер карты' />
                        </div>
                        <div className={s.planBlockAddCardItems}>
                            <input
                                placeholder='Действует до'
                            />
                            <input
                                placeholder='CVV / CVC'
                            />
                        </div>
                        {/* {meta.isTouched && meta.error && <span>Error: {meta.error}</span>} */}
                        <div className={s.secureText}>Мы гарантируем безопасность платежа. Все операции проходят на стороне банка, а ваши данные надежно зашифрованы. </div>
                    </div>
                </div>
                <div className={s.profileUserPlanFooter}>
                    <CustomButtom
                        type='primary'
                        onClick={() => { }}>
                        Добавить карту
                    </CustomButtom>
                </div></>}
            {wnd === 'card-s' && <>
                <div className={s.planBlock}>
                    <div className={s.seb}>
                        <div className={s.sebIcon}>
                            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="56" height="56" rx="28" fill="#04CF00" />
                                <path d="M23.9189 32.9396L19.5005 28.5211C19.2624 28.283 18.9395 28.1493 18.6028 28.1493C18.2661 28.1493 17.9432 28.283 17.7051 28.5211C17.467 28.7592 17.3333 29.0821 17.3333 29.4188C17.3333 29.5855 17.3661 29.7506 17.4299 29.9046C17.4937 30.0587 17.5872 30.1986 17.7051 30.3165L23.0276 35.639C23.5242 36.1356 24.3264 36.1356 24.823 35.639L38.2947 22.1672C38.5328 21.9291 38.6666 21.6062 38.6666 21.2695C38.6666 20.9328 38.5328 20.6099 38.2947 20.3718C38.0567 20.1338 37.7338 20 37.3971 20C37.0604 20 36.7374 20.1338 36.4994 20.3718L23.9189 32.9396Z" fill="white" />
                            </svg>
                        </div>
                        <div className={s.sebTitle}>Оплата прошла успешно!</div>
                        <div className={s.sebDesc}>Вы оформили подписку на <span>1 год</span> 🥳</div>
                    </div>
                </div>
                <div className={s.profileUserPlanFooter}>
                    <CustomButtom
                        type='primary'
                        onClick={() => { }}>
                        Перейти в профиль
                    </CustomButtom>
                </div></>}
            {wnd === 'card-d' && <>
                <div className={s.planBlock}>
                    <div className={s.seb}>
                        <div className={s.sebIcon}>
                            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="56" height="56" rx="28" fill="#FF3D00" />
                                <path d="M29.9959 28L35.5824 22.4135C35.8475 22.1488 35.9967 21.7897 35.997 21.415C35.9973 21.0404 35.8488 20.681 35.5841 20.4159C35.3195 20.1507 34.9603 20.0016 34.5857 20.0012C34.2111 20.0009 33.8517 20.1494 33.5865 20.4141L28 26.0006L22.4135 20.4141C22.1483 20.149 21.7887 20 21.4138 20C21.0388 20 20.6792 20.149 20.4141 20.4141C20.149 20.6792 20 21.0388 20 21.4138C20 21.7887 20.149 22.1483 20.4141 22.4135L26.0006 28L20.4141 33.5865C20.149 33.8517 20 34.2113 20 34.5862C20 34.9612 20.149 35.3208 20.4141 35.5859C20.6792 35.851 21.0388 36 21.4138 36C21.7887 36 22.1483 35.851 22.4135 35.5859L28 29.9994L33.5865 35.5859C33.8517 35.851 34.2113 36 34.5862 36C34.9612 36 35.3208 35.851 35.5859 35.5859C35.851 35.3208 36 34.9612 36 34.5862C36 34.2113 35.851 33.8517 35.5859 33.5865L29.9959 28Z" fill="white" />
                            </svg>
                        </div>
                        <div className={s.sebTitle}>Упс, что-то пошло не так...</div>
                        <div className={s.sebDesc}>Оплата была отклонена 🤔</div>
                    </div>
                </div>
                <div className={s.profileUserPlanFooter}>
                    <CustomButtom
                        type='primary'
                        onClick={() => { }}>
                        Повторить попытку
                    </CustomButtom>
                </div></>}
            {wnd === 'choose' && <>
                <Choose />
            </>}
            {wnd === 'uns-1' && <>
                <div className={s.planBlock}>
                    <div className={s.statusMenu}>
                        <StatusMenu
                            items={items}
                            current={status}
                        />
                    </div>
                    <div className={s.yplanBlock}>
                        <div className={s.yplanBlockl}>
                            <div className={s.yplanBlocklt}>Ваш план:</div>
                            <div className={s.yplanBlockld}>Бесплатная подписка на 1 мес</div>
                        </div>
                        <div className={s.yplanBlockr}>
                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="48" height="48" rx="24" fill="#DDBE90" />
                                <g clipPath="url(#clip0_1703_19910)">
                                    <path d="M19.6784 15.5L16.1427 23.8443C16.0938 23.9633 16.0749 24.0925 16.0876 24.2206C16.1003 24.3487 16.1443 24.4716 16.2157 24.5787C16.2871 24.6858 16.3837 24.7737 16.497 24.8347C16.6103 24.8957 16.7369 24.9279 16.8655 24.9286H21.2498L18.107 35.9286L31.6055 23.1371C31.7177 23.0291 31.7952 22.8902 31.8282 22.738C31.8612 22.5858 31.8483 22.4273 31.791 22.2825C31.7338 22.1377 31.6348 22.0131 31.5066 21.9247C31.3785 21.8362 31.2269 21.7879 31.0713 21.7857H25.1784L28.3213 15.5H19.6784Z" fill="white" stroke="#DDBE90" strokeWidth="0.857143" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1703_19910">
                                        <rect width="22" height="22" fill="white" transform="translate(13 14.7148)" />
                                    </clipPath>
                                </defs>
                            </svg>

                        </div>
                    </div>
                    <div className={s.updplanBlock}>
                        <div className={s.updplanBlockw}>
                            <div className={s.updplanBlockl}>
                                <div className={s.updplanBlocklt}>
                                    Оплата <span>картой</span>
                                </div>
                                <div className={s.updplanBlocklb}>
                                    1000 RUB / год
                                </div>
                            </div>
                            <div className={s.updplanBlockr}>
                                <div className={s.updplanBlockrt}>
                                    Дата следующего списания:

                                </div>
                                <div className={s.updplanBlockrb}>
                                    ---
                                </div>
                            </div>
                        </div>
                    </div>
                    <CustomButtom
                        type='secondary'
                        onClick={() => {
                            setWnd('choose')
                        }}>
                        Сменить план
                    </CustomButtom>
                </div>
                <div className={s.profileUserPlanFooterLine}>
                    <CustomButtom
                        type='primary'
                        onClick={() => { setWnd('plans') }}>
                        Отмена
                    </CustomButtom>
                    <CustomButtom
                        type='secondary'
                        onClick={() => {
                            setWnd('uns-2')
                            setStatus('Причина')
                        }}>
                        Далее
                    </CustomButtom>
                </div></>}
            {wnd === 'uns-2' && <>
                <div className={s.planBlock}>
                    <div className={s.statusMenu}>
                        <StatusMenu
                            items={items}
                            current={status}
                        />
                    </div>
                    <div className={s.rVars}>
                        <div className={s.rVarsTitle}>Укажите варинты:</div>
                        <div className={s.rVar} onClick={() => { setCvar('1') }}>
                            <div className={cn(s.rVarCheckBox, { [s.rVarActive]: cvar === '1' })}>
                            </div>
                            <div className={s.rVarTitle}>
                                Технические ошибки
                            </div>
                        </div>
                        <div className={s.planBlockCardsSeparator}></div>
                        <div className={s.rVar} onClick={() => { setCvar('2') }}>
                            <div className={cn(s.rVarCheckBox, { [s.rVarActive]: cvar === '2' })}>
                            </div>
                            <div className={s.rVarTitle}>
                                Не хватает функционала
                            </div>
                        </div>
                        <div className={s.planBlockCardsSeparator}></div>
                        <div className={s.rVar} onClick={() => { setCvar('3') }}>
                            <div className={cn(s.rVarCheckBox, { [s.rVarActive]: cvar === '3' })}>
                            </div>
                            <div className={s.rVarTitle}>
                                Мне удалось найти более подходящий сервис
                            </div>
                        </div>
                        <div className={s.planBlockCardsSeparator}></div>
                        <div className={s.rVar} onClick={() => { setCvar('4') }}>
                            <div className={cn(s.rVarCheckBox, { [s.rVarActive]: cvar === '4' })}>
                            </div>
                            <div className={s.rVarTitle}>
                                Подписка стоит дорого
                            </div>
                        </div>
                        <div className={s.planBlockCardsSeparator}></div>
                        <div className={s.rVar} onClick={() => { setCvar('5') }}>
                            <div className={cn(s.rVarCheckBox, { [s.rVarActive]: cvar === '5' })}>
                            </div>
                            <div className={s.rVarTitle}>
                                Не пользуюсь сервисом
                            </div>
                        </div>
                        <div className={s.planBlockCardsSeparator}></div>
                        <div className={s.rVar} onClick={() => { setCvar('6') }}>
                            <div className={cn(s.rVarCheckBox, { [s.rVarActive]: cvar === '6' })}>
                            </div>
                            <div className={s.rVarTitle}>
                                Не хочу отвечать
                            </div>
                        </div>
                        <div className={s.planBlockCardsSeparator}></div>
                        <div className={s.rVar} onClick={() => { setCvar('other') }}>
                            <div className={cn(s.rVarCheckBox, { [s.rVarActive]: cvar === 'other' })}>
                            </div>
                            <div className={s.rVarTitle}>
                                Другое
                            </div>
                        </div>
                        <div className={s.planBlockCardsSeparator}></div>

                        <MnInput
                            style={cvar !== 'other' ? { marginTop: '12px', marginBottom: '50px', visibility: 'hidden' } : { marginTop: '12px', marginBottom: '50px' }}
                            name="other"
                            type="textarea"
                            onChange={() => { }}
                            placeholder='Пожалуйта, укажите причину отписки'
                        />

                    </div>
                </div>
                <div className={s.profileUserPlanFooterLine}>
                    <CustomButtom
                        type='primary'
                        onClick={() => {
                            setWnd('uns-1')
                            setStatus('Подтверждение')
                        }}>
                        Назад
                    </CustomButtom>
                    <CustomButtom
                        type='secondary'
                        onClick={() => { setWnd('uns-s') }}>
                        Отписаться
                    </CustomButtom>
                </div></>}
            {wnd === 'uns-s' && <>
                <div className={s.planBlock}>
                    <div className={s.seb}>
                        <div className={s.sebIcon}>
                            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="56" height="56" rx="28" fill="#04CF00" />
                                <path d="M23.9189 32.9396L19.5005 28.5211C19.2624 28.283 18.9395 28.1493 18.6028 28.1493C18.2661 28.1493 17.9432 28.283 17.7051 28.5211C17.467 28.7592 17.3333 29.0821 17.3333 29.4188C17.3333 29.5855 17.3661 29.7506 17.4299 29.9046C17.4937 30.0587 17.5872 30.1986 17.7051 30.3165L23.0276 35.639C23.5242 36.1356 24.3264 36.1356 24.823 35.639L38.2947 22.1672C38.5328 21.9291 38.6666 21.6062 38.6666 21.2695C38.6666 20.9328 38.5328 20.6099 38.2947 20.3718C38.0567 20.1338 37.7338 20 37.3971 20C37.0604 20 36.7374 20.1338 36.4994 20.3718L23.9189 32.9396Z" fill="white" />
                            </svg>
                        </div>
                        <div className={s.sebTitle}>Операция прошла успешно</div>
                        <div className={s.sebDesc}>Вы отписались от услуг сервиса</div>
                    </div>
                </div>
                <div className={s.profileUserPlanFooter}>
                    <CustomButtom
                        type='primary'
                        onClick={() => { setWnd('plans') }}>
                        Назад
                    </CustomButtom>
                </div></>}
        </div>
    </>)
}