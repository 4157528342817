import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"
import { ENDPOINTS, REST_API } from "../../const/constants"
import axios from "axios"
import { IReward } from "./meSlice"



 interface IAlbum {
    id: number,
    title: string,
    reward: IReward,
    user: string,
    photos: IPhoto[],
    created_at: string,
    updated_at: string
 }


  interface IPhoto {
    id: number,
    image: string
  }

  interface initialStateErrorType {
    detail?: string,
    code?: string,
    messages?: string[]
  }

   interface meInitialStateType {
    loading: boolean,
    data: IAlbum,
    success: boolean,
    error: boolean,
    errors: initialStateErrorType,
  }
  
  const initialState: meInitialStateType = {
    loading: false,
    data: {} as IAlbum,
    success: false,
    error: false,
    errors: {} as initialStateErrorType,
  };

 
  
  export interface IMeReq {
    id?: number | string | undefined
  }
  
  export const getAlbum = createAsyncThunk(
    "getAlbum",
    async (req: IMeReq, { rejectWithValue }) => {
      const { id } = req;
      const url = REST_API + ENDPOINTS.albums.albums + id + '/';
      try {
        const response = await axios.get(url, {});
        return response.data;
      } catch (err) {
        const error: any = err;
        return rejectWithValue(error.response);
      }
    }
  );

  interface IDeleteAlbumReq {
    token: string | null,
    id: number | string | undefined
  }

  export const deleteAlbum = createAsyncThunk(
    "deleteAlbum",
    async (req: IDeleteAlbumReq, { rejectWithValue }) => {
      const { token, id} = req;
      const url = REST_API + ENDPOINTS.albums.albums + id + '/delete/'
      const headers = {
          Authorization: `Bearer ${token}`,
        };
      try {
        const response = await axios.post(url, {}, {headers: headers });
        return response.data;
      } catch (err) {
        const error: any = err;
        return rejectWithValue(error.response);
      }
    }
  );
  
  export const getAlbumSlice = createSlice({
    name: "getAlbum",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
      builder
        .addCase(getAlbum.pending, (state:any) => {
          state.loading = true;
          state.success = false;
          state.checkpoint = true;
        })
        .addCase(getAlbum.fulfilled, (state:any, { payload }:any) => {
          state.loading = false;
          state.data = payload;
          state.error = false;
          state.success = true;
        })
        .addCase(getAlbum.rejected, (state, { error, payload }) => {
          const payl: any = payload;
          state.loading = false;
          state.success = false;
          state.error = true;
        })
    },
  });
  
  export const GetAlbum = (state: RootState) => state.getAlbum;
  
  export const GetAlbumReducer = getAlbumSlice.reducer;
  