import { FC } from "react"

interface IIcon {
    fill?: string;
    stroke?: string;
    size?: number
}

export const CupIcon: FC<IIcon> = ({ stroke = "#DDBE90", size=18 }) => {
    const viewBox = `0 0 ${size} ${size}`
    return (
        <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 12.1997V14.5997" stroke={stroke} strokeWidth="1.3" strokeLinecap="round" />
            <path d="M11.8001 17H6.20007L6.47207 15.6432C6.50831 15.4619 6.60622 15.2988 6.74914 15.1815C6.89207 15.0643 7.0712 15.0001 7.25608 15H10.7441C10.929 15.0001 11.1081 15.0643 11.251 15.1815C11.394 15.2988 11.4919 15.4619 11.5281 15.6432L11.8001 17Z" stroke={stroke} strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.6001 3.3999L15.3593 3.6527C16.1513 3.9167 16.5473 4.04871 16.7737 4.36311C17.0001 4.67751 17.0001 5.09511 17.0001 5.92951V5.98791C17.0001 6.67592 17.0001 7.02072 16.8345 7.30232C16.6689 7.58392 16.3673 7.75112 15.7657 8.08632L13.4001 9.39993M3.40001 3.3999L2.64081 3.6527C1.8488 3.9167 1.4528 4.04871 1.2264 4.36311C1 4.67751 1 5.09591 1 5.92951V5.98791C1 6.67592 1 7.02072 1.1656 7.30232C1.3312 7.58392 1.6328 7.75112 2.23441 8.08632L4.60002 9.39993M8.31683 4.21831C8.62084 3.6719 8.77284 3.3999 9.00004 3.3999C9.22724 3.3999 9.37924 3.6719 9.68324 4.21831L9.76164 4.35911C9.84804 4.51431 9.89124 4.59111 9.95844 4.64231C10.0264 4.69351 10.1104 4.71271 10.2784 4.75031L10.4304 4.78551C11.0208 4.91911 11.316 4.98551 11.3864 5.21111C11.4568 5.43751 11.2552 5.67271 10.8528 6.14311L10.7488 6.26472C10.6344 6.39832 10.5768 6.46472 10.5512 6.54792C10.5256 6.63112 10.5344 6.71992 10.5512 6.89832L10.5672 7.06072C10.628 7.68872 10.6584 8.00312 10.4752 8.14232C10.2912 8.28153 10.0144 8.15432 9.46164 7.89992L9.31924 7.83432C9.16164 7.76232 9.08324 7.72632 9.00004 7.72632C8.91684 7.72632 8.83844 7.76232 8.68084 7.83432L8.53844 7.89992C7.98563 8.15512 7.70883 8.28153 7.52483 8.14232C7.34083 8.00312 7.37203 7.68872 7.43283 7.06072L7.44883 6.89832C7.46563 6.71992 7.47443 6.63112 7.44883 6.54792C7.42323 6.46552 7.36563 6.39832 7.25123 6.26472L7.14723 6.14311C6.74483 5.67271 6.54323 5.43751 6.61363 5.21111C6.68403 4.98551 6.97923 4.91911 7.56963 4.78551L7.72163 4.75031C7.88963 4.71271 7.97363 4.69431 8.04163 4.64231C8.10883 4.59111 8.15203 4.51431 8.23843 4.35911L8.31683 4.21831Z" stroke={stroke} strokeWidth="1.3" />
            <path d="M13.8001 16.9998H4.20008M13.0001 1.3645C13.5929 1.47731 13.9449 1.60211 14.2481 1.97651C14.6297 2.44531 14.6097 2.95171 14.5689 3.96452C14.4249 7.60693 13.6089 12.1998 9.0009 12.1998C4.39288 12.1998 3.57607 7.60773 3.43047 3.96452C3.39047 2.95171 3.37047 2.44451 3.75047 1.97651C4.13128 1.50771 4.58648 1.43091 5.49768 1.2773C6.65541 1.08756 7.82694 0.994705 9.0001 0.999703C9.5745 0.999703 10.1089 1.0197 10.6001 1.0541" stroke={stroke} strokeWidth="1.3" strokeLinecap="round" />
        </svg>

    )
}