import { FC } from "react"

interface IIcon {
    fill?: string;
    stroke?: string;
}

export const YandexIcon: FC<IIcon> = ({ }) => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.89 24C9.89 16.1758 16.231 9.83334 24.0567 9.83334C31.8795 9.83334 38.2233 16.1758 38.2233 24C38.2233 31.8243 31.8795 38.1667 24.0567 38.1667C16.231 38.1667 9.89 31.8243 9.89 24Z" fill="#FC3F1D" />
            <path d="M25.87 17.8602H24.561C22.1612 17.8602 20.8989 19.0757 20.8989 20.8677C20.8989 22.8936 21.7716 23.8427 23.5637 25.0597L25.0441 26.057L20.7898 32.4136H17.6108L21.4288 26.7271C19.2329 25.1532 18.0004 23.6246 18.0004 21.0392C18.0004 17.7978 20.26 15.585 24.5454 15.585H28.7997V32.398H25.87V17.8602Z" fill="white" />
            <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" stroke="#E9E9E9" />
        </svg>
    )
}

export const YandexSmallIcon: FC<IIcon> = ({ }) => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 11C0 4.92473 4.92361 0 11 0C17.0742 0 22 4.92473 22 11C22 17.0753 17.0742 22 11 22C4.92361 22 0 17.0753 0 11Z" fill="#FC3F1D" />
            <path d="M12.408 6.23261H11.3916C9.52822 6.23261 8.54808 7.17641 8.54808 8.56785C8.54808 10.1409 9.22571 10.8778 10.6172 11.8228L11.7667 12.5972L8.46337 17.5329H5.99496L8.95953 13.1175C7.25448 11.8954 6.29748 10.7085 6.29748 8.70101C6.29748 6.18416 8.05199 4.46599 11.3795 4.46599H14.6828V17.5208H12.408V6.23261Z" fill="white" />
        </svg>

    )
}