import { FC, useEffect, useState } from "react";
import s from './overlay.module.scss'
import { CloseXIcon } from "../../../icons/closex-icon";

interface IOverlay {
    children: any,
    status: boolean
    title?:string
    setStatus: (status: boolean) => void;
    isClose?: boolean
}

export const Overlay: FC<IOverlay> = ({ children, status, setStatus, title, isClose = true }) => {

    useEffect(()=>{
        document.body.style.overflow = 'hidden'

        return () => {
            document.body.style.overflow = 'auto'
        }     
    },[])

    return (<>
        <div className={s.overlay} style={!!status ? { display: "block" } : { display: "none" }}>
            <div onClick={()=>setStatus(false)} style={{position: 'absolute', left: 0, right: 0, top: 0, bottom: 0}}></div>
            <div 
                style={status ? { bottom: 0 } : {}}
                className={s.modalWrapper}>
                <div className={s.modalTopLine}></div>
                <div className={s.modalBody}>
                   {isClose && <div className={s.modalTitle}>
                        <h3>{title}</h3>
                    <div onClick={() => { setStatus(false) }} className={s.closeModal}><CloseXIcon /></div>
                    </div>}
                    {children}
                </div>
            </div>
        </div>
    </>)
}