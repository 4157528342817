
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import axios from "axios";
import { ENDPOINTS, REST_API } from "../../const/constants";

interface ICreateAlbum {
    id: number;
    title: string;
    user: string;
}

interface CreateAlbumInitialStateType {
    loading: boolean,
    data: ICreateAlbum
    success: boolean,
    error: boolean,
    errors: any,
}

const initialState: CreateAlbumInitialStateType = {
  loading: false,
  data: {} as ICreateAlbum,
  success: false,
  error: false,
  errors: {},
};

interface ICreateAlbumReq {
    token: string | null,
    id?: number | string,
    title: string,
    reward: number
}

export const createAlbum = createAsyncThunk(
  "createAlbum",
  async (req: ICreateAlbumReq, { rejectWithValue }) => {
    const { token, title, reward, id } = req;
    const _id = !!id ? id + "/" : ''
    const url = REST_API + ENDPOINTS.albums.albums + _id
    const headers = {
        Authorization: `Bearer ${token}`,
      };
    try {
      const response = await axios.post(url, { title: title, reward: reward }, {headers: headers });
      return response.data;
    } catch (err) {
      const error: any = err;
      return rejectWithValue(error.response);
    }
  }
);

export const createAlbumSlice = createSlice({
  name: "createAlbum",
  initialState,
  reducers: {
    createAlbumAction: () => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAlbum.pending, (state:any) => {
        state.loading = true;
        state.success = false;
        state.errors.email = "";
      })
      .addCase(createAlbum.fulfilled, (state:any, { payload }:any) => {
        state.loading = false;
        state.data = payload
        state.error = false;
        state.success = true;
      })
      .addCase(createAlbum.rejected, (state:any, { payload }:any) => {
        const payl: any = payload;
        state.loading = false;
        state.success = false;
        state.error = true;
      });
  },
});

export const { createAlbumAction } = createAlbumSlice.actions;

export const CreateAlbum = (state: RootState) => state.createAlbum;

export const CreateAlbumReducer = createAlbumSlice.reducer;
