import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"
import { ENDPOINTS, REST_API } from "../../const/constants"
import axios from "axios"

export interface IReward {
    id: number,
    photos: IPhoto[],
    user: string,
    start_day: number,
    start_month: number,
    start_date: string,
    start_year: number,
    end_day: number,
    end_month: number,
    end_year: number,
    year: number,
    sport: ISport,
    distance: IDistance,
    disciplines: string,
    result: string,
    category: string,
    title: string,
    note: string,
    age: number,
    gender: string,
    significance: string,
    protocol: string,
    team: string,
    organizer: string,
    teacher: string,
    institution: string,
    kind_activity: string,
    country: ILocation,
    city: ILocation,
    additional_criteria?:string
  }
  interface ILocation {
    id: number,
    name: string
  }

  interface IDistance{
    id: number,
    title: string,
    sport: number
  }

  interface ISport {
    id: number,
    title: string
  }

  interface IPhoto {
    id: number,
    image: string
  }

  interface initialStateErrorType {
    detail?: string,
    code?: string,
    messages?: string[]
  }

   interface meInitialStateType {
    loading: boolean,
    data: IReward,
    success: boolean,
    error: boolean,
    errors: initialStateErrorType,
  }
  
  const initialState: meInitialStateType = {
    loading: false,
    data: {} as IReward,
    success: false,
    error: false,
    errors: {} as initialStateErrorType,
  };

 
  
  export interface IMeReq {
    id?: string;
  }
  
  export const getReward = createAsyncThunk(
    "getReward",
    async (req: IMeReq, { rejectWithValue }) => {
      const { id } = req;
      const headers = {
        Authorization: `Bearer ${localStorage.getItem('access')}`,
      };
      const url = REST_API + ENDPOINTS.rewards.rewards + id + '/';
      try {
        const response = await axios.get(url, {headers: headers });
        return response.data;
      } catch (err) {
        const error: any = err;
        return rejectWithValue(error.response);
      }
    }
  );
  
  export const getRewardSlice = createSlice({
    name: "getReward",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
      builder
        .addCase(getReward.pending, (state:any) => {
          state.loading = true;
          state.success = false;
          state.checkpoint = true;
        })
        .addCase(getReward.fulfilled, (state:any, { payload }:any) => {
          state.loading = false;
          state.data = payload;
          state.error = false;
          state.success = true;
        })
        .addCase(getReward.rejected, (state, { error, payload }) => {
          const payl: any = payload;
          state.loading = false;
          state.success = false;
          state.error = true;
        })
    },
  });
  
  export const GetReward = (state: RootState) => state.getReward;
  
  export const GetRewardReducer = getRewardSlice.reducer;
  