import { FC } from 'react'
import s from './status-menu.module.scss'
import { CrownIcon } from '../../../icons/crown-icon'
import classnames from 'classnames'

const cn = classnames.bind(this)

interface IStatusMenu {
    items: string[]
    current: string
}

export const StatusMenu: FC<IStatusMenu> = ({ items, current }) => {
    const currIndex = items.indexOf(current)

    return (<>
        <div className={s.statusNav}>
            {items.map((item, i, items) => {
                if (currIndex === 0 || currIndex === 1 || (currIndex >= 2 && i >= currIndex - 1)) {
                    return (
                        <div key={i} className={cn(s.statusBlock, {[s.statusBlockDone]: item==='done' }, {[s.statusBlock3]: items.length === 3 })}>
                            {item === 'done' ? <div style={{position: 'absolute', top: '-22px', left: '0'}}><CrownIcon /> </div>: <>
                            <div className={s.statusItem}>
                                {i > currIndex && <span className={s.markPending}></span>}
                                {i < currIndex && <span className={s.markDone}></span>}
                                {i === currIndex && <span className={s.markCurrent}></span>}
                                {item !== 'done' && <div className={s.title}>{item}</div>}
                            </div> {i + 1 < items.length && <>
                                {i === 0 || i < currIndex ? <span className={s.activeLine}></span> : <span className={s.pendingLine}></span>}
                            </>}
                            </>}
                            
                        </div>
                    )
                }
                return
            })}
        </div>
    </>)
}